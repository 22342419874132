import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PopupDefineVariables from "./popupDefineVariables";
import { getLabels } from "../../../data/apiFields";
import FactFinderButtonSection from "../factFinderButtonSection";
import loadOptions from "../../../server/dbpedia";

import "../styles/template.scss";

const DefineVariables = (props) => {
  const { templateData } = props;
  const [openDefineVariablePopup, setOpenDefineVariablePopup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [type, setType] = useState("");

  const [searchOptionsPlayers, setSearchOptionsPlayers] = useState([]);
  const [selectedSearch, setSelectedSearch] = useState([]);

  const handleClickOpenDefineVariable = (value) => {
    setOpenDefineVariablePopup(true);
    setType(value);
    setSearchOptionsPlayers(getLabels(value));
  };
  const handleClickSaveVariable = () => {
    setOpenDefineVariablePopup(false);
  };
  const handleLoadOptions = (inputValue) => {
    return type === "players"
      ? loadOptions(inputValue, "players")
      : loadOptions(inputValue, "teams");
  };
  const handleInputChangeSearch = (value) => {
    setSelectedSearch(value);
  };
  const handleCloseDefineVariable = () => {
    setOpenDefineVariablePopup(false);
  };
  useEffect(() => {}, [groups]);

  return (
    <div>
      <div className="left-section">
        <div>
          <FactFinderButtonSection />
        </div>
        <div>
          <div>
            <div className="group-section">
              {templateData.map((group, index) => {
                return (
                  <div key={index}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ margin: "10px 0" }}
                    >
                      <p className="insertelement">&lt;{group.group_name}&gt;</p>
                      <Button
                        size="small"
                        data-testid={`define-variables-button-${group.type}`}
                        variant="contained"
                        onClick={() =>
                          handleClickOpenDefineVariable(group.type)
                        }
                      >
                        Define Variable
                      </Button>
                    </Grid>
                    {group.facts.map((fact, index) => {
                      return (
                        <div className="addvariable-section">
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              margin: "5px 0",
                            }}
                          >
                            <p className="insertelement">{fact.fact}</p>
                          </Grid>
                        </div>
                      );
                    })}
                    <PopupDefineVariables
                      handleCloseDefineVariable={handleCloseDefineVariable}
                      openDefineVariablePopup={openDefineVariablePopup}
                      handleClickSaveVariable={handleClickSaveVariable}
                      searchOptionsPlayers={searchOptionsPlayers}
                      handleInputChangeSearch={handleInputChangeSearch}
                      type={group.type}
                      handleLoadOptions={handleLoadOptions}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DefineVariables;
