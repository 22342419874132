const formatDate = (date) => {
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0]
    .replaceAll("-", "");
  return dateString;
};

const isoToDate = (iso) => {
  return iso
    .substring(0, 10)
    .split("-")
    .filter((item) => item != "-")
    .join("");
};

const getDataByCategory = (data, category) => {
  const filteredData = data[category];
  let facts = "";
  category != "playerInjuryHistoryData"
    ? (facts = objToArray(filteredData))
    : (facts = objToArray(filteredData[0]));
  return facts;
};

const objToArray = (obj) => {
  return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
};

const categoryData = {
  player: [
    { value: "weeklyPlayerData", label: "Weekly Player Gamelogs" },
    { value: "basicPlayerData", label: "Player Stats" },
    { value: "playerInjuryHistoryData", label: "Injury History" },
    { value: "seasonalPlayerData", label: "Current Season Player Stats" },
  ],
  season: [{ value: "SeasonalGamesData", label: "Seasonal Games" }],
  teams: [
    { value: "playersData", label: "Players" },
    { value: "seasonalTeamData", label: "Seasonal Team Stats" },
    { value: "upcomingGames", label: "Upcoming Games" },
  ],
  games: [{ value: "gameData", label: "Game Boxscore" }],
};
export { formatDate, isoToDate, categoryData, objToArray, getDataByCategory };
