import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import Box from "@mui/material/Box";
import toast from 'react-hot-toast';
import LinearProgress from "@mui/material/LinearProgress";
import AdvancedOptions from "../reusableComponent/AdvancedOptions";
import AiChatTools from "./aiChatTools";
import { styled } from '@mui/material/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import googleApi from "../../server/googleApi";

const languageMap = {
  "English (British English)": "en-GB",
  "English (American)": "en-US",
  "Mandarin Chinese": "zh-CN",
  "Spanish": "es-ES",
  "Hindi-Urdu": "hi-IN",
  "Arabic": "ar-SA",
  "Bengali": "bn-BD",
  "Portuguese (European)": "pt-PT",
  "Russian": "ru-RU",
  "Indonesian": "id-ID",
  "French (Standard)": "fr-FR",
  "French (Quebec)": "fr-CA",
};

const PromptContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E0E0",
  borderRadius: "16px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  boxShadow: "0px 3px 6px #00000029",
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  marginLeft: "8px",
  padding: "8px",
  backgroundColor: "#24ADE4",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#1c8dbf",
  },
}));

const AiChatSelection = ({ input, setInput, aiChatMessage, setAiChatMessage, handleSendMessage }) => {
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [interimTranscript, setInterimTranscript] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null); // State for selected tool
  const [placeholder, setPlaceholder] = useState("Type or speak your message..."); // State for placeholder text
  const [anchorEl, setAnchorEl] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileUploadClick = () => {
    document.getElementById('file-upload').click();
    handleMenuClose();
  };

  const isTokenExpired = () => {
    return expiryDate && Date.now() >= expiryDate;
  };

  const handleGoogleDriveClick = async () => {
    if (!accessToken || isTokenExpired()) {
      setAccessToken(null);
      await handleGoogleAuth();
    } else {
      showPicker(accessToken);
    }
  };

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const speechRecognition = new window.webkitSpeechRecognition();
      speechRecognition.continuous = false;
      speechRecognition.interimResults = true;

      const languageCode = languageMap[input.settings.language] || "en-GB";
      speechRecognition.lang = languageCode;

      speechRecognition.onresult = (event) => {
        let interimText = "";
        let finalText = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalText += transcript;
          } else {
            interimText += transcript;
          }
        }
        setInterimTranscript(interimText);
        if (finalText) {
          setAiChatMessage(prevMessage => prevMessage + finalText);
        }
      };

      speechRecognition.onerror = (event) => {
        toast.error(event.error); // Display error with toast
      };

      speechRecognition.onend = () => {
        setIsListening(false);
      };

      setRecognition(speechRecognition);
    } else {
      console.warn("Web Speech API is not supported in this browser.");
      toast.error("Web Speech API is not supported in this browser."); // Display error with toast
    }
  }, [input.settings.language]);

  useEffect(() => {
    // Update placeholder based on the selected tool
    if (selectedTool) {
      switch (selectedTool.value) {
        case 'googleSearch':
          setPlaceholder("Type your search query...");
          break;
        case 'crawlAndExtractTextFromWebsite':
          setPlaceholder("Enter the website URL...");
          break;
        case 'generateImageResponse':
          setPlaceholder("Describe the image you want to generate...");
          break;
        case 'generateWeatherForecastMessage':
          setPlaceholder("Enter the location for weather forecast...");
          break;
        default:
          setPlaceholder("Type or speak your message...");
      }
    } else {
      setPlaceholder("Type or speak your message...");
    }
  }, [selectedTool]);

  const handleVoiceInput = () => {
    if (recognition) {
      if (isListening) {
        recognition.stop();
      } else {
        setAiChatMessage("");
        recognition.start();
      }
      setIsListening(prevState => !prevState);
    } else {
      toast.error("Speech recognition is not available."); // Display error with toast
    }
  };

  const handleSubmit = async (content) => {
    const updatedMessages = [
      ...(input.options.messages || []),
      { role: "user", content: content }
    ];
    let updatedOptions = {
      ...input.options,
      max_length: "none", //set no limit max_length for file picked google drive 
      messages: updatedMessages
    };

    if (selectedTool && selectedTool.value !== 'standardChat') {
      updatedOptions = {
        ...updatedOptions,
        tool_choice: { type: "function", function: { name: selectedTool.function.name } }
      };
    } else {
      updatedOptions = { ...updatedOptions, tool_choice: 'auto' };
    }

    // Update input state before sending the message
    setInput(prevInput => ({
      ...prevInput,
      options: updatedOptions
    }));
    // Send the message and wait for the response (if `handleSendMessage` is async)
    await handleSendMessage({
      ...input,
      options: updatedOptions
    });
    // Clear message input and other related states after message is sent
    setAiChatMessage("");
    setInterimTranscript(""); // Clear the interim transcript
    setSelectedTool(null); // Reset selected tool
  };

  // Handles the Enter key press for form submission or new line
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        // If Shift + Enter is pressed, add a new line
        event.preventDefault();
        setAiChatMessage(aiChatMessage + "\n");
      } else {
        // If only Enter is pressed, submit the form
        event.preventDefault();
        handleSubmit(aiChatMessage);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Update input options for file upload
        setInput((prevInput) => ({
          ...prevInput,
          options: {
            ...prevInput.options,
            max_length: "none", // Set max_length to none for file upload
          },
        }));

        // File size limit of 3MB
        const fileSizeLimit = 3 * 1024 * 1024;
        const allowedFileTypes = [".csv", ".xls", ".xlsx", ".doc", ".docx", ".txt"];
        const fileType = file.name.split('.').pop().toLowerCase();

        // Validate file type and size
        if (!allowedFileTypes.includes(`.${fileType}`)) {
          toast.error(`Unsupported file type: .${fileType}. Allowed types are: .csv, .xls, .xlsx, .doc, .docx, .txt`);
          return;
        }
        if (file.size > fileSizeLimit) {
          toast.error("File size exceeds the 3MB limit.");
          return;
        }

        setFileLoading(true);

        // Read file as text
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            let fileContent = e.target.result;
            if (!fileContent) {
              throw new Error("File content is empty or could not be read.");
            }

            // Convert CSV files to markdown format
            if (fileType === "csv") {
              fileContent = convertCsvToMarkdown(fileContent);
            }

            // Submit file content (markdown for CSV, plain text for others)
            await handleSubmit(fileContent);
          } catch (innerError) {
            toast.error("An error occurred while processing the file content.");
            console.error("File content processing error:", innerError);
          } finally {
            setFileLoading(false);
          }
        };

        reader.onerror = () => {
          toast.error("Error reading file");
          setFileLoading(false);
        };

        reader.readAsText(file);
      } catch (error) {
        toast.error("An unexpected error occurred during file upload.");
        console.error("File upload error:", error);
        setFileLoading(false);
      }
    }
  };

  // Convert CSV content to markdown table format with auto-detected separator
  const convertCsvToMarkdown = (csv) => {
    const possibleSeparators = [',', ';', '\t', '|'];

    // Function to detect the separator based on the first line
    const detectSeparator = (line) => {
      const separatorCounts = possibleSeparators.map(sep => ({
        sep,
        count: line.split(sep).length
      }));
      // Choose the separator that results in the most columns
      separatorCounts.sort((a, b) => b.count - a.count);
      return separatorCounts[0].sep;
    };

    const rows = csv.trim().split("\n");
    const separator = detectSeparator(rows[0]);
    const headers = rows[0].split(separator);
    const mdTable = [];

    // Markdown table header
    mdTable.push(`| ${headers.join(" | ")} |`);
    mdTable.push(`| ${headers.map(() => "---").join(" | ")} |`);

    // Add rows in markdown table format
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(separator);
      mdTable.push(`| ${row.join(" | ")} |`);
    }
    return mdTable.join("\n");
  };

  const handleGoogleAuth = async () => {
    try {
      const redirectUri = window.location.href;
      const authUrl = await googleApi.fetchGoogleAuthUrl(redirectUri);
      window.location.href = authUrl;
    } catch (error) {
      toast.error("Failed to authenticate with Google");
    }
  };

  const showPicker = (accessToken) => {
    if (!accessToken) {
      toast.error("Access token is missing!"); // Handle missing token
      return;
    }

    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.DOCS)
      .setOAuthToken(accessToken)
      .setCallback((data) => pickerCallback(data, accessToken))
      .build();
    picker.setVisible(true);
  };

  const cleanUrlParams = (url, paramsToRemove = ['code', 'scope']) => {
    const urlObj = new URL(url);
    paramsToRemove.forEach(param => urlObj.searchParams.delete(param));
    return urlObj.toString();
  };

  const pickerCallback = async (data, accessToken) => {
    if (data.action === google.picker.Action.PICKED) {
      const doc = data.docs[0];
      const fileId = doc.id;
      const fileName = doc.name;

      setInput((prevInput) => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          max_length: "none", //set no limit max_length for file picked google drive 
        },
      }));

      try {
        setFileLoading(true); // Set loading state to true

        // Fetch file content as Blob using the provided accessToken
        const fileBlob = await googleApi.getFileContent(fileId, accessToken);

        // Check the content type of the fileBlob
        const contentType = fileBlob.type;

        let fileContent;
        if (contentType.includes("text/csv")) {
          // Convert CSV Blob to text and then to markdown
          const csvText = await blobToString(fileBlob);
          fileContent = convertCsvToMarkdown(csvText);
        } else {
          // Handle as plain text for other types
          fileContent = await blobToString(fileBlob);
        }

        // Ensure file content is fully read before submitting
        if (fileContent) {
          await handleSubmit(fileContent);
        } else {
          toast.error('The file content is empty or could not be read.');
        }
      } catch (error) {
        toast.error('Failed to retrieve file content from Google Drive');
        console.error('Google Drive file error:', error);
      } finally {
        setFileLoading(false); // Reset loading state
      }
    } else if (data.action === google.picker.Action.CANCEL) {
      toast.error('File selection canceled');
    }
  };

  // Helper function to convert Blob to text
  const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => gapi.load('picker', () => { });
    document.body.appendChild(script);

    const gisScript = document.createElement('script');
    gisScript.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(gisScript);

    const checkForOAuthCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        let cleanUrl;
        try {
          cleanUrl = cleanUrlParams(window.location.href);

          const tokens = await googleApi.handleGoogleAuthCallback(code, cleanUrl);

          if (tokens?.access_token) {
            // Directly call showPicker with the token, bypassing the need to wait for state update
            showPicker(tokens.access_token);

            // Update state after calling showPicker
            setAccessToken(tokens.access_token);
            
            if (tokens?.expiry_date) {
              // Set expiry date
              setExpiryDate(tokens.expiry_date);
            }
          }

          window.history.replaceState({}, document.title, cleanUrl);
        } catch (error) {
          toast.error("Error during OAuth callback");
        } finally {
          // Clean up the URL, regardless of whether the above code succeeded or failed
          cleanUrl = cleanUrl || cleanUrlParams(window.location.href);
          window.history.replaceState({}, document.title, cleanUrl);
        }
      }
    };

    checkForOAuthCode();
  }, []);

  return (
    <div className="left-section">
      <div className="filter-section">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="select-box" data-testid="setting-component">
              <h3 className="titlename">AI-Chat</h3>
              <h5>Settings:</h5>
              <AdvancedOptions input={input} setInput={setInput} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <h5>Prompt:</h5>
            <div className="select-box">
              <PromptContainer>
                <AiChatTools selectedTool={selectedTool} onSelectTool={setSelectedTool} /> {/* Tools Dropdown */}
                <input
                  type="file"
                  accept=".csv, .xls, .xlsx, .doc, .docx, .txt"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-upload"
                />
                <IconButtonStyled
                  aria-controls="file-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <AttachFileIcon />
                </IconButtonStyled>
                <Menu
                  id="file-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleFileUploadClick}>
                    <FileUploadIcon sx={{ marginRight: 1 }} />
                    Upload from Computer
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleGoogleDriveClick}>
                    <AddToDriveIcon sx={{ marginRight: 1 }} />
                    Connect to Google Drive
                  </MenuItem>
                </Menu>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    position: "relative",
                  }}
                >
                  <TextField
                    id="aiChatMessage"
                    variant="standard"
                    fullWidth
                    multiline
                    rows={4}
                    value={aiChatMessage + interimTranscript}
                    onChange={e => setAiChatMessage(e.target.value)}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: aiChatMessage ? "#000" : "#aaa",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    inputProps={{ "data-testid": "article-prompt" }}
                  />
                  <IconButton
                    data-testid="mic-button"
                    color="inherit"
                    onClick={handleVoiceInput}
                    sx={{
                      padding: "8px",
                      backgroundColor: isListening ? "#d32f2f" : "#24ADE4",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: isListening ? "#b71c1c" : "#1c8dbf",
                      },
                      position: "absolute",
                      right: "8px",
                    }}
                  >
                    <MicIcon />
                  </IconButton>
                </Box>
                <IconButtonStyled
                  data-testid="send-button"
                  color="inherit"
                  onClick={() => handleSubmit(aiChatMessage)}
                  disabled={!aiChatMessage.trim()}
                >
                  <SendIcon />
                </IconButtonStyled>
              </PromptContainer>
              {fileLoading && <LinearProgress />}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AiChatSelection;