import { EditorState, Modifier } from "draft-js";

const insertText = (text, editorValue) => {
  const currentContent = editorValue.getCurrentContent();
  const currentSelection = editorValue.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text
  );

  const newEditorState = EditorState.push(
    editorValue,
    newContent,
    "insert-characters"
  );

  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter()
  );
};
export default insertText;
