import DatePicker from "react-datepicker";
import Grid from "@mui/material/Grid";
import AsyncSelect from "react-select/async";
import Button from "@mui/material/Button";
import { Item } from "./styledComponents";
import "react-datepicker/dist/react-datepicker.css";
import {
  StartDatePicker,
  SearchPlayerTeam,
  EndDatePicker,
} from "../reusableComponent/selectFacts";

const RenderSelectBox = (props) => {
  const {
    startDate,
    setStartDate,
    handleInputChangeDataCategory,
    getFactsData,
    endDate,
    setEndDate,
    handleLoadGamesOptions,
    type,
    handleInputChangeSearch,
    handleLoadOptions,
    handleInputChangeGame,
  } = props;

  const categoryData = {
    players: [
      { value: "weeklyPlayerData", label: "Weekly Player Gamelogs" },
      { value: "basicPlayerData", label: "Player Stats" },
      { value: "playerInjuryHistoryData", label: "Injury History" },
      { value: "seasonalPlayerData", label: "Current Season Player Stats" },
    ],
    season: [{ value: "SeasonalGamesData", label: "Seasonal Games" }],
    teams: [
      { value: "playersData", label: "Players" },
      { value: "seasonalTeamData", label: "Seasonal Team Stats" },
      { value: "upcomingGames", label: "Upcoming Games" },
    ],
    games: [
      { value: "gameData", label: "Game Boxscore" },
      { value: "gamePlaybyPlayData", label: "Game Play-by-play" },
    ],
  };
  const handleInputStartDate = (value) => {
    setStartDate(value);
  };
  const handleInputEndDate = (value) => {
    setEndDate(value);
  };
  const availableSeason = [{ value: "2021-regular", label: "2021 Regular" }];
  switch (type) {
    case "season":
      return (
        <>
          <Grid item sm={2}>
            <Item>
              <StartDatePicker
                startDate={startDate}
                handleInputStartDate={handleInputStartDate}
              />
            </Item>
          </Grid>
          <Grid item sm={2}>
            <div>
              <AsyncSelect
                defaultOptions={availableSeason}
                placeholder="Available Seasons"
                isClearable={true}
                onChange={handleInputChangeSearch}
              />
            </div>
          </Grid>
          <Grid item sm={2}>
            <AsyncSelect
              defaultOptions={categoryData.season}
              placeholder="Data Category"
              isClearable={true}
              onChange={handleInputChangeDataCategory}
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                getFactsData();
              }}
            >
              Get Facts
            </Button>
          </Grid>
        </>
      );
    case "games":
      return (
        <>
          <Grid item sm={2}>
            <StartDatePicker
              startDate={startDate}
              handleInputStartDate={handleInputStartDate}
            />
            <EndDatePicker
              endDate={endDate}
              handleInputEndDate={handleInputEndDate}
            />
          </Grid>
          <Grid item sm={2}>
            <div>
              <AsyncSelect
                key={startDate}
                loadOptions={handleLoadGamesOptions}
                defaultOptions={true}
                placeholder="Available Games"
                isClearable={true}
                onChange={handleInputChangeGame}
              />
            </div>
          </Grid>
          <Grid item sm={2}>
            <AsyncSelect
              defaultOptions={categoryData.games}
              placeholder="Data Category"
              isClearable={true}
              onChange={handleInputChangeDataCategory}
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                getFactsData();
              }}
            >
              Get Facts
            </Button>
          </Grid>
        </>
      );
    case "players":
      return (
        <>
          <Grid item sm={2}>
            <Item>
              <StartDatePicker
                startDate={startDate}
                handleInputStartDate={handleInputStartDate}
              />
            </Item>
          </Grid>
          <Grid item sm={2}>
            <div>
              <SearchPlayerTeam
                placeholder="Search Player"
                handleInputChangeSearch={handleInputChangeSearch}
                handleLoadOptions={handleLoadOptions}
              />
            </div>
          </Grid>
          <Grid item sm={2}>
            <AsyncSelect
              defaultOptions={categoryData.players}
              placeholder="Data Category"
              isClearable={true}
              onChange={handleInputChangeDataCategory}
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                getFactsData();
              }}
            >
              Get Facts
            </Button>
          </Grid>
        </>
      );
    case "teams":
      return (
        <>
          <Grid item sm={2}>
            <Item>
              <StartDatePicker
                startDate={startDate}
                handleInputStartDate={handleInputStartDate}
              />
            </Item>
          </Grid>
          <Grid item sm={2}>
            <div>
              <SearchPlayerTeam
                placeholder="Search Team"
                handleInputChangeSearch={handleInputChangeSearch}
                handleLoadOptions={handleLoadOptions}
              />
            </div>
          </Grid>
          <Grid item sm={2}>
            <AsyncSelect
              defaultOptions={categoryData.teams}
              placeholder="Data Category"
              isClearable={true}
              onChange={handleInputChangeDataCategory}
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                getFactsData();
              }}
            >
              Get Facts
            </Button>
          </Grid>
        </>
      );
    default:
      return null;
  }
};

export default RenderSelectBox;
