const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return text; // Return the copied text on success
  } catch (error) {
    console.error('Error copying text:', error);
    throw error;
  }
};

const extractMetadata = (str) => {
  // Define metadata keywords to be extracted
  const metadataKeywords = [
    'Title', 'Article Headline', 'Word Count', 'Sources', 'Excerpt', 'Lead Text',
    'Similarity Score', 'Meta Title', 'Social Headline', 'Meta Description',
    'SEO Title', 'Headline', 'Newsletters Headline', 'Referrers Headline'
  ];

  // Generate regular expressions dynamically
  const metadataPatterns = metadataKeywords.map(keyword => ({
    keyword,
    pattern: new RegExp(`^(#{1,4} |\\*{1,4}\\s*)?${keyword}:\\s*"?([^"]*?)"?$`, 'im')
  }));

  // Special case for lines starting with ###
  const hashTitleRegex = /^###\s*(.*)$/;

  // Extract metadata using regular expressions
  const extractedMetadata = {};

  metadataPatterns.forEach(({ keyword, pattern }) => {
    const match = str.match(pattern);
    if (match) {
      extractedMetadata[keyword.replace(/\s+/g, '')] = match[2];
    }
  });

  // Handle the first line as a special case
  const lines = str.split('\n');
  const firstLine = lines[0].trim();
  const hashTitleMatch = firstLine.match(hashTitleRegex);

  // Extract title specifically from the first line if not already found
  if (hashTitleMatch && !extractedMetadata['Title']) {
    extractedMetadata['Title'] = hashTitleMatch[1];
  }

  // Custom logic to prioritise certain metadata fields
  const metadata = {
    title: extractedMetadata['ArticleHeadline'] || extractedMetadata['SEOHeadline'] || extractedMetadata['Headline'] || extractedMetadata['Title'] || '',
    leadText: extractedMetadata['LeadText'] || extractedMetadata['Excerpt'] || '',
    metaTitle: extractedMetadata['SocialHeadline'] || extractedMetadata['MetaTitle'] || '',
    metaDescription: extractedMetadata['MetaDescription'] || '',
    newsLettersAndReferrersHeadline: extractedMetadata['NewslettersHeadline'] || extractedMetadata['ReferrersHeadline'] || '',
  };

  return metadata;
};

const cleanContent = (str, cleanMetadata = false) => {
  // Define metadata keywords to be removed
  const metadataKeywords = [
    'Title', 'Word Count', 'Sources', 'Excerpt', 'Similarity Score',
    'Meta Title', 'Social Headline', 'Meta Description', 'Lead Text',
    'SEO Title', 'Headline', 'Newsletters Headline', 'Conclusion',
    'Reference', 'Published by', 'Dated', 'Date', 'Date Range', 'Time',
    'Published in Print Title', 'Director', 'Publication Reference', 'Publication', 'URL'
  ];

  // Generate metadata patterns dynamically
  const metadataPatterns = metadataKeywords.flatMap(keyword => [
    new RegExp(`^#\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^##\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^###\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^####\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^\\*\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^\\*\\*\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^\\*\\*\\*\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^\\*\\*\\*\\*\\s*${keyword}:?.*$`, 'm'),
    new RegExp(`^${keyword}:?.*$`, 'm')
  ]);

  // Check if the first line is a title and remove it if it matches the pattern
  const lines = str.split('\n');
  if (/^###\s/.test(lines[0])) {
    lines.shift();
  }

  let cleanedStr = lines.join('\n');

  // Remove each metadata pattern
  metadataPatterns.forEach(pattern => {
    cleanedStr = cleanedStr.replace(pattern, '');
  });

  // Remove hashes at the beginning and end of the string
  cleanedStr = cleanedStr.replace(/^(#\s*|##\s*|###\s*|####\s*)|(\s*#|##|###|####)$/g, '');

  // Remove asterisks at the beginning and end of the string
  cleanedStr = cleanedStr.replace(/^(^\*\s*|\*\*\s*|\*\*\*\s*|\*\*\*\*\s*)|(\s*\*|\*\*|\*\*\*|\*\*\*\*)$/g, '');

  // Remove double quotes at the beginning and end
  cleanedStr = cleanedStr.replace(/^"(.*)"$/, '$1');

  // Remove single quotes at the beginning and end
  cleanedStr = cleanedStr.replace(/^'(.*)'$/, '$1');

  // Remove ```markdown from the beginning and ``` from the end
  cleanedStr = cleanedStr.replace(/^```markdown\s*/, '');
  cleanedStr = cleanedStr.replace(/\s*```$/, '');

  if (cleanMetadata) {
    // Replace & with 'and'
    cleanedStr = cleanedStr.replace(/&/g, 'and');
    // Remove asterisks from the string
    cleanedStr = cleanedStr.replace(/\*/g, '');
    // Remove full stop at the end if present
    cleanedStr = cleanedStr.replace(/\.$/, '');
  }

  return cleanedStr.trim();
};

export {
  copyToClipboard,
  extractMetadata,
  cleanContent
};
