import api from '../api';

const initiateWebCrawling = async (body) => {
  try {
    const url = `/v1/web/crawl`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default {
  initiateWebCrawling,
};
