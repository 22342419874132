import api from '../api';

const fetchUserMessages = async (user_id) => {
  try {
    const url = `/v1/users/${user_id}/messages`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};

const fetchUserMessageById = async (user_id, message_id) => {
  try {
    const url = `/v1/users/${user_id}/messages/${message_id}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};

const updateUserMessage = async (user_id, message_id, messageData) => {
  try {
    const url = `/v1/users/${user_id}/messages/${message_id}`;
    const { data } = await api.put(url, messageData);
    return data;
  } catch (error) {
    console.error("Error updating user messages:", error);
    throw error;
  }
};

const fetchUserTasks = async (user_id) => {
  try {
    const url = `/v1/users/${user_id}/tasks`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};

const fetchUserTaskById = async (user_id, task_id) => {
  try {
    const url = `/v1/users/${user_id}/tasks/${task_id}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};

const updateUserTask = async (user_id, task_id, taskData) => {
  try {
    const url = `/v1/users/${user_id}/tasks/${task_id}`;
    const { data } = await api.put(url, taskData);
    return data;
  } catch (error) {
    console.error("Error updating user task:", error);
    throw error;
  }
};

const createTask = async (user_id, taskData) => {
  try {
    const url = `/v1/users/${user_id}/tasks`;
    const { data } = await api.post(url, taskData);
    return data;
  } catch (error) {
    console.error("Error creating user task:", error);
    throw error;
  }
};

const deleteUserTask = async (user_id, task_id) => {
  try {
    const url = `/v1/users/${user_id}/tasks/${task_id}`;
    await api.delete(url);
    return { message: "Task deleted successfully" };
  } catch (error) {
    console.error("Error deleting user task:", error);
    throw error;
  }
};

export default {
  fetchUserMessages,
  fetchUserMessageById,
  updateUserMessage,
  fetchUserTasks,
  fetchUserTaskById,
  updateUserTask,
  createTask,
  deleteUserTask,
};
