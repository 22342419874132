import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { debounce } from 'lodash';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from 'date-fns/locale';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomInput = ({
  type,
  label,
  value,
  onChange,
  options = [],
  tooltipTitle = '',
  disableClearable = true,
  sx = {},
  fullWidth = true,
  multiline = true,
  rows = 7,
  gridSize = { xs: 12, md: type === 'text' ? 12 : 6 },
  getOptionLabel = (option) => option.label || '',
  isOptionEqualToValue = (option, value) => option.value === value.value,
}) => {
  const debouncedOnChange = React.useCallback(debounce((newValue) => onChange(newValue), 800), []);

  const handleChangeMultiSelect = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('all')) {
      onChange(options.length === value.length - 1 ? [] : options.map(option => option.value));
    } else {
      onChange(value);
    }
  };

  return (
    <Grid item {...gridSize}>
      <Tooltip title={tooltipTitle} placement="right">
        {type === 'multiSelect' && (
          <FormControl sx={{ ...sx, width: fullWidth ? '100%' : 300 }}>
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
              size="small"
              labelId={`${label}-label`}
              id={`${label}-multi-select`}
              multiple
              value={value.length > 0 ? value.map((item) => item.value) : []}
              onChange={handleChangeMultiSelect}
              input={<OutlinedInput label={label} />}
              renderValue={(selected) =>
                selected
                  .map((val) =>
                    // Attempt to find the option
                    options.find((option) => option.value === val)
                      // Use the found option if it exists, otherwise return a placeholder or empty string
                      ? options.find((option) => option.value === val).label
                      : ''
                  )
                  .join(', ')
              }
              MenuProps={MenuProps}
            >
              {options.length > 1 &&
                <MenuItem value="all">
                  <Checkbox checked={options.length > 0 && value.length === options.length} indeterminate={value.length > 0 && value.length < options.length} />
                  <ListItemText primary="Select All" />
                </MenuItem>
              }
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={value?.some((item) => item.value === option.value) || false} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {type === 'autocomplete' && (
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                fullWidth={fullWidth}
                sx={sx}
              />
            )}
            disableClearable={disableClearable}
          />
        )}
        {type === 'select' && (
          <Autocomplete
            size="small"
            value={value}
            onInputChange={(event, newInputValue) => {
              debouncedOnChange(newInputValue);
            }}
            options={options}
            getOptionLabel={(option) => option || ''}
            isOptionEqualToValue={(option, value) => option === value}
            freeSolo={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                fullWidth={fullWidth}
                sx={sx}
              />
            )}
            disableClearable={disableClearable}
          />
        )}
        {type === 'text' && (
          <TextField
            size="small"
            label={label}
            variant="outlined"
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            sx={sx}
          />
        )}
        {type === 'switch' && (
          <Switch
            label={label}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            sx={sx}
          />
        )}
        {type === 'dateTimePicker' && (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <DateTimePicker
              label={label}
              renderInput={(props) => <TextField {...props} />}
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          </LocalizationProvider>
        )}
      </Tooltip>
    </Grid>
  );
};

export default CustomInput;
