import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchTemplates } from "../../../server/fetchTemplates";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import "../styles/template.scss";
import Divider from "@mui/material/Divider";

const SelectTemplate = (props) => {
  const redirectToUseTemplate = (template_id) => {
    let path = `/use-template/${template_id}`;
    navigate(path);
  };
  const [templates, setTemplates] = useState([]);
  const fetchTemplateList = async () => {
    const data = await fetchTemplates("MohamedJama");
    setTemplates(data);
  };
  const navigate = useNavigate();
  const handleBuildTemplate = () => {
    navigate("/template");
  };

  useEffect(() => {
    fetchTemplateList();
  }, []);
  return (
    <div>
      <div className="selecttemplatepage">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={2}>
            <Button
              data-testid="create-newtemplate-button"
              size="small"
              variant="contained"
              onClick={handleBuildTemplate}
            >
              Create New Template
            </Button>
          </Grid>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ height: "500px" }}
          />
          <Grid item sm={9}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 850 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "Bold" }}>
                      Template Name
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "Bold" }}>
                      Template Type
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "Bold" }}>
                      Created By
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "Bold" }}>
                      Created Date
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "Bold" }}>
                      Last Used
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() => redirectToUseTemplate(row.id)}
                      data-testid={`table-row-${row.id}`}
                    >
                      <TableCell align="center">{row.template_name}</TableCell>
                      <TableCell align="center">{row.template_type}</TableCell>
                      <TableCell align="center">{row.created_by}</TableCell>
                      <TableCell align="center">
                        {row.created_date
                          ? row.created_date.split("T")[0]
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.last_viewed ? row.last_viewed.split("T")[0] : "NA"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SelectTemplate;
