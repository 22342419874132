import React, { useState } from 'react';

const ModelSelector = ({ onSelectModel }) => {
  const [selectedModel, setSelectedModel] = useState('');

  const handleModelChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedModel(selectedValue);
    onSelectModel(selectedValue);
  };

  return (
    <div>
      <h2>Model Selection</h2>
      <label>Select a model to fine tune (optional): </label>
      <select onChange={(e) => onSelectModel(e.target.value)}>
        <option value=""></option>
        <option value="gpt-3.5-turbo-0125">gpt-3.5-turbo-0125</option>
        <option value="gpt-3.5-turbo-1106">gpt-3.5-turbo-1106</option>
      </select>
    </div>
  );
};

export default ModelSelector;
