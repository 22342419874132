import { useDrop } from "react-dnd";
import Grid from "@mui/material/Grid";
import { ItemFacts } from "../styledComponents";
import React, { useState } from "react";

const DroppedFacts = (props) => {
  const { moveStoryBoardFacts } = props;
  const [headerFacts, setHeaderFacts] = useState([]);

  const updateStoryBoard = (item) => {
    setHeaderFacts((headerFacts) => [...headerFacts, item]);
    moveStoryBoardFacts(item);
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "fact",
    drop: (item, monitor) => updateStoryBoard(item.fact),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const renderStoryBoardFacts = () => {
    return headerFacts.map((storyfact, index) => {
      return (
        <Grid item xs={4} sm={4} md={4} key={index}>
          <ItemFacts>{storyfact}</ItemFacts>
        </Grid>
      );
    });
  };
  return (
    <div
      style={{ border: isOver ? "1px solid red" : "1px solid lightgrey" }}
      ref={drop}
    >
      <Grid container direction="column" rowSpacing={1}  xs={12} sm={12} md={12}>
       <p className="Dropbox">Drop Here</p>
        {renderStoryBoardFacts()}
      </Grid>
    </div>
  );
};

export default DroppedFacts;
