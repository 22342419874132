export const languages = [
  { value: "English (British English)", label: "English (British English)" },
  { value: "English (American)", label: "English (American)" },
  { value: "Mandarin Chinese", label: "Mandarin Chinese" },
  { value: "Spanish", label: "Spanish" },
  { value: "Hindi-Urdu", label: "Hindi-Urdu" },
  { value: "Arabic", label: "Arabic" },
  { value: "Bengali", label: "Bengali" },
  { value: "Portuguese (European)", label: "Portuguese (European)" },
  { value: "Russian", label: "Russian" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "French (Standard)", label: "French (Standard)" },
  { value: "French (Quebec)", label: "French (Quebec)" },
];