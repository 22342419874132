import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import EditorSection from "../../template/textEditorSection";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./aiwriter.scss";
import AiWriterSelection from "./aiWriterSelection";
import feedsApi from "../../../server/feedsApi.js";
import completionsApi from "../../../server/completionsApi.js";
import { EditorState, convertToRaw } from "draft-js";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import insertText from "../../reusableComponent/sendTextToEditor";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

const AiWriter = () => {
  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [copyTextSuccessAlert, setCopyTextSuccessAlert] = useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [writerConfig, setWriterConfig] = useState(null);


  const handleClickOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleCreateTemplate = async (config) => {
    if (writerConfig === null) {
      setWriterConfig(config);
    } else if (JSON.stringify(config.settings) !== JSON.stringify(writerConfig.settings)) {
      setWriterConfig(config);
    }

    refreshTextEditor();
    setLoading(true);
    try {
      if ((config?.description?.trim() === '') && (config?.message?.trim() === '')) {
        throw new Error('Please provide a description or a message for the article.');
      }

      let sportsApiResponse;
      if (config.settings.serviceType === "Player Comparison") {
        sportsApiResponse = await feedsApi.postPlayerComparison(config);
      } else if (config.settings.serviceType === "Game Report") {
        sportsApiResponse = await feedsApi.postMatchReport(config);
      }

      if (sportsApiResponse.status === 200) {
        const apiData = sportsApiResponse.data;
        const response = await completionsApi.sendMessageToCompletionsAPI(apiData);

        // Successful direct API call
        if (response.status === 200 && response.message && response.messages) {
          const article = typeof response.message === 'string' ? response.message : '';
          const firstLine =
            article
              ?.split('\n')
              .find((line) => line.trim() !== '')
              ?.replace(/^"|"$/g, '') ?? '';
          const articleTitle = config.title !== "" ? config.title : firstLine;
          setTitle(articleTitle);
          setText(article);
          setLoading(false);
        } else if (response.status === 400) {
          throw new Error('Bad request: The request was invalid or cannot be served.');
        } else if (response.status === 500) {
          throw new Error('Internal server error: The server encountered an unexpected condition.');
        } else {
          throw new Error('Unknown error: An unexpected error occurred.');
        }
      } else {
        throw new Error(`API Error: ${sportsApiResponse?.response?.data?.message || sportsApiResponse.message}`);
      }
    } catch (error) {
      setText(`${error ? error.toString() : "Error: An error occurred. Please try again."}`);
      setLoading(false);
    }
  };
  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(
        convertToRaw(editorState.getCurrentContent()).blocks[0].text
      );
      setCopyTextSuccessAlert(true);
    } catch {
      return "Unable to copy text";
    }
  };
  const handleClose = () => {
    setCopyTextSuccessAlert(false);
  };
  const refreshTextEditor = () => {
    setEditorState(initialState);
  };
  useEffect(() => {
    sendTextToEditor(text);
  }, [text]);
  return (
    <div>
      <Container maxWidth="xl">
        <div className="ai-writer">
          <Grid container spacing={2}>
            <Grid item sm={5}>
              <AiWriterSelection handleClickOpenWarning={handleClickOpenWarning} handleCreateTemplate={handleCreateTemplate} />
            </Grid>
            <Grid item sm={7} className="text-editor">
              <div className="loadingcomponent">
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size="6rem" color="inherit" />
                    <Typography position="absolute">
                      Generating<br></br>Article
                    </Typography>
                  </Box>
                </Backdrop>
              </div>
              <div>
                <EditorSection
                  loading={loading}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  title={title}
                  handleTitleChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="copybuttons">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Snackbar
                    open={copyTextSuccessAlert}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleClose}
                  >
                    <Alert severity="success">
                      <AlertTitle>Success</AlertTitle>
                      Text Copied Successfully
                    </Alert>
                  </Snackbar>
                  <IconButton onClick={copyToClipboard} aria-label="copy">
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={refreshTextEditor} aria-label="refresh">
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default AiWriter;
