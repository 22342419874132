import api from '../api';

const loadOptions = async (inputValue, type, sport) => {
  if (inputValue.length > 3) {
    const url = `/facts/sport/dropdowns/${sport}/${type}/${inputValue}`;
    const { data } = await api.get(url);

    return data.map((obj) => {
      return {
        label:
          type === "players"
            ? `${obj.player_name} - ${obj.team}`
            : obj.team_name,
        value: "no Summary Available for this player",
      };
    });
  }
};

export default loadOptions;
