export const articleTypes = [
  {
    "label": "3am Daily video",
    "value": "three_am_daily_video"
  },
  {
    "label": "Advertising Feature",
    "value": "advertising"
  },
  {
    "label": "Advertorial",
    "value": "advertorial"
  },
  {
    "label": "Affiliate",
    "value": "affiliate"
  },
  {
    "label": "Analysis",
    "value": "analysis"
  },
  {
    "label": "Backgrounder",
    "value": "backgrounder"
  },
  {
    "label": "Blog",
    "value": "blog"
  },
  {
    "label": "Columnist / Commentator",
    "value": "columnist"
  },
  {
    "label": "Event / match report",
    "value": "event_report"
  },
  {
    "label": "Football daily video",
    "value": "football_daily_video"
  },
  {
    "label": "Football spy video",
    "value": "football_spy_video"
  },
  {
    "label": "Game",
    "value": "game"
  },
  {
    "label": "Guest blogger",
    "value": "guest_blogger"
  },
  {
    "label": "Investigation",
    "value": "investigation"
  },
  {
    "label": "Live blog",
    "value": "live_blog"
  },
  {
    "label": "News Story",
    "value": "news_story"
  },
  {
    "label": "Obituaries",
    "value": "obituaries"
  },
  {
    "label": "Prediction / betting tips",
    "value": "prediction_tips"
  },
  {
    "label": "Profile / Interview",
    "value": "profile_interview"
  },
  {
    "label": "Quiz",
    "value": "quiz"
  },
  {
    "label": "Ratings",
    "value": "ratings"
  },
  {
    "label": "Reaction",
    "value": "reaction"
  },
  {
    "label": "Real-life",
    "value": "real_life"
  },
  {
    "label": "Review",
    "value": "review"
  },
  {
    "label": "Rumour / Gossip",
    "value": "rumour_gossip"
  },
  {
    "label": "Satire",
    "value": "satire"
  },
  {
    "label": "Sponsored",
    "value": "sponsored"
  },
  {
    "label": "Sport Preview",
    "value": "sport_preview"
  },
  {
    "label": "Top 10",
    "value": "top_10"
  },
  {
    "label": "Transfer news",
    "value": "transfer_news"
  },
  {
    "label": "Voice of the Mirror",
    "value": "voice_of_the_mirror"
  },
  {
    "label": "Weird",
    "value": "weird"
  }
];