// React Libraries and Hooks
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

// Authentication
import { useAuth0 } from '@auth0/auth0-react';

// Routing
import { Route, Routes } from 'react-router-dom';

// Component Imports
import FactFinder from './components/board/board';
import Template from './components/template/buildTemplate/buildTemplate';
import UseTemplate from './components/template/useTemplate/useTemplate';
import Setting from './components/userSetting/userSetting';
import SelectTemplate from './components/template/selectTemplate/selectTemplate';
import AiWriter from './components/aiWriter/createArticles/aiWriter';
import AiChat from './components/aiChat/aiChat';
import IndexPage from './components/index';
import PushToCMS from './components/pushToCMS/PushToCMS';
import BatchProcessor from './components/batchProcessor';
import FeedSelectorComponent from './components/feeds';
import TasksList from './components/tasks';
import CreateTaskPage from './components/tasks/createTask';
import Article from './components/aiWriter/articlesList/fetchArtilcleById';
import Login from './components/login/login';
import FineTuning from './components/fineTuning/FineTuning';
import ErrorFallback from './components/errorComponent'; // Error handling component

// Third-Party Libraries
import { Toaster } from 'react-hot-toast';

// Styles
import './App.scss'; // Main application styling

function App() {
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  if (error) {
    return <h1>Oops... {error.message}</h1>;
  }
  if (isAuthenticated) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Toaster />
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/ai-writer" element={<AiWriter />} />
          <Route path="/ai-chat" element={<AiChat />} />
          <Route path="/push-to-cms" element={<PushToCMS />} />
          <Route path="/batch-processor" element={<BatchProcessor />} />
          <Route path="/feeds" element={<FeedSelectorComponent />} />
          <Route path="/tasks" element={<TasksList />} />
          <Route path="/tasks/:id" element={<CreateTaskPage />} />
          <Route path="/create-task" element={<CreateTaskPage />} />
          <Route path="/ai-chat/:id" element={<AiChat />} />
          <Route path="/fact-finder" element={<FactFinder />} />
          <Route path="/template" element={<Template />} />
          <Route path="/use-template/:id" element={<UseTemplate />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/select-template" element={<SelectTemplate />} />
          <Route path="/fine-tuning" element={<FineTuning />} />
          <Route path="/settings" element={<Setting />} />
        </Routes>
      </ErrorBoundary>
    );
  } else {
    return <Login />;
  }
}

export default App;
