import React from 'react';
import Grid from '@mui/material/Grid';
import CustomInput from './CustomInput.js';

const CustomOptions = ({ options }) => {
  return (
    <Grid container spacing={2}>
      {options.map((option, index) => (
        <CustomInput
          key={index}
          type={option.type}
          label={option.label}
          value={option.value}
          onChange={option.onChange}
          options={option.options}
          tooltipTitle={option.tooltipTitle}
          disableClearable={option.disableClearable}
          sx={option.sx}
          fullWidth={option.fullWidth}
          multiline={option.multiline}
          rows={option.rows}
          gridSize={option.gridSize}
          getOptionLabel={option.getOptionLabel}
          isOptionEqualToValue={option.isOptionEqualToValue}
        />
      ))}
    </Grid>
  );
};

export default CustomOptions;
