import React from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

const ImageBlock = ({ src, alt }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = alt || 'image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img src={src} alt={alt} style={{ maxWidth: '100%' }} />
      <IconButton
        aria-label="Download"
        onClick={handleDownload}
        style={{
          color: '#fff',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <DownloadIcon />
      </IconButton>
    </div>
  );
};

export default ImageBlock;
