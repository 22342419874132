import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import AddVariableTemplate from "./addVariable";
import EditorSection from "../textEditorSection";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import insertText from "../../reusableComponent/sendTextToEditor";

import "../styles/template.scss";
const Template = () => {
  //functionality for add variable button
  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);
  const [convertedContent, setConvertedContent] = useState(null);
  const [templateTitle, setTemplateTitle] = useState("");

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const handleTitleChange = (e) => {
    setTemplateTitle(e.target.value);
  };
  useEffect(() => {
    let textvalue = convertToRaw(editorState.getCurrentContent());
    setConvertedContent(textvalue);
  }, [editorState]);

  const fetchText = convertedContent ? convertedContent.blocks[0].text : "";

  return (
    <div>
      <div className="templatepage">
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <AddVariableTemplate
              sendTextToEditor={sendTextToEditor}
              fetchText={fetchText}
              templateTitle={templateTitle}
            />
          </Grid>
          <Grid item sm={8} className="text-editor">
            <EditorSection
              editorState={editorState}
              setEditorState={setEditorState}
              handleTitleChange={handleTitleChange}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Template;
