import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import loadOptions from "../../server/dbpedia";
import {
  getSubjectFacts,
  getAvailableGames,
  getGameData,
  getSeasonData,
  getImage,
} from "../../server/sportsfeed";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Storyboard from "./storyBoard/storyboard";
import "react-datepicker/dist/react-datepicker.css";

import "./styles/board.scss";

import * as utils from "../../utils.js";
import CircularProgress from "@mui/material/CircularProgress";
import RenderSelectBox from "./selectBox";
import SummaryBox from "./summary";

const Board = () => {
  const typeData = [
    { value: "season", label: "Season" },
    { value: "games", label: "Games" },
    { value: "players", label: "Player" },
    { value: "teams", label: "Team" },
  ];

  const sportData = [
    { value: "nfl", label: "NFL" },
    { value: "nba", label: "NBA" },
    { value: "nhl", label: "NHL" },
    { value: "mlb", label: "MLB" },
  ];

  const [expanded, setExpanded] = useState(false);
  const [subject, setSubject] = useState("");
  const [summary, setSummary] = useState("");
  const [img, setImg] = useState("");

  const [facts, setFacts] = useState([]);
  const [allDatafact, setAlldata] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const [sport, setSport] = useState("");
  const [game, setGame] = useState("");

  const [dataCategory, setDatacategory] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [storyBoardFacts, setStoryBoardFacts] = useState([]);
  const [endDate, setEndDate] = useState(new Date());

  //add and remove subsection Count
  const [subsectionCount, setSubsectionCount] = useState(2);

  const addToSubsetionCount = () => {
    setSubsectionCount((subsectionCount) => subsectionCount + 1);
  };
  const removeSubsetionCount = () => {
    setSubsectionCount((subsectionCount) => subsectionCount - 1);
  };

  // functionality for pagination
  const [pages, setPages] = useState(0);
  const [pagination, showPagination] = useState(false);

  const perPageItem = 9; // set display facts on per page = 9

  const pagesSize = Math.ceil(pages.length / perPageItem); //divided data length/9 = pagesize

  const moveStoryBoardFacts = (item) => {
    setStoryBoardFacts((storyBoardFacts) => [...storyBoardFacts, item]);
  };

  // handle change function for pagination
  const handlePageChange = (event, page) => {
    const start = (page - 1) * perPageItem;
    const end = start + perPageItem;
    const data = pages.slice(start, end);
    setFacts(data);
  };

  // function for set page when page or facts is rendering 1st time
  const paginationData = (dataByCategory, allData) => {
    const start = 0;
    const end = 9;
    const data = dataByCategory.slice(start, end);
    setFacts(data);
    setLoading(false);
    setPages(dataByCategory);
  };

  const handleLoadOptions = (inputValue) => {
    return type === "players"
      ? loadOptions(inputValue, "players")
      : loadOptions(inputValue, "teams");
  };

  const handleLoadGamesOptions = async () => {
    if (type === "games" && startDate && endDate) {
      const start_date = utils.isoToDate(startDate.toISOString());
      const end_date = utils.isoToDate(endDate.toISOString());
      const matches = await getAvailableGames(
        sport,
        type,
        start_date,
        end_date
      );
      const mapOptions = matches
        .map((match) => {
          return {
            label: `${match["Final Score"]}, ${match["Game Date"]}`,
            value: match.gameId,
          };
        })
        .slice(0, 20);
      return mapOptions;
    }
  };

  const getFactsData = async () => {
    setFacts([]);
    if (type !== "games") {
      try {
        setLoading(true);

        if (dataCategory !== "" && subject !== "") {
          switch (type) {
            case "players":
              const playersStartDate = utils.isoToDate(startDate.toISOString());
              const playersData = await getSubjectFacts(
                subject,
                type,
                sport,
                playersStartDate
              );
              const playersDataByCategory = utils.getDataByCategory(
                playersData,
                dataCategory
              );
              setAlldata(playersDataByCategory);
              paginationData(playersDataByCategory, playersData);
              showPagination(true);
              break;
            case "teams":
              const teamsData = await getSubjectFacts(subject, type, sport);
              const teamsDataByCategory = utils.getDataByCategory(
                teamsData,
                dataCategory
              );
              setAlldata(teamsDataByCategory);
              paginationData(teamsDataByCategory, teamsData);
              showPagination(true);
              break;
            case "season":
              const seasonData = await getSeasonData(sport);
              const seasonDataByCategory = utils.getDataByCategory(
                seasonData,
                dataCategory
              );
              setAlldata(seasonDataByCategory);
              paginationData(seasonDataByCategory, seasonData);
              showPagination(true);
              break;
            default:
              break;
          }
        } else {
          alert("please select all dropdown");
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        if (dataCategory !== "" && game !== "") {
          const gamesData = await getGameData(sport, game);
          const gamesDataByCategory = utils.getDataByCategory(
            gamesData,
            dataCategory
          );
          setAlldata(gamesDataByCategory);
          paginationData(gamesDataByCategory, gamesData);
          showPagination(true);
        } else {
          alert("please select all dropdown");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (type !== "" && subject !== "" && summary !== "") {
      const fetchImage = async () => {
        setLoading(true);
        const img = await getImage(sport, type, subject);
        setImg(img);
        setLoading(false);
      };
      fetchImage().catch((err) => console.log(err));
    }
  }, [type, subject, dataCategory, game, startDate, endDate, sport, summary]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleInputChangeSport = (val) => {
    if (val) {
      setSport(val.value);
    } else {
      setSport("");
      setType("");
      setSubject("");
      setSummary("");
      setGame("");
      setDatacategory("");
      setFacts([]);
      setImg("");
    }
  };

  const handleInputChangeGame = (val) => {
    if (val) {
      setGame(val.value);
    } else {
      setGame("");
    }
  };
  const handleInputChangeType = (val) => {
    if (val) {
      setType(val.value);
    } else {
      setType("");
      setSubject("");
      setSummary("");
      setGame("");
      setDatacategory("");
      setFacts([]);
      setImg("");
    }
  };
  const handleInputChangeDataCategory = (val) => {
    if (val) {
      setDatacategory(val.value);
    } else {
      setDatacategory("");
      setFacts([]);
    }
  };
  const handleInputChangeSearch = (val) => {
    if (val) {
      setSubject(val.label);
      setSummary(val.value);
    } else {
      setSubject("");
      setSummary("");
      setImg("");
      showPagination("");
      setFacts([]);
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#f2f1f1" }}>
        <div className="factfinder">
          <h1>Fact Finder</h1>
          <Grid container spacing={2}>
            <Grid item sm={2}>
              <AsyncSelect
                defaultOptions={sportData}
                placeholder="Sport"
                isClearable={true}
                onChange={handleInputChangeSport}
              />
            </Grid>
            <Grid item sm={2}>
              <AsyncSelect
                placeholder="Type"
                defaultOptions={typeData}
                isClearable={true}
                onChange={(e) => {
                  handleInputChangeType(e);
                }}
              />
            </Grid>
            <RenderSelectBox
              startDate={startDate}
              setStartDate={setStartDate}
              handleExpandClick={handleExpandClick}
              handleInputChangeDataCategory={handleInputChangeDataCategory}
              handleInputChangeGame={handleInputChangeGame}
              getFactsData={getFactsData}
              endDate={endDate}
              setEndDate={setEndDate}
              handleLoadGamesOptions={handleLoadGamesOptions}
              type={type}
              handleInputChangeSearch={handleInputChangeSearch}
              handleLoadOptions={handleLoadOptions}
            />
          </Grid>
          <div className="loadingcomponent">
            {loading && <CircularProgress />}
          </div>
          <SummaryBox
            summary={summary}
            img={img}
            expanded={expanded}
            loading={loading}
            handleExpandClick={handleExpandClick}
            facts={facts}
            allDatafact={allDatafact}
            pagination={pagination}
            handlePageChange={handlePageChange}
            pagesSize={pagesSize}
          />
        </div>
        <Storyboard
          type={type}
          storyBoardFacts={storyBoardFacts}
          moveStoryBoardFacts={moveStoryBoardFacts}
          addToSubsetionCount={addToSubsetionCount}
          removeSubsetionCount={removeSubsetionCount}
          subsectionCount={subsectionCount}
        />
      </Box>
    </div>
  );
};

export default Board;
