import api from '../api';

const sendArticleToCMS = async (input) => {
  try {
    const url = `/v1/cms/push`;

    const { data } = await api.post(url, input);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default {
  sendArticleToCMS
};
