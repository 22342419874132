import Button from "@mui/material/Button";
import React, { useState } from "react";
import { postTemplate } from "../../server/templates";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";

const FactFinderSection = (props) => {
  const {
    groups,
    type,
    sport,
    variables,
    text,
    template_name,
    handleResetTemplate,
  } = props;

  const navigate = useNavigate();
  const handleSelectTemplate = () => {
    navigate("/select-template");
  };
  const location = useLocation();

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorsAlert] = useState(false);

  const [alertContent, setAlertContent] = useState("");

  const handleClose = () => {
    setSuccessAlert(false);
  };

  const handleSaveTemplate = async () => {
    try {
      await postTemplate(groups, type, sport, variables, text, template_name);
      setAlertContent(template_name);
      setSuccessAlert(true);
    } catch (error) {
      console.error(error);
      setErrorsAlert(true);
    }
  };
  return (
    <div>
      <div>
        <Snackbar         
          open={successAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
          data-testid="save-template-alert"
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your template <strong>{alertContent}</strong> has been successfully
            saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            You haven't <strong>{alertContent}</strong>saved your changes!
          </Alert>
        </Snackbar>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: "10px 0" }}
        >
          <Button
            sx={{
              width: location.pathname === "/template" ? "100px" : "150px",
            }}
            size="small"
            variant="contained"
            onClick={handleSelectTemplate}
            data-testid="load-template-button"
          >
            Load Template
          </Button>
          <Button
            sx={{
              marginLeft: "10px",
              width: location.pathname === "/template" ? "100px" : "150px",
            }}
            data-testid="save-template-button"
            size="small"
            variant="contained"
            onClick={handleSaveTemplate}
          >
            Save Template
          </Button>
          {location.pathname === "/template" && (
            <Button
              sx={{ marginLeft: "10px", width: "100px" }}
              size="small"
              variant="contained"
              onClick={handleResetTemplate}
            >
              Reset Template
            </Button>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default FactFinderSection;
