import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import "../styles/../styles/template.scss";
import {
  StartDatePicker,
  SearchPlayerTeam,
  EndDatePicker,
} from "../../reusableComponent/selectFacts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const PopupDefineVariables = (props) => {
  const {
    handleCloseDefineVariable,
    openDefineVariablePopup,
    handleInputChangeSearch,
    handleClickSaveVariable,
    searchOptionsPlayers,
    type,
    handleLoadOptions,
  } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleInputStartDate = (value) => {
    setStartDate(value);
  };

  const handleInputEndDate = (value) => {
    setEndDate(value);
  };
  const defineVariablePopup = (type) => {
    switch (type) {
      case "season":
        return (
          <>
            <DialogTitle>Season</DialogTitle>
            <DialogContent>
              <div>
                <Grid container alignItems="end" justifyContent="end">
                  <div className="popupstyle">
                    <StartDatePicker
                      startDate={startDate}
                      handleInputStartDate={handleInputStartDate}
                    />
                    <EndDatePicker
                      endDate={endDate}
                      handleInputEndDate={handleInputEndDate}
                    />
                  </div>
                </Grid>
              </div>
              <div>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {[1, 2, 3].map((value) => (
                    <div key={value}>
                      <ListItem>
                        <ListItemText primary={`Season ${value}`} />
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </div>
            </DialogContent>
          </>
        );
      case "games":
        return (
          <>
            <DialogTitle>Games</DialogTitle>
            <DialogContent>
              <div className="popupBox">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item sm={6}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      sx={{ marginBottom: "10px" }}
                      disableClearable
                      options={searchOptionsPlayers.map((option) => option)} // just for UI purpose hardcoded data
                      onChange={handleInputChangeSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Home Team"
                          id="outlined-size-small"
                          className="outlined-size-small"
                          sx={{ zIndex: "0" }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      sx={{ marginBottom: "10px" }}
                      disableClearable
                      options={searchOptionsPlayers.map((option) => option)} // just for UI purpose hardcoded data
                      onChange={handleInputChangeSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Away Team"
                          id="outlined-size-small"
                          className="outlined-size-small"
                          sx={{ zIndex: "0" }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item sm={6}>
                    <StartDatePicker
                      startDate={startDate}
                      handleInputStartDate={handleInputStartDate}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <EndDatePicker
                      endDate={endDate}
                      handleInputEndDate={handleInputEndDate}
                    />
                  </Grid>
                </Grid>
                <div>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {[1, 2, 3].map((value) => (
                      <div key={value}>
                        <ListItem>
                          <ListItemText primary={`Game ${value}`} />
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </div>
              </div>
            </DialogContent>
          </>
        );
      case "players":
        return (
          <>
            <DialogTitle>Search Player</DialogTitle>
            <DialogContent sx={{ height: "200px" }}>
              <div className="date-picker">
                <StartDatePicker
                  startDate={startDate}
                  handleInputStartDate={handleInputStartDate}
                />
              </div>
              <div data-testid="my-select-component">
                <SearchPlayerTeam  placeholder="Search Player" handleInputChangeSearch={handleInputChangeSearch} handleLoadOptions={handleLoadOptions}/>
              </div>
            </DialogContent>
          </>
        );
      case "teams":
        return (
          <>
            <DialogTitle>Search Team</DialogTitle>
            <DialogContent sx={{ height: "200px" }}>
              <div className="date-picker">
                <StartDatePicker
                  startDate={startDate}
                  handleInputStartDate={handleInputStartDate}
                />
              </div>
              <div>
                <SearchPlayerTeam  placeholder="Search Team" handleInputChangeSearch={handleInputChangeSearch} handleLoadOptions={handleLoadOptions}/>
              </div>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <div className="left-section">
        <div>
          <Dialog
            fullWidth
            data-testid="define-variable-dialog"
            open={openDefineVariablePopup}
            sx={{ height: "500px" }}
            onClose={handleCloseDefineVariable}
          >
            <div>{defineVariablePopup(type)}</div>
            <DialogActions>
              <Button onClick={handleCloseDefineVariable}>Cancel</Button>
              <Button
                data-testid="save-variable-button"
                onClick={handleClickSaveVariable}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default PopupDefineVariables;
