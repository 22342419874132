// Libraries
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

// Local Files
import './typingAnimation.scss';

const TypingAnimation = ({ text = '' }) => {
  const [content, setContent] = useState('');
  const [index, setIndex] = useState(0);
  const [blinking, setBlinking] = useState(false);

  useEffect(() => {
    // If text is not provided, we just display the blinking cursor
    if (text.length === 0) {
      setBlinking(true);
      return;
    } else {
      const interval = setInterval(() => {
        if (index < text.length) {
          setContent((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        } else {
          clearInterval(interval);
        }
      }, 100);
  
      return () => clearInterval(interval);
    }
  }, [text, index]);

  return (
    <div>
      {blinking ? (
        <p className="blinking-cursor">|</p>
      ) : (
        <ReactMarkdown>{content}</ReactMarkdown>
      )}
    </div>
  );
};

export default TypingAnimation;
