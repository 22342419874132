import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const publisher = [{ value: "Reach PLC", label: "Reach PLC" }];
const userRoles = [
  { value: "admin", label: "Admin" },
  { value: "superAdmin", label: "Super Admin" },
  { value: "user", label: "User" },
];

const EditBulkAction = (props) => {
  const {
    handleChangeUserRole,
    handleChangeUserPublisher,
    handleCloseEditPopup,
    handleEditUser,
    editActionPopupOpen,
  } = props;
  return (
    <>
      <Dialog
        fullWidth
        open={editActionPopupOpen}
        sx={{ height: "500px" }}
        onClose={handleCloseEditPopup}
      >
        <DialogTitle>Edit User(s)</DialogTitle>
        <DialogContent sx={{ height: "200px" }}>
          <div>
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={publisher}
              sx={{ marginBottom: "10px" }}
              onChange={handleChangeUserPublisher}
              renderInput={(params) => (
                <TextField {...params} label="Publisher" />
              )}
            />
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={userRoles}
              onChange={handleChangeUserRole}
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditPopup}>Cancel</Button>
          <Button data-testid="save-variable-button" onClick={handleEditUser}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const UserAction = (props) => {
  const {
    handleChangeUsername,
    handleChangeUserEmail,
    handleChangeUserRole,
    handleChangeUserPublisher,
    handleChangeUserArticles,
    handleChangeUsernamTokenused,
    handleCloseUserPopup,
    userActionPopupOpen,
    oldData,
    handleUpdateSingleUserData
  } = props;
  return (
    <>
      <Dialog
        fullWidth
        open={userActionPopupOpen}
        sx={{ height: "500px" }}
        onClose={handleCloseUserPopup}
      >
        <DialogTitle>User Action(s)</DialogTitle>
        <DialogContent sx={{ height: "300px" }}>
          <div>
            <Autocomplete
              id="size-small-outlined"
              size="small"
              defaultValue={oldData.publisher}
              options={publisher}
              onChange={handleChangeUserPublisher}
              renderInput={(params) => (
                <TextField {...params} label="Publisher" />
              )}
            />
            <TextField
              sx={{ width: "100%", margin: "10px 0px" }}
              label="User Name"
              defaultValue={oldData.name}
              id="outlined-size-small"
              onChange={handleChangeUsername}
              className="outlined-size-small"
              size="small"
            />
            <TextField
              sx={{ width: "100%", marginBottom: "10px" }}
              label="User Email"
              defaultValue={oldData.email}
              id="outlined-size-small"
              onChange={handleChangeUserEmail}
              className="outlined-size-small"
              size="small"
            />
            <Autocomplete
              id="size-small-outlined"
              sx={{ width: "100%", marginBottom: "10px" }}
              size="small"
              defaultValue={oldData.role}
              options={userRoles}
              onChange={handleChangeUserRole}
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
            <TextField
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Articles"
              id="outlined-size-small"
              defaultValue={oldData.articles}
              onChange={handleChangeUserArticles}
              className="outlined-size-small"
              size="small"
            />
            <TextField
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Toke Used"
              defaultValue={oldData.token_used}
              id="outlined-size-small"
              onChange={handleChangeUsernamTokenused}
              className="outlined-size-small"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserPopup}>Cancel</Button>
          <Button
            data-testid="save-variable-button"
            onClick={handleUpdateSingleUserData}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const AddNewUser = (props) => {
  const {
    handleCloseAddUserPopup,
    addUserPopupOpen,
    handleChangeUsername,
    handleChangeUserEmail,
    handleChangeUserRole,
    handleChangeUserPublisher,
    createUser,
  } = props;
  return (
    <>
      <Dialog
        open={addUserPopupOpen}
        sx={{ height: "500px" }}
        onClose={handleCloseAddUserPopup}
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent sx={{ height: "200px" }}>
          <div>
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={publisher}
              onChange={handleChangeUserPublisher}
              renderInput={(params) => (
                <TextField {...params} label="Publisher" />
              )}
            />
            <TextField
              sx={{ width: "100%", margin: "10px 0px" }}
              label="Name"
              id="outlined-size-small"
              onChange={handleChangeUsername}
              className="outlined-size-small"
              size="small"
            />
            <TextField
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Email"
              id="outlined-size-small"
              onChange={handleChangeUserEmail}
              className="outlined-size-small"
              size="small"
            />
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={userRoles}
              onChange={handleChangeUserRole}
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddUserPopup}>Cancel</Button>
          <Button data-testid="save-variable-button" onClick={createUser}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export { EditBulkAction, UserAction, AddNewUser };
