const NFLPlayerData = {
  dailyPlayerData: {
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Tackles": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Interceptions": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Games Played": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Defense Snaps": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Offense Snaps": 65,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Special Team Snaps": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Pass Attempts": 50,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Pass Completions": 32,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Pass Yards": 379,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Pass Avg": 7.6,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Pass TD percentage": 8,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Rush Attempts": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Rush Yards": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Rush Average": 0,
    "Tom Brady vs Dallas Cowboys, 2021-09-10 - Rush TD": 0,
  },
  basicPlayerData: {
    "Tom Brady - Age": 45,
    "Tom Brady - Position": "QB",
    "Tom Brady - Injury Status": "Player is not injured",
    "Tom Brady - Height": "6'4\"",
    "Tom Brady - Weight": 225,
    "Tom Brady - Current Team": "TB",
  },
  weeklyPlayerData: {
    "Game Week": "no weekly data available for 43",
  },
  playerInjuryHistoryData: [
    {
      "Tom Brady - Current Injury": "2021-07-18",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Questionable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "N/A",
    },
    {
      "Tom Brady - Current Injury": "2021-09-07",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Probable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "2021-09-10T14:39:58.000Z",
    },
    {
      "Tom Brady - Current Injury": "2021-10-12",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Questionable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "N/A",
    },
    {
      "Tom Brady - Current Injury": "2021-10-13",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Probable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "2021-10-15T12:02:37.000Z",
    },
    {
      "Tom Brady - Current Injury": "2021-10-23",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Probable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "2021-10-25T11:31:19.000Z",
    },
    {
      "Tom Brady - Current Injury": "2022-09-25",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Probable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "N/A",
    },
    {
      "Tom Brady - Current Injury": "2022-10-04",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Questionable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "N/A",
    },
    {
      "Tom Brady - Current Injury": "2022-10-09",
      "Tom Brady - Out For Season": false,
      "Tom Brady - Next Game probablity": "Probable",
      "Tom Brady - Estimated Return": "N/A",
      "Tom Brady - Actual Return": "2022-10-11T13:02:11.000Z",
    },
  ],
  seasonalPlayerData: {
    "Tom Brady - Games Played": 7,
    "Tom Brady - Games Started": 7,
    "Tom Brady - Tackles": 0,
    "Tom Brady - Interceptions": 0,
    "Tom Brady - Fumbles": 3,
    "Tom Brady - Defense Snaps": 0,
    "Tom Brady - Offense Snaps": 409,
    "Tom Brady - Special Snaps": 0,
    "Tom Brady - Passing": 296,
    "Tom Brady - Pass Completions": 198,
    "Tom Brady - Pass Yards": 1942,
    "Tom Brady - Pass Avg.": 6.6,
    "Tom Brady - Pass TD.": 6.6,
    "Tom Brady - Pass TD. Pct.": 2.7,
    "Tom Brady - Rush Attempts": 11,
    "Tom Brady - Rush Yards": -6,
    "Tom Brady - Rush Avg.": -0.5,
    "Tom Brady - Rush TD.": 0,
  },
};

const NBAPlayerData = {
  dailyPlayerData: {
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Games Started": 1,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - 3 point attempts": 9,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - 3 point percentage": 44.4,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - 2 point attempts": 14,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - 2 point percentage": 78.6,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Points": 36,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Assists": 6,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Offensive Rebounds": 2,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Defensive Rebounds": 3,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Turnovers": 2,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Steals": 2,
    "Kyrie Irving vs Minnesota Timberwolves, 2023-02-14 - Blocks": 1,
  },
  basicPlayerData: {
    "Kyrie Irving - Age": 30,
    "Kyrie Irving - Position": "PG",
    "Kyrie Irving - Injury Status": "Player is not injured",
    "Kyrie Irving - Height": "6'2\"",
    "Kyrie Irving - Weight": 191,
    "Kyrie Irving - Current Team": "DAL",
  },
  weeklyPlayerData: {
    "Game Week": "no weekly data available for 7",
  },
  playerInjuryHistoryData: [
    {
      "Kyrie Irving - Current Injury": "2023-02-06",
      "Kyrie Irving - Out For Season": false,
      "Kyrie Irving - Next Game probablity": "Out",
      "Kyrie Irving - Estimated Return": "N/A",
      "Kyrie Irving - Actual Return": "2023-02-07T12:39:07.000Z",
    },
  ],
  seasonalPlayerData: {
    "Kyrie Irving - Games Played": 40,
    "Kyrie Irving - Games Started": 40,
    "Kyrie Irving - 3 point attempts": 348,
    "Kyrie Irving - 3 point percentage": 37.4,
    "Kyrie Irving - 2 point attempts": 471,
    "Kyrie Irving - 2 point percentage": 56.9,
    "Kyrie Irving - Points": 1084,
    "Kyrie Irving - Points per Game": 27.1,
    "Kyrie Irving - Assists": 210,
    "Kyrie Irving - Assists per Game": 5.3,
    "Kyrie Irving - Offensive Rebounds": 38,
    "Kyrie Irving - Offensive Rebounds per Game": 0.9,
    "Kyrie Irving - Defensive Rebounds": 166,
    "Kyrie Irving - Defensive Rebounds per Game": 4.2,
    "Kyrie Irving - Turnovers": 91,
    "Kyrie Irving - Turnovers per Game": 2.3,
    "Kyrie Irving - Steals": 42,
    "Kyrie Irving - Steals per Game": 1,
    "Kyrie Irving - Blocks": 33,
    "Kyrie Irving - Blocks per Game": 0.8,
  },
};

const teamData = {
  seasonalTeamData: {
    "Games Played": 17,
    "Overall Rank": 6,
    "Conference Rank": "NFC : 4",
    "Division Rank": "NFC East : 1",
  },
  playersData: [
    "DAL - Bryan Anger",
    "DAL - Dorance Armstrong",
    "DAL - Josh Ball",
    "DAL - Anthony Barr",
    "DAL - Tarell Basham",
    "DAL - Markquese Bell",
    "DAL - Mackenzy Bernadeau",
    "DAL - Francis Bernard",
    "DAL - Tyler Biadasz",
    "DAL - Daron Bland",
    "DAL - Quinton Bohanna",
    "DAL - Devante Bond",
  ],
  upcomingGame: {
    "Next Game": "no upcoming games available for this team",
  },
};

const gameData = {
  gameData: {
    Date: "2021-09-10",
    Week: 1,
    "Away Team": "DAL",
    "Home Team": "TB",
    Venue: "Raymond James Stadium",
    Referee: "Shawn Hochuli ",
    "Final Score": "DAL 29 - TB 31",
    "1st Quarter Score": "DAL 7 - TB 7",
    "2nd Quarter Score": "DAL 9 - TB 14",
    "3rd Quarter Score": "DAL 10 - TB 7",
    "4th Quarter Score": "DAL 3 - TB 3",
  },
};

const splitLabels = (labelKey, label) => {
  const newLabel = label.split("-")[label.split("-").length - 1].trim();
  if (typeof newLabel === "string" && newLabel !== undefined) {
    return labelKey === "seasonalPlayerData"
      ? {
          label: `${newLabel} - Season`,
          value: { field: newLabel, type: labelKey },
        }
      : { label: newLabel, value: { field: newLabel, type: labelKey } };
  }
};

const formatLabels = (data) => {
  const allKeys = Object.keys(data);
  let label;
  let listOfLabels = [];

  allKeys.map((labelKey) => {
    label = !Array.isArray(data[labelKey])
      ? Object.keys(data[labelKey])
          .map((l) => splitLabels(labelKey, l))
          .forEach((l) => listOfLabels.push(l))
      : null;
  });
  return listOfLabels;
};

const getLabels = (type, sport) => {
  if (sport === "nfl") {
    switch (type) {
      case "players":
        return formatLabels(NFLPlayerData);
      case "teams":
        return formatLabels(teamData);
      case "games":
        return formatLabels(gameData);
      default:
        break;
    }
  } else
    switch (type) {
      case "players":
        return formatLabels(NBAPlayerData);
      case "teams":
        return formatLabels(teamData);
      case "games":
        return formatLabels(gameData);
      default:
        break;
    }
};
export {
  NFLPlayerData,
  gameData,
  teamData,
  getLabels,
  formatLabels,
  splitLabels,
};
