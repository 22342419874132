import React, { useState, useEffect, useContext } from 'react';
import { format, parseISO, formatDistanceToNow, isFuture } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Grid, Box, Button, IconButton, Paper, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, MenuItem, Typography, Select, Switch, Divider, useMediaQuery, Breadcrumbs, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import { userProfileContext } from '../../lib/UserProvider';
import usersApi from '../../server/usersApi';
import { TASK_STATUS, RECURRENCE_RULE_OPTIONS, DATE_RANGES, TASK_TYPES, applyDateRangeFilter, calculateNextScheduledTime } from './helpers';

const EditableCell = ({ value, onValueChange, children, isStatus, isRecurrenceRule, options }) => {
  const [isEditing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleEdit = () => setEditing(true);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setEditValue(newValue);
    onValueChange(newValue);
    if (isStatus || isRecurrenceRule) {
      setEditing(false);
    }
  };

  const handleBlur = () => {
    onValueChange(editValue);
    setEditing(false);
  };

  return isEditing ? (
    isStatus ? (
      <Select
        value={editValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoWidth
      >
        {Object.values(options).map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    ) : isRecurrenceRule ? (
      <Select
        value={editValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoWidth
      >
        {Object.values(options).map((rule) => (
          <MenuItem key={rule} value={rule}>
            {rule}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        value={editValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
    )
  ) : (
    <Typography onClick={handleEdit} style={{ cursor: 'pointer', maxWidth: '200px', wordBreak: 'break-word' }}>
      {children || value}
    </Typography>
  );
};

const TasksListComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterState, setFilterState] = useState({
    status: '',
    title: '',
    type: '',
    pushToCMS: '',
    dateRange: {
      createdAt: 'all',
      updatedAt: 'all',
      lastRunAt: 'all',
      nextRunAt: 'all'
    },
    dialogOpen: false
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user_id: userProfileId } = useContext(userProfileContext) || {};

  const fetchTasks = async () => {
    if (userProfileId) {
      try {
        const data = await usersApi.fetchUserTasks(userProfileId);
        setTasks(data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
      } catch (error) {
        console.error("Failed to fetch tasks:", error);
      }
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [userProfileId]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (field, value) => {
    setFilterState(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDateRangeChange = (field, value) => {
    setFilterState(prev => ({
      ...prev,
      dateRange: {
        ...prev.dateRange,
        [field]: value
      }
    }));
  };

  const handleDialogOpen = () => {
    setFilterState(prev => ({
      ...prev,
      dialogOpen: true
    }));
  };

  const handleDialogClose = () => {
    setFilterState(prev => ({
      ...prev,
      dialogOpen: false
    }));
  };

  const handleFilterApply = () => {
    setFilterState(prev => ({
      ...prev,
      dialogOpen: false
    }));
  };

  const handleUpdateTask = async (taskId, field, newValue) => {
    try {
      const taskToUpdate = tasks.find(task => task.id === taskId);
      let updates = { [field]: newValue };

      // Handle scheduled_time updates
      if (field === 'scheduled_time') {
        const isScheduledTimeInFuture = isFuture(new Date(newValue));

        // Determine if the recurrence rule indicates a repeating or non-repeating task
        if (taskToUpdate.recurrence_rule === null || taskToUpdate.recurrence_rule === RECURRENCE_RULE_OPTIONS.NONE) {
          // For non-repeating tasks with a future scheduled time
          if (isScheduledTimeInFuture) {
            updates.next_run_at = newValue;
            updates.status = TASK_STATUS.PENDING;  // Scheduled but not repeated
          }
        } else {
          // For repeating tasks
          updates.next_run_at = newValue;
          updates.status = TASK_STATUS.TIMED;  // Scheduled and repeated
        }
      }

      // Adjust logic for updating task based on recurrence_rule changes
      if (field === 'recurrence_rule') {
        updates.recurring = newValue !== RECURRENCE_RULE_OPTIONS.NONE;

        if (newValue !== RECURRENCE_RULE_OPTIONS.NONE) {
          updates.status = TASK_STATUS.TIMED;
          // Set scheduled_time to now and calculate next_run_at based on recurrence_rule
          const now = new Date();
          updates.scheduled_time = now;
          updates.next_run_at = calculateNextScheduledTime(now, newValue);
        } else if (newValue === RECURRENCE_RULE_OPTIONS.NONE && taskToUpdate.status === TASK_STATUS.TIMED) {
          updates.status = TASK_STATUS.DISABLED;
          // Optionally, clear scheduled_time and next_run_at when disabling recurrence
          updates.scheduled_time = null;
          updates.next_run_at = null;
        }
      }

      // If the status is being changed to Disabled, clear Scheduled Start and Next Run At
      if (field === 'status' && newValue === TASK_STATUS.DISABLED) {
        updates.scheduled_time = null; // Clear Scheduled Start
        updates.next_run_at = null;  // Clear Next Run At
        updates.recurring = false;   // Turn off recurring
        updates.recurrence_rule = RECURRENCE_RULE_OPTIONS.NONE; // Remove recurrence rule
        updates.auto_publish = 'false'; // Remove auto publish
      }

      // Perform the update
      await usersApi.updateUserTask(userProfileId, taskId, updates);
      fetchTasks();
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await usersApi.deleteUserTask(userProfileId, taskId);
      fetchTasks(); // Refresh the task list
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
  };

  const handleAutoPublishStatusChange = async (taskId, newValue) => {
    let updatedValue;
    if (newValue === true) {
      updatedValue = 'true';
    } else {
      updatedValue = 'false';
    }

    try {
      await usersApi.updateUserTask(userProfileId, taskId, { auto_publish: updatedValue });
      fetchTasks();
    } catch (error) {
      console.error("Failed to update auto publish status:", error);
    }
  };

  const handleDuplicateTask = async (task) => {
    try {
      const duplicatedTask = {
        ...task,
        id: undefined, // Ensure a new ID is generated
        name: `${task.name} (Copy)`,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        progress: 0,
        last_run_successful: null,
        status: 'Disabled',
        last_run_at: null,
      };
  
      await usersApi.createTask(userProfileId, duplicatedTask);
      fetchTasks(); // Refresh the task list
    } catch (error) {
      console.error("Failed to duplicate task:", error);
    }
  };

  const filteredTasks = tasks.filter(task => {
    if (filterState.status && task.status !== filterState.status) return false;
    if (filterState.title && !task.name.toLowerCase().includes(filterState.title.toLowerCase())) return false;
    if (filterState.type && task.type !== filterState.type) return false;
    if (filterState.pushToCMS && String(task.auto_publish) !== filterState.pushToCMS) return false;

    if (filterState.dateRange.createdAt !== 'all' && !applyDateRangeFilter([task], 'created_at', filterState.dateRange.createdAt).length) return false;
    if (filterState.dateRange.updatedAt !== 'all' && !applyDateRangeFilter([task], 'updated_at', filterState.dateRange.updatedAt).length) return false;
    if (filterState.dateRange.lastRunAt !== 'all' && !applyDateRangeFilter([task], 'last_run_at', filterState.dateRange.lastRunAt).length) return false;
    if (filterState.dateRange.nextRunAt !== 'all' && !applyDateRangeFilter([task], 'next_run_at', filterState.dateRange.nextRunAt).length) return false;

    return true;
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/" color="inherit">
              Home
            </Link>
            <Typography color="textPrimary">{'Tasks'}</Typography>
          </Breadcrumbs>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>{'Tasks'}</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/create-task')}>
              Create New Task
            </Button>
          </Grid>
          <Typography variant="subtitle1" gutterBottom>
            This screen displays all the tasks you have set up. You can edit, manage, and delete your tasks from here.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#343542' }}>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>Title</TableCell>
                  <TableCell sx={{ color: 'white' }}>Type</TableCell>
                  <TableCell sx={{ color: 'white' }}>Status</TableCell>
                  <TableCell sx={{ color: 'white' }}>Created At</TableCell>
                  <TableCell sx={{ color: 'white' }}>Updated At</TableCell>
                  <TableCell sx={{ color: 'white' }}>Scheduled Start</TableCell>
                  <TableCell sx={{ color: 'white' }}>Repeat</TableCell>
                  <TableCell sx={{ color: 'white' }}>Last Run At</TableCell>
                  <TableCell sx={{ color: 'white' }}>Next Run At</TableCell>
                  <TableCell sx={{ color: 'white' }}>Push to CMS</TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      Actions
                      <IconButton onClick={handleDialogOpen} sx={{ color: 'white', padding: 0 }}>
                        <FilterListIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((task) => (
                  <TableRow key={task.id}><TableCell>
                    <EditableCell
                      value={task.name || task.id || 'Untitled Task'}
                      onValueChange={(newName) => handleUpdateTask(task.id, 'name', newName)}
                    >
                      <Typography sx={{ fontSize: '0.875rem' }}>
                        {task.name || task.id || 'Untitled Task'}
                      </Typography>
                    </EditableCell>
                  </TableCell>
                    <TableCell>
                      <EditableCell
                        value={task.type || '-'}
                        onValueChange={(newType) => handleUpdateTask(task.id, 'type', newType)}
                      >
                        <Typography sx={{ fontSize: '0.875rem' }}>
                          {task.type || '-'}
                        </Typography>
                      </EditableCell>
                    </TableCell>
                    <TableCell>
                      <EditableCell
                        value={task.status || TASK_STATUS.PENDING}
                        onValueChange={(newStatus) => handleUpdateTask(task.id, 'status', newStatus)}
                        isStatus={true}
                        options={TASK_STATUS}
                      >
                        <Typography sx={{ fontSize: '0.875rem' }}>
                          {task.status || TASK_STATUS.PENDING}
                        </Typography>
                      </EditableCell>
                    </TableCell>
                    <TableCell>{formatDistanceToNow(parseISO(task.created_at), { addSuffix: true })}</TableCell>
                    <TableCell>{format(parseISO(task.updated_at), 'dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>
                      <DateTimePicker
                        value={task.scheduled_time || null}
                        onChange={(newValue) => handleUpdateTask(task.id, 'scheduled_time', newValue)}
                        renderInput={(props) => <TextField {...props} />}
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={task.recurrence_rule || RECURRENCE_RULE_OPTIONS.NONE || null}
                        onChange={(e) => handleUpdateTask(task.id, 'recurrence_rule', e.target.value)}
                        autoWidth
                      >
                        {Object.values(RECURRENCE_RULE_OPTIONS).map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>{task.last_run_at ? format(parseISO(task.last_run_at), 'dd/MM/yyyy HH:mm') : '-'}</TableCell>
                    <TableCell>{task.next_run_at ? format(parseISO(task.next_run_at), 'dd/MM/yyyy HH:mm') : '-'}</TableCell>
                    <TableCell>
                      <Switch
                        checked={task.auto_publish === 'true'}
                        onChange={(e) => handleAutoPublishStatusChange(task.id, e.target.checked)}
                        inputProps={{ 'aria-label': 'auto publish' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton onClick={() => navigate(`/tasks/${task.id}`, { state: { task } })} sx={{ marginRight: 1 }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDuplicateTask(task)} sx={{ marginRight: 1 }}>
                          <FileCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteTask(task.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tasks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={filterState.dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Filter Tasks</DialogTitle>
        <DialogContent>
          <DialogContentText>Select criteria to filter the tasks.</DialogContentText>
          <Box mt={2}>
            <Typography variant="subtitle1" gutterBottom>General Filters</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  value={filterState.title}
                  onChange={(e) => handleFilterChange('title', e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Type"
                  value={filterState.type}
                  onChange={(e) => handleFilterChange('type', e.target.value)}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>All Types</em>
                  </MenuItem>
                  {TASK_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={filterState.pushToCMS}
                  onChange={(e) => handleFilterChange('pushToCMS', e.target.value)}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>All Options</em>
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={filterState.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>All Statuses</em>
                  </MenuItem>
                  {Object.values(TASK_STATUS).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom mt={2}>Date Range Filters</Typography>
            <Grid container spacing={2}>
              {['createdAt', 'updatedAt', 'lastRunAt', 'nextRunAt'].map((field) => (
                <Grid item xs={12} key={field}>
                  <Typography variant="subtitle2">{`Filter by ${field.replace(/([A-Z])/g, ' $1')}`}</Typography>
                  <Select
                    value={filterState.dateRange[field]}
                    onChange={(e) => handleDateRangeChange(field, e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                  >
                    {DATE_RANGES.map((range) => (
                      <MenuItem key={range.value} value={range.value}>
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFilterApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

    </LocalizationProvider>
  );
};

export default TasksListComponent;
