const apiURL = process.env.NODE_ENV === "development" ? 'http://localhost:8080/api' : '/api';
let auth0 = {
  REACT_APP_AUTH0_DOMAIN: "converse.eu.auth0.com"
};
if (process.env.NODE_ENV === "development") {
  auth0.REACT_APP_AUTH0_CLIENT_ID = "rKVcvjiKSFCQCVN1aNL6UB88d8worgmT";
} else if (process.env.NODE_ENV === "bertha") {
  auth0.REACT_APP_AUTH0_CLIENT_ID = "rKVcvjiKSFCQCVN1aNL6UB88d8worgmT";
} else if (process.env.NODE_ENV === "stable") {
  auth0.REACT_APP_AUTH0_CLIENT_ID = "rKVcvjiKSFCQCVN1aNL6UB88d8worgmT";
} else {
  auth0.REACT_APP_AUTH0_CLIENT_ID = "OkgKXyp9npd8BdLdiOBL4bm0Du7F9GBn";
}

export default { apiURL, auth0 };