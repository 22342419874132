export const temperatures = [
  { value: 0.1, label: "1" },
  { value: 0.2, label: "2" },
  { value: 0.3, label: "3" },
  { value: 0.4, label: "4" },
  { value: 0.5, label: "5" },
  { value: 0.6, label: "6" },
  { value: 0.7, label: "7" },
  { value: 0.8, label: "8" },
  { value: 0.9, label: "9" },
  { value: 1.0, label: "10" },
];