// React Libraries and Hooks
import React, { useRef, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI Components
import Autocomplete from '@mui/material/Autocomplete';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

// Third-Party Libraries
import toast from 'react-hot-toast';

// Helper Functions
import {
  validateFile,
  detectSeparator,
  parseCsvFile,
  convertArrayToCSV,
  createChunks,
  countAndSplitInput,
  formatDuration,
  calculateEstimatedProcessingTime,
  prepareDataWithCombineColumn,
  ColumnField,
  ColumnPreview,
  ApiResponse
} from './Helpers';

// API Modules
import completionsApi from '../../server/completionsApi';

// Local Definitions from 'definitions' file
import {
  languages, // Predefined set of language options
  lengths, // Predefined set of length units
  temperatures, // Predefined set of temperature units
  tones, // Predefined set of tones
  batchServiceTypes, // Predefined set of batch service types
  models, // Predefined set of AI models
  publications, // Predefined set of publication types
  empty, // Definition for an 'empty' state or selection
  booleans,
} from '../../definitions';

// Local Components
import AdvancedOptions from '../reusableComponent/AdvancedOptions';
import { userProfileContext } from "../../lib/UserProvider";

const BatchProcessorComponent = () => {
  const location = useLocation();
  const userProfile = useContext(userProfileContext);

  const [originalCsvData, setOriginalCsvData] = useState([]);
  const [detectedSeparator, setDetectedSeparator] = useState(null);
  // ui api response content
  const [apiPreview, setApiPreview] = useState([]);
  const [secondApiPreview, setSecondApiPreview] = useState([]);
  const [thirdApiPreview, setThirdApiPreview] = useState([]);

  // ui api request content
  const [columnPreview, setColumnPreview] = useState([]);
  const [secondColumnPreview, setSecondColumnPreview] = useState([]);
  const [thirdColumnPreview, setThirdColumnPreview] = useState([]);

  // subfields selection
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [selectedSecondColumn, setSelectedSecondColumn] = useState([]);
  const [selectedThirdColumn, setSelectedThirdColumn] = useState([]);

  // processed api response for downloadable output CSV generation
  const [apiResponse, setApiResponse] = useState(null);
  const [secondApiResponse, setSecondApiResponse] = useState(null);
  const [thirdApiResponse, setThirdApiResponse] = useState(null);

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progresses, setProgresses] = useState([]);

  const [input, setInput] = useState({
    useQueue: booleans[0].value,
    options: {
      max_length: lengths[3].value,
      messages: [],
      model: models[5].value,
      temperature: temperatures[5].value,
      user: userProfile?.user_id || null,
    },
    settings: {
      articleTone: tones[0].value,
      language: languages[0].value,
      serviceType: batchServiceTypes[5].value,
      secondServiceType: batchServiceTypes[5].value,
      thirdServiceType: batchServiceTypes[5].value,
      writingStyle: empty.value
    },
    user_id: userProfile?.user_id || null,
  });

  const [file, setFile] = useState(null);
  const [estimatedProcessingTime, setEstimatedProcessingTime] = useState(null);
  const [originalFileName, setOriginalFileName] = useState('');

  const fileInputRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const debug = queryParams.get('debug') === 'true';

  const clearFormData = (clearFileInput = true) => {
    setInput({
      useQueue: booleans[0].value,
      options: {
        max_length: lengths[0].value,
        messages: [],
        model: models[5].value,
        temperature: temperatures[5].value,
        user: userProfile?.user_id || null,
      },
      settings: {
        articleTone: tones[0].value,
        language: languages[0].value,
        serviceType: batchServiceTypes[5].value,
        secondServiceType: batchServiceTypes[5].value,
        thirdServiceType: batchServiceTypes[5].value,
        writingStyle: empty.value
      },
      user_id: userProfile?.user_id || null,
    })
    setOriginalCsvData([]);
    setDetectedSeparator(null);
    setApiResponse(null);
    setApiPreview([]);
    setColumns([]);
    setSelectedColumn([]);
    setSelectedSecondColumn([]);
    setSelectedThirdColumn([]);
    setColumnPreview([]);
    setSecondColumnPreview([]);
    setThirdColumnPreview([]);
    setLoading(false);
    setEstimatedProcessingTime(null);
    setOriginalFileName('');

    if (clearFileInput) {
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFileChange = (event) => {
    clearFormData(false);

    const selectedFile = event.target.files[0];
    const validationResult = validateFile(selectedFile);
    if (!validationResult.valid) {
      toast.error(validationResult.error);
      return;
    }

    const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, '');
    setOriginalFileName(fileNameWithoutExtension);
    setFile(selectedFile);
    detectSeparator(selectedFile, setDetectedSeparator, loadColumns);
  };

  const loadColumns = (file, separator) => {
    parseCsvFile(file, separator, true, (results) => {
      setColumns(Object.keys(results.data[0]));
      setOriginalCsvData(results.data);
    });
  };

  const processApiResponse = (response) => {
    const modifiedResults = response.batchResults.map((item) => item.replace(/\"/g, ''));
    return {
      ...response,
      batchResults: modifiedResults,
    };
  };

  const processApiPreview = (response) => {
    return response.batchResults.map((item, index) => `${index + 1}: ${item.replace(/\"/g, '')}`);
  };

  const checkTaskStatusWithInterval = async (taskId, timeoutInSeconds, intervalMilliseconds, columnWorkIndex) => {
    return new Promise((resolve, reject) => {
      let intervalId;
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        reject(new Error('Timeout while waiting for task completion'));
      }, timeoutInSeconds * 1000);

      intervalId = setInterval(async () => {
        const task = await completionsApi.checkTaskStatusInCompletionsQueue(taskId);
        if (task) {
          const taskStatus = task.message;

          setProgresses((prevProgresses) => {
            const newProgresses = JSON.parse(JSON.stringify(prevProgresses));
            newProgresses[columnWorkIndex] = task.progress;
            return newProgresses;
          });

          if (taskStatus === 'Completed' || taskStatus === 'Failed') {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            resolve(task);
          }
        }
      }, intervalMilliseconds);
    });
  };

  const parseCsvAndSendRequest = async (columnFields, columnServiceType, columnWorkIndex) => {
    return new Promise((resolve, reject) => {
      // Step 1: Parse the CSV file
      parseCsvFile(file, detectedSeparator, true, async (results) => {
        try {
          let batchResults = [];
          let batchSummary = {
            successCount: 0,
            failureCount: 0,
            totalCost: 0,
          };

          // Step 2: Sanitize and replace '|' in the data
          const sanitisedData = results.data.map(row => {
            const sanitisedRow = {};
            Object.keys(row).forEach(key => {
              sanitisedRow[key] = row[key].replace(/\|/g, ' '); // Replace '|' with space or another desired separator
            });
            return sanitisedRow;
          });

          // Step 3: Define maximum chunk size and half of it
          const MAX_CHUNK_SIZE = 51200; // Server limit 102400
          const HALF_MAX_CHUNK_SIZE = MAX_CHUNK_SIZE / 2;

          // Step 4: Extract data to process
          const dataToProcess = prepareDataWithCombineColumn(sanitisedData, columnFields);
          const dataChunks = createChunks(dataToProcess, '|', MAX_CHUNK_SIZE);
          const { count } = countAndSplitInput(dataToProcess, '|');

          // Check if there are multiple chunks
          const multipleChunks = dataChunks.length > 1;

          // Step 5: Process each chunk
          for (const chunk of dataChunks) {
            const requestBody = {
              ...input,
              options: {
                ...input.options,
                tool_choice: 'none'
              },
              settings: {
                ...input.settings,
                serviceType: columnServiceType,
              },
              batch: chunk,
            };

            // Step 6: Check if the chunk size is small enough for immediate processing and there's only one chunk
            if (chunk.length <= HALF_MAX_CHUNK_SIZE && !multipleChunks && !input.useQueue) {
              const response = await completionsApi.sendToBatchChatCompletionsAPI(requestBody);
              if (response.status === 200 && response.batchResults && Array.isArray(response.batchResults)) {
                response.batchResults.forEach((item) => {
                  if (item) {
                    batchResults.push(item.message);
                  }
                });

                // Update batchSummary
                batchSummary.totalCost = response.totalCost;
                batchSummary.successCount = response.successCount;
                batchSummary.failureCount = response.failureCount;

                // Step 5A: If batch processing completes for all chunks, resolve the promise
                if (count === batchResults.length) {
                  resolve({ status: 200, batchResults, batchSummary });
                }
              } else {
                throw new Error(`API Error: ${response?.response?.data?.message ? response.response.data.message : response.message}`);
              }
            } else {
              // Step 7: If the chunk is large, enqueue it for processing
              const response = await completionsApi.enqueueMessageToCompletionsAPI(requestBody);
              if (response?.status === 202 && response?.id) {
                const task = await checkTaskStatusWithInterval(
                  response.id,
                  3600, // Timeout after 3600 seconds (1 hour)
                  8000, // Check every 8 seconds,
                  columnWorkIndex
                );

                if (task) {
                  const fetchedResults = JSON.parse(task.output);
                  const fetchedResultsResponse = fetchedResults.batchResults;
                  fetchedResultsResponse.forEach((item) => {
                    if (item) {
                      batchResults.push(item.message);
                    }
                  });

                  // Update batchSummary
                  batchSummary.totalCost += fetchedResults.totalCost;
                  batchSummary.successCount += fetchedResults.successCount;
                  batchSummary.failureCount += fetchedResults.failureCount;

                  // Step 6A: If batch processing completes for all chunks, resolve the promise
                  if (count === batchResults.length) {
                    resolve({ status: 200, batchResults, batchSummary });
                  }
                }

                // Step 8: Set a timeout for 2 hours
                setTimeout(() => {
                  // Step 9: Reject the promise if it times out
                  reject(new Error('Timeout while waiting for task completion'));
                }, 7200000); // 2 hour in milliseconds
              } else {
                throw new Error(`API Error: ${response?.response?.data?.message ? response.response.data.message : response.message}`);
              }
            }
          }

          // Step 9: Set a 2-hour timeout after resolving the promise
          setTimeout(() => {
            reject(new Error('API Error: Timeout during batch processing'));
          }, 7200000);
        } catch (apiError) {
          reject(apiError);
        }
      });
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setApiPreview([]);
    setLoading(true);

    const estimatedTime = calculateEstimatedProcessingTime(originalCsvData, selectedColumn, selectedSecondColumn, selectedThirdColumn, input.options.model);
    setEstimatedProcessingTime(estimatedTime);

    try {
      if (!file) {
        throw new Error('Please upload a file.');
      }

      if (!selectedColumn || selectedColumn.length === 0) {
        throw new Error('Please select a column.');
      }

      let requestInput = [
        {
          columnFields: selectedColumn,
          columnServiceType: input.settings.serviceType,
        },
      ];
      if (selectedSecondColumn?.length > 0) {
        requestInput.push({
          columnFields: selectedSecondColumn,
          columnServiceType: input.settings.secondServiceType,
        });
      }
      if (selectedThirdColumn?.length > 0) {
        requestInput.push({
          columnFields: selectedThirdColumn,
          columnServiceType: input.settings.thirdServiceType,
        });
      }

      //set progresses to zeroes for all parallel batch requests
      const initialProgresses = Array(requestInput.length).fill(0);
      setProgresses(initialProgresses);

      const responsesPromises = requestInput.map((input, columnWorkIndex) => parseCsvAndSendRequest(input.columnFields, input.columnServiceType, columnWorkIndex));
      const responses = await Promise.all(responsesPromises);
      if (responses.every((r) => r.status === 200)) {
        setApiResponse(processApiResponse(responses[0]));
        setApiPreview(processApiPreview(responses[0]).slice(0, 10));
        if (responses.length > 1) {
          setSecondApiResponse(processApiResponse(responses[1]));
          setThirdApiResponse(processApiResponse(responses[1]));
          setSecondApiPreview(processApiPreview(responses[1]).slice(0, 10));
          setThirdApiPreview(processApiPreview(responses[1]).slice(0, 10));
        } else {
          setSecondApiResponse(null);
          setThirdApiResponse(null);
          setSecondApiPreview([]);
          setThirdApiPreview([]);
        }

        const totalCount = responses.map((response) => response?.batchSummary?.successCount || 0).reduce((a, b) => a + b, 0);
        const failureCount = responses.map((response) => response?.batchSummary?.failureCount || 0).reduce((a, b) => a + b, 0);
        const totalCost = responses.map((response) => response?.batchSummary?.totalCost || 0).reduce((a, b) => a + b, 0);

        let successMessage = `Data processed successfully! Items successfully processed: ${totalCount || '0'}, with ${failureCount || '0'} failures`;
        if (debug) {
          const formattedTotalCost = `$${totalCost.toFixed(2)}`;
          successMessage += ` Total cost: ${formattedTotalCost}.`;
          console.log('Data processed successfully!', formattedTotalCost);
        }

        toast.success(successMessage, { duration: 10000 });
      } else {
        const message = responses
          .filter((r) => r.status !== 200)
          .map((r) => r.message)
          .join(', ');
        toast.error(`API Error: ${message}`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setEstimatedProcessingTime(null);
    }
  };

  const handleColumnChange = (event, newValue) => {
    setSelectedColumn(newValue);
    updateColumnPreview(newValue, setColumnPreview);
  };

  const handleSecondColumnChange = (event, newValue) => {
    setSelectedSecondColumn(newValue);
    updateColumnPreview(newValue, setSecondColumnPreview);
  };
  const handleThirdColumnChange = (event, newValue) => {
    setSelectedThirdColumn(newValue);
    updateColumnPreview(newValue, setThirdColumnPreview);
  };

  const updateColumnPreview = (columns, previewSetter) => {
    if (!file || columns.length === 0) {
      previewSetter([]);
      return;
    }

    parseCsvFile(file, detectedSeparator, true, (results) => {
      const previewData = results.data
        .map((row, index) => {
          // Combine texts from the selected columns for this preview
          const combinedText = columns.map((column) => row[column] || '').join(', ');
          return `${index + 1}: ${combinedText}`;
        })
        .filter(Boolean) // Remove empty lines
        .slice(0, 10); // Limit preview to first 10 items

      previewSetter(previewData);
    });
  };

  const downloadResults = () => {
    if (!apiResponse || !apiResponse.batchResults) {
      console.error('API response is invalid');
      return;
    }

    const modifiedColumnName = `${selectedColumn} Modified`.replace(',', '-');
    const modifiedColumnName2 = `${selectedSecondColumn} Modified`.replace(',', '-');
    const modifiedColumnName3 = `${selectedThirdColumn} Modified`.replace(',', '-');

    const modifiedData = originalCsvData.map((row, index) => {
      // Assuming each item in batchResults corresponds to each row in the CSV
      const apiResult = apiResponse.batchResults[index] || '';
      const apiResult2 = secondApiResponse?.batchResults[index] || '';
      const apiResult3 = thirdApiResponse?.batchResults[index] || '';
      let newRow = {
        ...row,
        [modifiedColumnName]: apiResult,
      };
      if (apiResult2)
        newRow[modifiedColumnName2] = apiResult2;
      if (apiResult3)
        newRow[modifiedColumnName3] = apiResult3;
      return newRow
    });

    const newCsvString = convertArrayToCSV(modifiedData);
    const blob = new Blob([newCsvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const modifiedFileName = `${originalFileName}_modified.csv`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', modifiedFileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Container maxWidth='xl' style={{ height: '100%' }}>
      <Typography variant='h4' gutterBottom>
        Batch Processing Tool
      </Typography>
      <Typography variant='body1' gutterBottom>
        Upload a CSV or document, choose your options, and process the data in batch.
      </Typography>

      <Box
        component='form'
        onSubmit={handleSubmit}
        noValidate
        sx={{
          width: '100%',
          height: '75%',
          mt: 3,
          maxWidth: {
            lg: '50%',
            xl: '50%',
          },
        }}
      >
        <Tooltip title='Upload CSV File'>
          <input type='file' onChange={handleFileChange} ref={fileInputRef} style={{ display: 'block', marginBottom: '20px' }} />
        </Tooltip>

        <ColumnField
          selectColumn={selectedColumn}
          handleColumn={handleColumnChange}
          columnNumber='first'
          columns={columns}
        />
        <ColumnField
          selectColumn={selectedSecondColumn}
          handleColumn={handleSecondColumnChange}
          columnNumber='second'
          columns={columns}
        />
        <ColumnField
          selectColumn={selectedThirdColumn}
          handleColumn={handleThirdColumnChange}
          columnNumber='third'
          columns={columns}
        />

        <AdvancedOptions serviceMode={"batch-processor"} input={input} setInput={setInput} />

        <Button variant='contained' color='secondary' onClick={clearFormData} sx={{ mt: 3, mb: 2, mr: 2 }}>
          Clear
        </Button>
        <Button type='submit' variant='contained' color='primary' sx={{ mt: 3, mb: 2 }}>
          Upload and Process
        </Button>

        {loading && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
            <br />
            {/* calculation below average the multiple progress indicators */}
            <LinearProgress variant="determinate" value={progresses.reduce((a, b) => a + b, 0) / progresses.length} />
          </>
        )}

        {estimatedProcessingTime !== null && (
          <div style={{ textAlign: 'center', marginTop: '16px' }}>Estimated Processing Time: {formatDuration(estimatedProcessingTime)}</div>
        )}
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <ColumnPreview columnPreview={columnPreview} columnNumber="First" />
          <ColumnPreview columnPreview={secondColumnPreview} columnNumber="Second" />
          <ColumnPreview columnPreview={thirdColumnPreview} columnNumber="Third" />
        </Grid>
        <Grid item xs={12} md={6}>
          <>
            <ApiResponse apiPreview={apiPreview} columnNumber="First" />
            <ApiResponse apiPreview={secondApiPreview} columnNumber="Second" />
            <ApiResponse apiPreview={thirdApiPreview} columnNumber="Third" />

            {apiResponse && (
              <Button variant='contained' color='primary' onClick={downloadResults} sx={{ mt: 1 }}>
                Download
              </Button>
            )}
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BatchProcessorComponent;
