import api from '../api';

const postTemplate = (groups, type, sport, variables, text, template_name) => {
  return api.post(`/templates/MohamedJama/template`, {
    groups,
    template_type: type,
    sport,
    facts: variables,
    text: text,
    template_name: template_name,
  });
};

export { postTemplate };
