import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import userData from "../server/userData";

// Create a new context for the user's profile information
export const userProfileContext = React.createContext();

export function UserProvider({ children }) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userProfile, setUserProfile] = useState({}); // Store the user profile in the context

  useEffect(() => {
    //get token from auth0
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("login-token", token);
      } catch (error) {
        console.log(error.message);
      }
      
    };
    // Fetch the UUID here if needed
    if (isAuthenticated) {   

      // Fetch and set the UUID based on your logic
      const fetchedUuid = async () => {
        try {
          const data = await userData.createOrRetrieveUser(
            user.name,
            user.email
          );
          if (data) {
            setUserProfile(data.user);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getToken();
      fetchedUuid();
    }
  }, [isAuthenticated]);

  // Pass the extracted user information through the custom context provider
  return (
    <userProfileContext.Provider value={userProfile}>
      {children}
    </userProfileContext.Provider>
  );
}

// Create a custom hook to access the user context
export function useUserContext() {
  return useContext(userProfileContext);
}
