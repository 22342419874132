import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Box, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import GoogleIcon from '@mui/icons-material/Google';
import LanguageIcon from '@mui/icons-material/Language';
import ImageIcon from '@mui/icons-material/Image';
import WeatherIcon from '@mui/icons-material/WbSunny';

const tools = [
  { name: 'Standard Chat', icon: <ChatIcon />, value: 'standardChat', function: null },
  { name: 'Google Search', icon: <GoogleIcon />, value: 'googleSearch', function: { name: 'googleSearch' } },
  { name: 'Web Crawling', icon: <LanguageIcon />, value: 'crawlAndExtractTextFromWebsite', function: { name: 'crawlAndExtractTextFromWebsite' } },
  { name: 'Image Generation', icon: <ImageIcon />, value: 'generateImageResponse', function: { name: 'generateImageResponse' } },
  { name: 'Weather Forecast', icon: <WeatherIcon />, value: 'generateWeatherForecastMessage', function: { name: 'generateWeatherForecastMessage' } } // New tool added here
];

const AiChatTools = ({ selectedTool, onSelectTool }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToolsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolsClose = () => {
    setAnchorEl(null);
  };

  const handleToolSelect = (tool) => {
    onSelectTool(tool);
    setAnchorEl(null);
  };

  const getSelectedIcon = () => {
    const tool = tools.find(t => t.value === selectedTool?.value);
    return tool ? tool.icon : <ChatIcon />;
  };

  return (
    <>
      <Tooltip title="Tools">
        <IconButton
          component="span"
          color="inherit"
          onClick={handleToolsClick}
        >
          {getSelectedIcon()}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleToolsClose}
      >
        {tools.map((tool) => (
          <MenuItem key={tool.value} onClick={() => handleToolSelect(tool)}>
            {tool.icon}
            <Box ml={1}>{tool.name}</Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AiChatTools;
