export const lengths = [
  {
    value: "2500",
    label: "Short (less than 500 words)",
  },
  {
    value: "5000",
    label: "Medium (500-1000 words)",
  },
  {
    value: "10000",
    label: "Large (more than 1000 words)",
  },
  {
    value: "none",
    label: "No Limit",
  },
];
