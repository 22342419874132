import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Navbar from "../navbar/navbar.js";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import UserTable from "./userSettingTable.js";
import fetchUserData from "../../server/userData";
import { AddNewUser } from "./popupUsetting";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "./userSetting.scss";

const UserSetting = (props) => {
  const userAction = [
    { value: "Change Publisher", label: "Change Publisher" },
    { value: "Change Name", label: "Change Name" },
    { value: "Change Email", label: "Change Email" },
    { value: "Change Role", label: "Change Role" },
    { value: "Change Articles", label: "Change Articles" },
    { value: "Change Tokens", label: "Change Tokens" },
  ];
  const [users, setUserData] = useState([]);
  const [useraction, setUserAction] = useState("");

  const [userActionPopupOpen, setUserActionPopupOpen] = useState(false);
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false);
  const [searchName, setSelectedSearch] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [error, setError] = useState(false);

  // Create new user functionality
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userPublisher, setUserPublisher] = useState("");
  const [userArticles, setUserArticles] = useState(0);
  const [userTokenUsed, setUserTokenUsed] = useState(0);

  const handleChangeUsername = (e) => {
    setUserName(e.target.value);
  };
  const handleChangeUserEmail = (e) => {
    setUserEmail(e.target.value);
  };
  const handleChangeUserRole = (event, value) => {
    setUserRole(value.value);
  };
  const handleChangeUserPublisher = (event, value) => {
    setUserPublisher(value.value);
  };
  const handleChangeUserArticles = (e) => {
    setUserArticles(e.target.value);
  };
  const handleChangeUsernamTokenused = (e) => {
    setUserTokenUsed(e.target.value);
  };
  const createUser = async () => {
    try {
      const data = await fetchUserData.createOrRetrieveUser(
        userName,
        userEmail
      );
      setAlertMessage("User added successfully");
      setSuccessAlert(true);
      handleCloseAddUserPopup();
      fetchUserList();
    } catch (error) {
      console.error(error);
      setAlertMessage(error);
      setErrorsAlert(true);
    }
  };
  const handleCloseSuccessAlert = () => {
    setSuccessAlert(false);
  };
  const handleCloseErrorAlert = () => {
    setErrorsAlert(false);
  };
  const handleInputChangeSearch = (e) => {
    setSelectedSearch(e.target.textContent);
  };

  const handleClickUserAction = () => {
    setUserActionPopupOpen(true);
  };
  const handleCloseUserPopup = () => {
    setUserActionPopupOpen(false);
  };
  const handleClickAddNewUser = () => {
    setAddUserPopupOpen(true);
  };

  const handleCloseAddUserPopup = () => {
    setAddUserPopupOpen(false);
  };
  const handleInputChangeRole = (event, value) => {
    setUserAction(value.value);
  };
  const fetchUserList = async () => {
    try {
      const data = await fetchUserData.fetchUserData();
      setUserData(data);
    } catch (error) {
      setError(true);
      console.log(error);
      console.error(error);
    }
  };
  const searchUserByName = async () => {
    try {
      const data = await fetchUserData.searchUserByName(searchName);
      setUserData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteUsers = async (body) => {
    try {
      const data = await fetchUserData.deleteUsers(body);
      setAlertMessage("User deleted successfully");
      setSuccessAlert(true);
      fetchUserList();
    } catch (error) {
      console.error(error);
      setAlertMessage(error);
      setErrorsAlert(true);
    }
  };
  const editUsers = async (body) => {
    try {
      const data = await fetchUserData.editUsers(body);
      setAlertMessage("User role and publisher updated successfully");
      setSuccessAlert(true);
      fetchUserList();
    } catch (error) {
      console.error(error);
      setAlertMessage(error);
      setErrorsAlert(true);
    }
  };
  const updateSingleUser = async (
    user_id,
    userName,
    userEmail,
    userRole,
    userPublisher,
    userArticles,
    userTokenUsed
  ) => {
    try {
      const data = await fetchUserData.updateSingleUserData(
        user_id,
        userName,
        userEmail,
        userRole,
        userPublisher,
        userArticles,
        userTokenUsed
      );
      setAlertMessage("User updated successfully");
      setSuccessAlert(true);
      handleCloseUserPopup();
      fetchUserList();
    } catch (error) {
      console.error(error);
      setAlertMessage(error);
      setErrorsAlert(true);
    }
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  return (
    <div>
      <Navbar />
      <div className="settingpage">
        {error ? (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <h3>You Don’t Have Permission to Access on This Page.</h3>
            </Grid>
          </div>
        ) : (
          <div>
            {" "}
            <Snackbar
              open={successAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleCloseSuccessAlert}
              data-testid="save-template-alert"
            >
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {alertMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={errorAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleCloseErrorAlert}
            >
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {alertMessage}
              </Alert>
            </Snackbar>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h2 className="title">User Management</h2>
            </Grid>
            <div className="countdetails">
              <span style={{ marginRight: "5px" }}>All({users.length})</span>
              <span style={{ marginRight: "5px" }}>
                Admin(
                {users ? users.filter((r) => r.role === "Admin").length : ""})
              </span>
              <span style={{ marginRight: "5px" }}>
                Super Admin(
                {users
                  ? users.filter((r) => r.role === "superAdmin").length
                  : ""}
                )
              </span>
              <span>
                User(
                {users ? users.filter((r) => r.role === "user").length : ""})
              </span>
            </div>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={3}>
                <div>
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    options={userAction}
                    onChange={handleInputChangeRole}
                    renderInput={(params) => (
                      <TextField {...params} label="Change role to" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item sm={1}>
                <Button
                  onClick={handleClickUserAction}
                  size="small"
                  variant="contained"
                  sx={{ margin: "5px" }}
                >
                  Change
                </Button>
              </Grid>
              <Grid item sm={4}>
                <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  disableClearable
                  options={users.map((option) => option.name)}
                  onChange={handleInputChangeSearch}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search User"
                      id="outlined-size-small"
                      className="outlined-size-small"
                      sx={{ zIndex: "0" }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={2}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={searchUserByName}
                  sx={{ margin: "5px" }}
                >
                  Search User
                </Button>
              </Grid>
              <Grid item sm={2}>
                <div>
                  <Button
                    onClick={handleClickAddNewUser}
                    size="small"
                    variant="contained"
                    sx={{ margin: "5px" }}
                  >
                    Add New User
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item sm={12}>
                <UserTable
                  rows={users}
                  deleteUsers={deleteUsers}
                  editUsers={editUsers}
                  userActionPopupOpen={userActionPopupOpen}
                  handleCloseUserPopup={handleCloseUserPopup}
                  userName={userName}
                  userEmail={userEmail}
                  userRole={userRole}
                  userPublisher={userPublisher}
                  userArticles={userArticles}
                  userTokenUsed={userTokenUsed}
                  handleChangeUsername={handleChangeUsername}
                  handleChangeUserEmail={handleChangeUserEmail}
                  handleChangeUserRole={handleChangeUserRole}
                  handleChangeUserPublisher={handleChangeUserPublisher}
                  handleChangeUserArticles={handleChangeUserArticles}
                  handleChangeUsernamTokenused={handleChangeUsernamTokenused}
                  updateSingleUser={updateSingleUser}
                />
              </Grid>
              <AddNewUser
                handleCloseAddUserPopup={handleCloseAddUserPopup}
                addUserPopupOpen={addUserPopupOpen}
                handleChangeUsername={handleChangeUsername}
                handleChangeUserEmail={handleChangeUserEmail}
                handleChangeUserRole={handleChangeUserRole}
                handleChangeUserPublisher={handleChangeUserPublisher}
                createUser={createUser}
              />
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSetting;
