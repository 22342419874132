import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import "./styles/storyboard.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DroppedFacts from "./storyBoardFactDisplay";
import InputAdornment from "@mui/material/InputAdornment";


const Storyboard = (props) => {
  // props from parent component board.js
  const {
    storyBoardFacts,
    moveStoryBoardFacts,
    addToSubsetionCount,
    subsectionCount,
    removeSubsetionCount,
  } = props;

  return (
    <div className="storyboard">
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container alignItems="center"  justifyContent="space-between">
            <Grid item sm={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                
                  <h3>Story Board</h3>
                  <div>
                  <IconButton aria-label="add" onClick={addToSubsetionCount}>
                    <AddCircleIcon color="primary" />
                  </IconButton>

                  <Button size="small" variant="contained">
                    First Draft
                  </Button>
                </div>
              </Grid>
              <Grid
                container
                spacing={2}
                className="displayfacts"
              >
                {[...Array(subsectionCount)].map((i) => (
                  <Grid item={i} sm={2.4}>
                    <div className="subsectionBox">
                      <TextField
                        label="Subsection Header"
                        id="outlined-size-small"
                        defaultValue=""
                        className="outlined-size-small"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <CancelIcon
                                sx={{ cursor: "pointer" }}
                                color="primary"
                                onClick={removeSubsetionCount}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="below-section">
                        <DroppedFacts
                          moveStoryBoardFacts={moveStoryBoardFacts}
                          storyBoardFacts={storyBoardFacts}
                        />
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Storyboard;
