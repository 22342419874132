import api from '../api';


const sendMessageToWriterAPI = async (body) => {
  try {
    const url = `/writer`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchArticleByUser = async (user_id) => {
  try {
    const url = `/articles/user/${user_id}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};
const fetchArticleById = async (article_id) => {
  try {
    const url = `/articles/article/${article_id}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) { }
};
export default { sendMessageToWriterAPI, fetchArticleByUser, fetchArticleById };
