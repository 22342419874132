// React and React Router
import React, { useEffect } from "react";

// Material UI Components
import {
  Select, MenuItem, FormControl, InputLabel, Box, Typography, Tooltip, Container, Grid, Button, TextField, Autocomplete, TextareaAutosize
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import ReplayIcon from "@mui/icons-material/Replay";

// Local Data Imports
import { publications, articleTypes, publicationStatusOptions } from "../../definitions";

const PushToCueSelection = (props) => {
  const {
    prepareData,
    input,
    setInput,
    loadingTitle,
    setLoadingTitle,
    loadingMetaDescription,
    setLoadingMetaDescription,
    regenerateContent,
    loadingSocialHeadline,
    setLoadingSocialHeadline,
    loadingNewsLettersAndReferrersHeadline,
    setLoadingNewsLettersAndReferrersHeadline,
    loadingLeadText,
    setLoadingLeadText,
    sendingToCMS,
  } = props;

  const { title, metaTitle, newsLettersAndReferrersHeadline, leadText, metaDescription } = input.articleData;

  useEffect(() => {
    if (!loadingTitle && title?.trim() === "") {
      regenerateContent("title", setLoadingTitle, (value) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          title: value
        }
      })));
    }
    if (!loadingSocialHeadline && metaTitle?.trim() === "") {
      regenerateContent("metaTitle", setLoadingSocialHeadline, (value) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          metaTitle: value
        }
      })));
    }
    if (!loadingNewsLettersAndReferrersHeadline && newsLettersAndReferrersHeadline?.trim() === "") {
      regenerateContent("newsLettersAndReferrersHeadline", setLoadingNewsLettersAndReferrersHeadline, (value) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          newsLettersAndReferrersHeadline: value
        }
      })));
    }
    if (!loadingLeadText && leadText?.trim() === "") {
      regenerateContent("leadText", setLoadingLeadText, (value) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          leadText: value
        }
      })));
    }
    if (!loadingMetaDescription && metaDescription?.trim() === "") {
      regenerateContent("metaDescription", setLoadingMetaDescription, (value) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          metaDescription: value
        }
      })));
    }
  }, [title, metaTitle, newsLettersAndReferrersHeadline, leadText, metaDescription]);

  return (
    <Container maxWidth="xl" className="left-section" style={{ marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>Push to CMS</Typography>
      <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
        Push the AI-generated content to the selected CMS. Ensure that all content parameters are correctly set before pushing to avoid any issues.
      </Typography>

      <Box className="filter-section" sx={{ mb: 2 }}>
        <Typography sx={{ mb: 2 }}>Publications Settings</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              id="publication-autocomplete"
              size="small"
              options={publications}
              getOptionLabel={(option) => option.label}
              value={publications.find(option => option.value === input?.cmsData?.cms) || null}
              onChange={(event, newValue) => setInput(prevState => ({
                ...prevState,
                cmsData: {
                  ...prevState.cmsData,
                  cms: newValue ? newValue.value : ''
                }
              }))}
              renderInput={(params) => (
                <TextField {...params} label="CMS" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="subtype-autocomplete"
              size="small"
              options={articleTypes}
              getOptionLabel={(option) => option.label}
              value={articleTypes.find(type => type.value === input?.cmsData?.cmsContentType) || null}
              onChange={(event, newValue) => setInput(prevState => ({
                ...prevState,
                cmsData: {
                  ...prevState.cmsData,
                  cmsContentType: newValue ? newValue.value : ''
                }
              }))}
              renderInput={(params) => (
                <TextField {...params} label="Article Type" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="status-autocomplete"
              size="small"
              options={publicationStatusOptions}
              getOptionLabel={(option) => option.label}
              value={publicationStatusOptions.find(status => status.value === input?.cmsData?.cmsPublicationStatus) || null}
              onChange={(event, newValue) => setInput(prevState => ({
                ...prevState,
                cmsData: {
                  ...prevState.cmsData,
                  cmsPublicationStatus: newValue ? newValue.value : ''
                }
              }))}
              renderInput={(params) => (
                <TextField {...params} label="Publication Status" />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography sx={{ mb: 2 }} gutterBottom>Article Content</Typography>

      <Box className="select-box" sx={{ mb: 2 }}>
        <TextField
          label="Title/Headline"
          id="outlined-size-small"
          value={title}
          onChange={(e) => setInput(prevState => ({
            ...prevState,
            articleData: {
              ...prevState.articleData,
              title: e.target.value
            }
          }))}
          multiline
          rows={2}
          fullWidth
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
          <LoadingButton
            size="small"
            endIcon={<ReplayIcon />}
            loading={loadingTitle}
            loadingPosition="end"
            variant="contained"
            onClick={(e) => {
              setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  title: ""
                }
              }));
              regenerateContent("title", setLoadingTitle, (value) => setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  title: value
                }
              })));
            }}
          >
            Regenerate
          </LoadingButton>
        </Grid>
      </Box>

      <Box className="select-box" sx={{ mb: 2 }}>
        <TextField
          label="Lead Text"
          value={leadText}
          onChange={(e) => setInput(prevState => ({
            ...prevState,
            articleData: {
              ...prevState.articleData,
              leadText: e.target.value
            }
          }))}
          multiline
          rows={4}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
          <LoadingButton
            size="small"
            endIcon={<ReplayIcon />}
            loading={loadingLeadText}
            loadingPosition="end"
            variant="contained"
            onClick={(e) => {
              setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  leadText: ""
                }
              }));
              regenerateContent("leadText", setLoadingLeadText, (value) => setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  leadText: value
                }
              })));
            }}
          >
            Regenerate
          </LoadingButton>
        </Grid>
      </Box>

      <Box className="select-box" sx={{ mb: 2 }}>
        <TextField
          label="Meta Title/Social Headline"
          value={metaTitle}
          onChange={(e) => setInput(prevState => ({
            ...prevState,
            articleData: {
              ...prevState.articleData,
              metaTitle: e.target.value
            }
          }))}
          multiline
          rows={2}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
          <LoadingButton
            size="small"
            endIcon={<ReplayIcon />}
            loading={loadingSocialHeadline}
            loadingPosition="end"
            variant="contained"
            onClick={(e) => {
              setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  metaTitle: ""
                }
              }));
              regenerateContent("metaTitle", setLoadingSocialHeadline, (value) => setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  metaTitle: value
                }
              })));
            }}
          >
            Regenerate
          </LoadingButton>
        </Grid>
      </Box>

      <Box className="select-box" sx={{ mb: 2 }}>
        <TextField
          label="Meta Description"
          value={metaDescription}
          onChange={(e) => setInput(prevState => ({
            ...prevState,
            articleData: {
              ...prevState.articleData,
              metaDescription: e.target.value
            }
          }))}
          multiline
          rows={4}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
          <LoadingButton
            size="small"
            endIcon={<ReplayIcon />}
            loading={loadingMetaDescription}
            loadingPosition="end"
            variant="contained"
            onClick={(e) => {
              setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  metaDescription: ""
                }
              }));
              regenerateContent("metaDescription", setLoadingMetaDescription, (value) => setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  metaDescription: value
                }
              })));
            }}
          >
            Regenerate
          </LoadingButton>
        </Grid>
      </Box>

      <Box className="select-box" sx={{ mb: 2 }}>
        <TextField
          label="Newsletters And Referrers Headline"
          value={newsLettersAndReferrersHeadline}
          onChange={(e) => setInput(prevState => ({
            ...prevState,
            articleData: {
              ...prevState.articleData,
              newsLettersAndReferrersHeadline: e.target.value
            }
          }))}
          multiline
          rows={2}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
          <LoadingButton
            size="small"
            endIcon={<ReplayIcon />}
            loading={loadingNewsLettersAndReferrersHeadline}
            loadingPosition="end"
            variant="contained"
            onClick={(e) => {
              setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  newsLettersAndReferrersHeadline: ""
                }
              }));
              regenerateContent("newsLettersAndReferrersHeadline", setLoadingNewsLettersAndReferrersHeadline, (value) => setInput(prevState => ({
                ...prevState,
                articleData: {
                  ...prevState.articleData,
                  newsLettersAndReferrersHeadline: value
                }
              })));
            }}
          >
            Regenerate
          </LoadingButton>
        </Grid>
      </Box>

      <LoadingButton
        size="small"
        endIcon={<SendIcon />}
        loading={sendingToCMS}
        loadingPosition="end"
        variant="contained"
        onClick={prepareData}
      >
        Push Article to CMS
      </LoadingButton>
    </Container>
  );
};

export default PushToCueSelection;