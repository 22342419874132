import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import scribeLogo from "../../assets/mantis_scribe_logo.png";
import "./navbar.scss";
import { userProfileContext } from "../../lib/UserProvider";

const LogoImage = styled("img")({
  cursor: "pointer",
});

const Navbar = (props) => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [accessLevel, setAccessLevel] = useState("");
  const [userId, setUserId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const userProfile = useContext(userProfileContext);
  const userProfileRole = userProfile?.role ?? null;
  const userProfileId = userProfile?.user_id ?? null;

  useEffect(() => {
    const handleResize = () => setIsDesktopView(window.innerWidth > 768);

    if (isAuthenticated && userProfileId && userProfileRole) {
      setAccessLevel(userProfileRole);
      setUserId(userProfileId);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isAuthenticated, userProfileId, userProfileRole]);

  const handleClick = () => {
    navigate("/");
    window.location.reload();
  };

  const handleClickSetting = () => {
    navigate("/settings");
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: { returnTo: window.location.origin }
    });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#343542 !important" }}>
      <Toolbar sx={{
        justifyContent: "space-between", minHeight: "83px", maxHeight: "83px", "& .MuiToolbar-gutters": {
          paddingLeft: "24px",
          paddingRight: "24px",
          "@media (max-width:768px)": {
            paddingLeft: "9px",
            paddingRight: "9px",
          },
        }
      }}>
        {isDesktopView ? (
          <>
            <LogoImage
              data-testid="scribe-logo"
              src={scribeLogo}
              alt=""
              onClick={handleClick}
              sx={{ cursor: "pointer", width: "200px", "@media (max-width:900px)": { width: "150px" } }}
            />
            <div className="navlinks">
              {isAuthenticated ? (
                <>
                  {[
                    { to: '/ai-writer', text: 'AI Writer', dataTestid: "ai-writer-page" },
                    { to: '/feeds', text: 'Feeds', dataTestid: "feeds" },
                    { to: '/ai-chat', text: 'AI Chat', dataTestid: "ai-writer-page" },
                  ].map((item, index) => (
                    <Link
                      key={index}
                      to={item.to}
                      data-testid={item.dataTestid}
                      state={userId}
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        fontSize: '22px',
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        lineHeight: '21px',
                        borderLeft: index > 0 ? '2px solid white' : 'none',
                        '@media (max-width:1532px)': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      {item.text}
                    </Link>
                  ))}
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center", // Align items vertically in the center
              }}
            >
              <div
                style={{
                  backgroundColor: "#24ADE4",
                  border: "none",
                  borderRadius: "44px",
                  padding: "8px 12px",
                  fontSize: "16px",
                  fontFamily: "Poppins Bold",
                  color: "white",
                  fontWeight: "600",
                  marginRight: "8px", // Add some margin to separate the elements
                  "@media (max-width:768px)": {
                    display: "none",
                  },
                }}
              >
                Your Ultimate Writing Assistant
              </div>
              {isAuthenticated ? (
                <img
                  onClick={handleMenu}
                  src={user.picture}
                  alt="Profile"
                  style={{
                    fontSize: "2.8rem",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    fill: "#595959",
                    width: "1em",
                    height: "1em",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <AccountCircleIcon
                  data-testid="profile-icon"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  style={{
                    fontSize: "2.8rem",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    fill: "#595959",
                    width: "1em",
                    height: "1em",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              className="menu"
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {accessLevel === "superAdmin" && (
                <MenuItem onClick={handleClickSetting}>Settings</MenuItem>
              )}
              <MenuItem
                disabled={!isAuthenticated}
                onClick={() => logoutWithRedirect()}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            {isAuthenticated ? (
              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                className="drawer"
              >
                <List>
                  {[
                    { to: '/ai-writer', text: 'AI Writer' },
                    { to: '/feeds', text: 'Feeds' },
                    { to: '/ai-chat', text: 'AI Chat' },
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText>
                        <Link
                          to={item.to}
                          state={userId}
                          style={{
                            textDecoration: 'none',
                            fontSize: '18px',
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 500,
                            padding: '5px 10px',
                            color: 'rgb(36, 173, 228)',
                            border: '1px solid rgb(36, 173, 228)',
                            borderRadius: '4px',
                            transition: 'background-color 0.2s, color 0.2s',
                            display: 'inline-block',
                          }}
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = 'rgb(36, 173, 228)';
                            e.target.style.color = '#fff';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = 'rgb(36, 173, 228)';
                          }}
                        >
                          {item.text}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            ) : null}
            <div>
              <LogoImage
                src={scribeLogo}
                alt=""
                onClick={handleClick}
                sx={{ cursor: "pointer", width: "200px", "@media (max-width:900px)": { width: "150px" } }}
              />
            </div>
            {isAuthenticated ? (
              <img
                onClick={handleMenu}
                src={user.picture}
                alt="Profile"
                style={{
                  fontSize: "2.8rem",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  fill: "#595959",
                  width: "1em",
                  height: "1em",
                  cursor: "pointer",
                }}
              />
            ) : (
              <AccountCircleIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ fontSize: "2.8rem", margin: "0px 8px 0px 16px", borderRadius: "50%", backgroundColor: "white", fill: "#595959;", width: "1em", height: "1em" }}
              />
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              className="menu"
            >
              {accessLevel === "superAdmin" && (
                <MenuItem onClick={handleClickSetting}>Settings</MenuItem>
              )}
              <MenuItem
                onClick={() => logoutWithRedirect()}
                disabled={!isAuthenticated}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
