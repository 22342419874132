import api from "../api";

const fetchUserData = async () => {
  try {
    const url = `/user/users`;
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      throw new Error("You don't have permission to access this page");
    } else {
      return error;
    }
  }
};
const searchUserByName = async (name) => {
  try {
    const url = `/user/username`;
    const { data } = await api.post(url, {
      name: name,
    });
    return data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      throw new Error("You don't have permission to access this page");
    } else {
      return error;
    }
  }
};
const createOrRetrieveUser = async (userName, userEmail) => {
  try {
    const url = `/user`;
    const { data } = await api.post(url, {
      name: userName,
      email: userEmail,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deleteUsers = async (body) => {
  try {
    const url = `/user/deleteuser`;
    const { data } = await api.delete(url, { data: body });
    return data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      throw new Error("You don't have permission to access this page");
    } else {
      return error;
    }
  }
};
const editUsers = async (body) => {
  try {
    const url = `/user/roles`;
    const { data } = await api.put(url, body);
    return data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      throw new Error("You don't have permission to access this page");
    } else {
      return error;
    }
  }
};
const updateSingleUserData = async (
  user_id,
  userName,
  userEmail,
  userRole,
  userPublisher,
  userArticles,
  userTokenUsed
) => {
  try {
    const url = `/user/updateuser`;
    const { data } = await api.put(url, {
      user_id: user_id,
      name: userName,
      email: userEmail,
      role: userRole,
      publisher: userPublisher,
      articles: userArticles,
      token_used: userTokenUsed,
    });
    return data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      throw new Error("You don't have permission to access this page");
    } else {
      return error;
    }
  }
};

export default {
  fetchUserData,
  searchUserByName,
  createOrRetrieveUser,
  deleteUsers,
  editUsers,
  updateSingleUserData,
};
