import React from "react";
import ReactDOM from "react-dom/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import App from "./App";
import { UserProvider } from "../src/lib/UserProvider";
import Container from "@mui/material/Container";
import Navbar from "../src/components/navbar/navbar";
import Footer from "../src/components/footer/footer";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import config from "./config.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={config.auth0.REACT_APP_AUTH0_DOMAIN}
    clientId={config.auth0.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience:"https://mantisscribe.com",
      scope:'openid profile email'
    }}
   
  >
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <UserProvider>
          <Navbar />
          <Container
            maxWidth="xl"
            sx={{
              width: "100%",
              marginTop: "110px",
              marginBottom: "120px",
              height: "100%",
              "@media (max-width: 768px)": {
                height: "100%",
              },
            }}
          >
            <App />
          </Container>
          <Footer />
        </UserProvider>
      </BrowserRouter>
    </DndProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
