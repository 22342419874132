// React Libraries and Hooks
import React, { useState, useEffect } from 'react';

// Material UI Components
import Typography from '@mui/material/Typography';

// Local Components
import CustomOptions from '../reusableComponent/CustomOptions';

// Local Definitions from 'definitions' file
import {
  printPublications,    // Predefined set of print publications
} from "../../definitions";

// Import validation function
import sanitiseSettings from '../reusableComponent/settingsValidation';

const PublicNoticesPortalOptions = ({
  serviceMode = "public-notices",
  input,
  setInput,
  selectedItem,
  setSelectedItem,
  feedResults
}) => {
  const localStorageKey = `scribe-${serviceMode}-options`;

  useEffect(() => {
    const savedSettings = localStorage.getItem(localStorageKey);
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      const sanitisedSettings = sanitiseSettings(settings);
      localStorage.setItem(localStorageKey, JSON.stringify(sanitisedSettings)); // Update localStorage with sanitised settings
      setInput(prevState => ({
        ...prevState,
        ...sanitisedSettings,
        additionalData: { ...prevState.additionalData, ...sanitisedSettings.additionalData },
      }));
    }
  }, []);

  // Function to handle changes and update localStorage
  const handleChange = (key, value) => {
    setInput(prevState => {
      const updatedAdditionalData = { ...prevState.additionalData, [key]: value };
      const updatedState = { ...prevState, additionalData: updatedAdditionalData };

      // Save additionalData part of the state to localStorage
      localStorage.setItem(localStorageKey, JSON.stringify({ additionalData: updatedAdditionalData }));
      return updatedState;
    });
  };

  const options = [
    {
      type: 'select',
      label: 'Postcode',
      value: input?.additionalData?.search,
      onChange: (newValue) => handleChange('search', newValue),
      tooltipTitle: 'Enter the postcode for your search.',
      disableClearable: false,
    },
    {
      type: 'autocomplete',
      label: 'Publication',
      value: input?.additionalData?.publications?.includes('all')
        ? { label: 'All', value: 'all' }
        : printPublications.find(pub => (input?.additionalData?.publications || []).includes(pub.printTitle))
          ? printPublications.filter(pub => (input?.additionalData?.publications || []).includes(pub.printTitle)).map(pub => ({ label: pub.label, value: pub.printTitle }))[0]
          : null,
      onChange: (newValue) => handleChange('publications', newValue ? [newValue.value] : []),
      options: [
        ...(printPublications.length > 1 ? [{ label: 'All', value: 'all' }] : []),
        ...printPublications.map(pub => ({ label: pub.label, value: pub.printTitle }))
      ],
      isOptionEqualToValue: (option, value) => option.value === value,
      tooltipTitle: 'This field allows you to filter content by choosing a specific publication.',
    },
    {
      type: 'autocomplete',
      label: 'Date Range',
      value: [
        { label: 'All', value: 'all' },
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'This Week', value: 'this_week' },
        { label: 'Last Week', value: 'last_week' },
        { label: 'This Month', value: 'this_month' },
        { label: 'Last Month', value: 'last_month' },
      ].find(option => option.value === input?.additionalData?.dateRange) || null,
      onChange: (newValue) => handleChange('dateRange', newValue ? newValue.value : ''),
      options: [
        { label: 'All', value: 'all' },
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'This Week', value: 'this_week' },
        { label: 'Last Week', value: 'last_week' },
        { label: 'This Month', value: 'this_month' },
        { label: 'Last Month', value: 'last_month' },
      ],
      tooltipTitle: 'This field allows you to filter content by choosing a specific date range.',
      gridSize: { xs: 12, md: 4 }
    },
    {
      type: 'autocomplete',
      label: 'Order By',
      value: [
        { label: 'Published Date', value: 'publishedTime' },
        { label: 'Distance', value: 'distance' }
      ].find(option => option.value === input?.additionalData?.orderBy) || null,
      onChange: (newValue) => handleChange('orderBy', newValue ? newValue.value : ''),
      options: [
        { label: 'Published Date', value: 'publishedTime' },
        { label: 'Distance', value: 'distance' }
      ],
      tooltipTitle: 'Select how you want the results ordered.',
      gridSize: { xs: 12, md: 4 }
    },
    {
      type: 'autocomplete',
      label: 'Category',
      value: [
        'all', 'planning', 'traffic', 'hgv', 'licensing', 'probate', 'legal', 'statutory', 'other'
      ].map(category => ({
        label: category === 'hgv' ? 'HGV' : category.charAt(0).toUpperCase() + category.slice(1),
        value: category
      })).find(option => option.value === input?.additionalData?.category) || null,
      onChange: (newValue) => handleChange('category', newValue ? newValue.value : ''),
      options: [
        'all', 'planning', 'traffic', 'hgv', 'licensing', 'probate', 'legal', 'statutory', 'other'
      ].map(category => ({
        label: category === 'hgv' ? 'HGV' : category.charAt(0).toUpperCase() + category.slice(1),
        value: category
      })),
      tooltipTitle: 'Select the category of notices you are interested in.',
      gridSize: { xs: 12, md: 4 }
    },
    {
      type: 'multiSelect',
      label: 'Select Item',
      value: selectedItem,
      onChange: (newValue) => {
        // Ensure newValue is an array and map it to their corresponding objects from options
        const newSelection = Array.isArray(newValue) ? newValue.map(value => {
          const item = feedResults.find(item => item.url === value);
          return {
            label: item ? item.title : '',
            value: value ? value : '',
            title: item ? item.title : '',
            url: value ? value : '',
            postcode: item ? item.postcode : '',
            category: item ? item.category : '',
          };
        }) : [];

        setSelectedItem(newSelection);
      },
      options: [
        ...feedResults.map(item => ({ label: item.title, value: item.url, title: item.title, url: item.url, postcode: item.postcode, category: item.category }))
      ],
      tooltipTitle: 'Select an item from the fetched results.',
      sx: { mb: 4 },
      gridSize: { xs: 12 }
    }
  ];

  return (
    <>
      <Typography sx={{ mb: 2 }}>Public Notices Options</Typography>
      <CustomOptions options={options} />
    </>
  );
};

export default PublicNoticesPortalOptions;
