import React from 'react';

const CheckpointsList = ({ checkpoints, onSelectCheckpoint }) => {
  return (
    <div>
      {checkpoints.map((checkpoint) => (
        <div key={checkpoint.id} onClick={() => onSelectCheckpoint(checkpoint)}>
          <h3>
            {checkpoint.name} - Version {checkpoint.version}
          </h3>
          <p>Created on: {checkpoint.date}</p>
        </div>
      ))}
    </div>
  );
};

export default CheckpointsList;
