import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ExpandMore, Item, ItemFacts } from "./styledComponents";
import Fact from "./factDisplay";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useEffect } from "react";

const grid = 8;

const SummaryBox = (props) => {
  const {
    summary,
    img,
    expanded,
    loading,
    handleExpandClick,
    facts,
    pagination,
    pagesSize,
    handlePageChange,
    allDatafact,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event, value) => {
    setSelectedOptions(value);
  };
  return summary && img ? (
    <>
      <div className="summarysection">
        <Grid container>
          <Grid item xs={6} sx={{ padding: "10px" }}>
            <Card sx={{ display: "flex" }}>
              <CardMedia
                component="img"
                sx={{ width: 151, height: 151 }}
                image={img}
                alt="profile"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {expanded ? (
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    {summary}...
                  </CardContent>
                ) : (
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    {summary.slice(0, 200)}...
                  </CardContent>
                )}
                <CardActions disableSpacing>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ padding: "10px" }}>
            <Autocomplete
              multiple
              id="size-small-outlined"
              size="small"
              sx={{ marginBottom: "10px" }}
              disableClearable
              options={allDatafact.map((fact) => fact.split(":")[0])}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search within facts result"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ cursor: "pointer" }}
                          color="primary"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {selectedOptions.length === 0 ? (
                <>
                  {facts.map((fact, index) => (
                    <Grid item xs={4} sm={4} md={4} key={index}>
                      <Fact index={index} item={fact} />
                    </Grid>
                  ))}
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {pagination && (
                      <div className="paginationComponent">
                        <Pagination
                          count={pagesSize}
                          variant="outlined"
                          size="small"
                          onChange={handlePageChange}
                          defaultPage={1}
                        />
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  {selectedOptions.map((fact, index) => (
                    <Grid item xs={4} sm={4} md={4} key={index}>
                      <Fact index={index} item={fact} />
                    </Grid>
                  ))}
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {pagination && (
                      <div className="paginationComponent">
                        <Pagination
                          count={pagesSize}
                          variant="outlined"
                          size="small"
                          onChange={handlePageChange}
                          defaultPage={1}
                        />
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    <>
      <div className="summarysection">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <div className="loadingcomponent">
              {loading && <CircularProgress />}
            </div>
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {facts.map((fact, index) => (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  <Fact index={index} item={fact} />
                </Grid>
              ))}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {pagination && (
                  <div className="paginationComponent">
                    <Pagination
                      count={pagesSize}
                      variant="outlined"
                      size="small"
                      onChange={handlePageChange}
                      defaultPage={1}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SummaryBox;
