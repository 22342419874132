const findByValue = (array, value) => {
  const foundObj = array.find((item) => item.value === value || item.label === value);
  return foundObj || { label: value, value };
};

const convertToTemperatureValue = (array, input) => {
  const isWholeNumber = num => Number.isInteger(num);

  if (typeof input === 'string') {
    const parsedInput = parseFloat(input);
    if (!isNaN(parsedInput)) {
      const found = array.find(obj => parsedInput === obj.value || parsedInput === parseFloat(obj.label));
      return found ? found.value : array[4].value;
    }
  } else if (typeof input === 'number') {
    const found = array.find(obj => input === obj.value || (isWholeNumber(input) && input === parseFloat(obj.label)));
    return found ? found.value : array[4].value;
  }

  return array[4].value; // Default return value if no match is found
};

const findFirstTeamByUseCase = (serviceType, teamServiceTypeMapping) => {
  for (const team in teamServiceTypeMapping) {
    if (teamServiceTypeMapping[team].includes(serviceType)) {
      return team;
    }
  }
  return "Editorial";
};

const sortAndStringify = (object) => object ? JSON.stringify(object, Object.keys(object).sort()) : null;

// Utility function to partition messages into system and non-system
function partitionMessages(messages) {
  return messages.reduce(([sys, nonSys], message) => {
    if (message.role === 'system') {
      sys.push(message);
    } else {
      nonSys.push(message);
    }
    return [sys, nonSys];
  }, [[], []]);
}

export {
  findByValue,
  convertToTemperatureValue,
  findFirstTeamByUseCase,
  sortAndStringify,
  partitionMessages
};