import toast from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, Link } from '@mui/material';
// Local Definitions from 'definitions' file
import { publications } from "../../definitions";
import usersApi from '../../server/usersApi';

export const TASK_STATUS = {
  PENDING: 'Pending',
  TIMED: 'Timed',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  DISABLED: 'Disabled',
  LOCAL: 'Local'
};

export const RECURRENCE_RULE_OPTIONS = {
  NONE: 'None',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

export const DATE_RANGES = [
  { label: 'All', value: 'all' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'This Week', value: 'this_week' },
  { label: 'Last Week', value: 'last_week' },
  { label: 'This Month', value: 'this_month' },
  { label: 'Last Month', value: 'last_month' },
];

export const ORDER_BY_OPTIONS = [
  { label: 'Published Date', value: 'publishedTime' },
  { label: 'Distance', value: 'distance' }
];

export const ARTICLE_TYPES = [
  { label: "All", value: "all" },
  { label: "Planning", value: "planning" },
  { label: "Traffic", value: "traffic" },
  { label: "HGV", value: "hgv" },
  { label: "Licensing", value: "licensing" },
  { label: "Probate", value: "probate" },
  { label: "Legal", value: "legal" },
  { label: "Statutory", value: "statutory" },
  { label: "Other", value: "other" }
];

export const TASK_TYPES = ['Public Notices', 'Unique Article', 'Weather Article'];

export const calculateNextScheduledTime = (currentScheduledTime, recurrenceRule) => {
  if (!(currentScheduledTime instanceof Date) || isNaN(currentScheduledTime.getTime())) {
    throw new Error('Invalid currentScheduledTime: must be a valid Date object.');
  }

  let nextScheduledTime = new Date(currentScheduledTime);

  switch (recurrenceRule) {
    case 'Daily':
      nextScheduledTime.setDate(nextScheduledTime.getDate() + 1);
      break;
    case 'Weekly':
      nextScheduledTime.setDate(nextScheduledTime.getDate() + 7);
      break;
    case 'Monthly':
      const currentMonth = nextScheduledTime.getMonth();
      nextScheduledTime.setMonth(currentMonth + 1);

      // Handle end-of-month cases
      if (nextScheduledTime.getMonth() !== (currentMonth + 1) % 12) {
        nextScheduledTime.setDate(0); // Move to the last day of the previous month
      }
      break;
    case 'Yearly':
      const currentYear = nextScheduledTime.getFullYear();
      nextScheduledTime.setFullYear(currentYear + 1);

      // Handle leap year adjustments if needed
      if (nextScheduledTime.getMonth() === 1 && nextScheduledTime.getDate() === 29 && !isLeapYear(nextScheduledTime.getFullYear())) {
        nextScheduledTime.setDate(28);
      }
      break;
    default:
      throw new Error(`Unsupported recurrence rule: ${recurrenceRule}`);
  }

  // Set the time based on the current scheduled time
  nextScheduledTime.setHours(currentScheduledTime.getHours());
  nextScheduledTime.setMinutes(currentScheduledTime.getMinutes());
  nextScheduledTime.setSeconds(currentScheduledTime.getSeconds());

  return nextScheduledTime;
}

// Helper function to check for leap years
const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export const applyDateRangeFilter = (tasks, field, range) => {
  if (range === 'all') return tasks;

  const now = new Date();
  let startDate, endDate;

  switch (range) {
    case 'today':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      break;
    case 'yesterday':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      break;
    case 'this_week':
      startDate = new Date(now.setDate(now.getDate() - now.getDay()));
      endDate = new Date(now.setDate(now.getDate() - now.getDay() + 7));
      break;
    case 'last_week':
      startDate = new Date(now.setDate(now.getDate() - now.getDay() - 7));
      endDate = new Date(now.setDate(now.getDate() - now.getDay()));
      break;
    case 'this_month':
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      break;
    case 'last_month':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 1);
      break;
    default:
      return tasks;
  }

  return tasks.filter(task => {
    const taskDate = new Date(task[field]);
    return taskDate >= startDate && taskDate < endDate;
  });
};

const getPublicationLabel = (writingStyle) => {
  const publication = publications.find(pub => pub.value === writingStyle);
  return publication ? publication.label : writingStyle;
};

// Function to generate links from output.messages
export const fetchMessageDetails = async (messages, user_id) => {
  // Determine if `messages` is an array of objects or IDs
  const isArrayOfObjects = messages[0] && typeof messages[0] === 'object' && messages[0].id;

  // Extract IDs from `messages` if they are objects or use them directly if they are IDs
  const ids = isArrayOfObjects
    ? messages.map(item => item.id)  // Extract IDs from objects
    : messages;  // Use IDs directly

  // Fetch message details for each ID
  const messageDetails = await Promise.all(ids.map(id => usersApi.fetchUserMessageById(user_id, id)));

  // Filter out null or malformed responses and include url if available, then sort by modified_date
  return messageDetails
    .filter(message => message !== null && Array.isArray(message) && message.length > 0)
    .map(message => {
      const detail = message[0]; // Extract the message detail
      // If messages were objects, `url` is already part of `detail`, otherwise `url` will be `undefined`
      return {
        ...detail,
        url: isArrayOfObjects ? messages.find(item => item.id === detail.id).url : null  // Include url if it exists
      };
    })
    .sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date)); // Sort by modified_date
};

export const generateHistoryLinks = (sortedMessages, toastId) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      padding: '16px',
      borderRadius: '8px',
      width: '100%',
      maxWidth: '600px',
      maxHeight: '90vh',
      overflowY: 'auto',
      boxSizing: 'border-box',
    }}
  >
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Button onClick={() => toast.remove(toastId)} style={{ marginBottom: '8px' }}>Close</Button>
    </div>
    <div style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
      {sortedMessages.slice(0, 10).map((message, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px', padding: '8px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9', width: '100%', boxSizing: 'border-box' }}>
          <Link component={RouterLink} to={`/ai-chat/${message.id}`} target="_blank" style={{ fontWeight: 'bold', marginBottom: '4px' }}>
            {message.name}
          </Link>
          <Typography variant="body2" color="textSecondary">
            CMS: {getPublicationLabel(message.settings.writingStyle)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Service Type: {message.settings.serviceType}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Created: {new Date(message.created_date).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Modified: {new Date(message.modified_date).toLocaleDateString()}
          </Typography>
          {message.url && (
            <Typography variant="body2" color="textSecondary">
              <a href={message.url} target="_blank" rel="noopener noreferrer" style={{ color: '#1a73e8' }}>
                View in CMS
              </a>
            </Typography>
          )}
        </div>
      ))}
      {sortedMessages.length > 10 && (
        <Typography variant="body2" style={{ marginTop: '8px' }}>
          and {sortedMessages.length - 10} more...
        </Typography>
      )}
    </div>
  </div>
);
