import api from '../api';

const getSubjectFacts = async (subject, type, sport, date) => {
  try {
    const api_subject = subject.toLowerCase().split(" ").join("-");
    const url =
      type === "players"
        ? `/facts/sport/${type}/${sport}/${api_subject}/${date}`
        : `/facts/sport/${type}/${sport}/${api_subject}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    return { error: "something went wrong" };
  }
};
const getAvailableGames = async (sport, type, start_date, end_date) => {
  try {
    const url = `/facts/sport/${type}/${sport}/${start_date}/${end_date}`;
    const { data } = await api.get(url);

    return data;
  } catch (error) {
    return { error: "something went wrong" };
  }
};

const getGameData = async (sport, game_id) => {
  try {
    const url = `/facts/sport/${sport}/game/${game_id}`;
    const { data } = await api.get(url);

    return data;
  } catch (error) {
    return { error: "something went wrong" };
  }
};

const getSeasonData = async (sport) => {
  try {
    const url = `/facts/sport/${sport}/season/2021-regular`;
    const { data } = await api.get(url);

    return data;
  } catch (error) {
    return { error: "something went wrong" };
  }
};

const getImage = async (sport, type, subject) => {
  try {
    const api_subject = subject.toLowerCase().split(" ").join("-");
    const url = `/facts/sport/${sport}/image/${type}/${api_subject}`;
    const { data } = await api.get(url);

    return data;
  } catch (error) {
    return { error: "something went wrong" };
  }
};

export {
  getSubjectFacts,
  getAvailableGames,
  getGameData,
  getSeasonData,
  getImage,
};
