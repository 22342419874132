import React from "react";
import Select from "react-select/async";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../styles/template.scss";

const AddGroupPopup = (props) => {
  const {
    openAddGroupPopup,
    openAddVariablePopup,
    handleCloseAddVariable,
    handleClickOpenAddVariable,
    handleCloseAddGroup,
    handleInputChangeType,
    handleClickSaveGroup,
    handleInputChangeVariable,
    options,
  } = props;

  const typeData = [
    { value: "season", label: "Season" },
    { value: "games", label: "Games" },
    { value: "players", label: "Player" },
    { value: "teams", label: "Team" },
  ];

  return (
    <div>
      <div className="left-section">
        <div>
          <Dialog
            fullWidth
            data-testid="add-group-dialog"
            open={openAddGroupPopup}
            onClose={handleCloseAddGroup}
          >
            <DialogTitle>Select options</DialogTitle>
            <DialogContent sx={{ height: "150px" }}>
              <div>
                <Select
                  placeholder="Choose Type"
                  defaultOptions={typeData}
                  isClearable={true}
                  onChange={(e) => {
                    handleInputChangeType(e);
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddGroup}>Cancel</Button>
              <Button
                data-testid="save-group-button"
                onClick={handleClickSaveGroup}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            open={openAddVariablePopup}
            sx={{ height: "100%" }}
            onClose={handleClickOpenAddVariable}
          >
            <DialogTitle>Search</DialogTitle>
            <DialogContent>
              <div>
                <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  sx={{ marginBottom: "10px" }}
                  disableClearable
                  options={options.map((option) => option)} // just for UI purpose hardcoded data
                  onChange={handleInputChangeVariable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      id="outlined-size-small"
                      className="outlined-size-small"
                      sx={{ zIndex: "0" }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              sx={{ cursor: "pointer" }}
                              color="primary"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddVariable}>Cancel</Button>
              <Button onClick={handleCloseAddVariable}>Save</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default AddGroupPopup;
