import React, { useState } from 'react';

const DataUploader = ({
  onDataUpload,
  modelName,
  batchSize,
  learningRateMultiplier,
  nEpochs,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const MAX_TOKEN_COUNT = 3000;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.toLowerCase().endsWith('.jsonl')) {
      setSelectedFile(file);
      setErrors([]);
    } else {
      setErrors(['Please upload a valid .jsonl file.']);
    }
  };

  const validateJSONLFormat = (content) => {
    const lines = content.split('\n');
    return lines.every((line) => {
      try {
        JSON.parse(line);
        return true;
      } catch {
        return false;
      }
    });
  };

  const countTokens = (content) => {
    return content.split(/\s+/).length;
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target.result;
      if (!validateJSONLFormat(fileContent)) {
        setErrors([...errors, 'Invalid JSONL format.']);
        return;
      }

      const totalTokens = countTokens(fileContent);
      if (totalTokens > MAX_TOKEN_COUNT) {
        setErrors([
          ...errors,
          `Exceeds maximum token count of ${MAX_TOKEN_COUNT}.`,
        ]);
        return;
      }

      const hyperparameters = {
        ...(batchSize && { batch_size: batchSize }),
        ...(learningRateMultiplier && {
          learning_rate_multiplier: learningRateMultiplier,
        }),
        ...(nEpochs && { n_epochs: nEpochs }),
      };

      const formData = new FormData();
      formData.append('dataFile', selectedFile);
      if (modelName) {
        formData.append('modelName', modelName);
      }

      formData.append('hyperparameters', JSON.stringify(hyperparameters));

      try {
        const response = await fetch('/v1/chat/fine-tuning', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          onDataUpload(responseData);
        } else {
          const errorData = await response.json();
          setErrors([...errors, errorData.message]);
        }
      } catch (error) {
        console.error('Error sending data to backend:', error);
        setErrors([...errors, 'Failed to send data to the backend.']);
      }
    };
    reader.readAsText(selectedFile);
  };

  return (
    <div>
      <h2>Training Data Upload (.JSONL)</h2>
      <input type="file" accept=".jsonl" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {errors.length > 0 && (
        <div>
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DataUploader;
