import api from '../api';

// Function to get the Google OAuth URL
const fetchGoogleAuthUrl = async (redirectUri) => {
  try {
    const url = `/v1/google/auth-url`;
    const { data } = await api.post(url, { redirectUri });
    return data.url;
  } catch (error) {
    console.error("Error fetching Google auth URL:", error);
    throw error;
  }
};

// Function to handle the OAuth callback
const handleGoogleAuthCallback = async (code, redirectUri) => {
  try {
    const url = `/v1/google/auth/callback?code=${code}`;
    const { data } = await api.post(url, { redirectUri });
    return data;
  } catch (error) {
    console.error("Error handling Google auth callback:", error);
    throw error;
  }
};

// Function to fetch file info from Google Drive
const fetchGoogleFileInfo = async (fileId) => {
  try {
    const url = `/v1/google/file-info?fileId=${fileId}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    console.error("Error fetching Google file info:", error);
    throw error;
  }
};

// Function to fetch file contents from Google Drive
const getFileContent = async (fileId, accessToken) => {
  try {
    const url = `/v1/google/file-contents?fileId=${fileId}&accessToken=${accessToken}`;
    const { data } = await api.get(url, {
      responseType: 'blob',
    });
    return data;
  } catch (error) {
    console.error("Error fetching Google file contents:", error);
    throw error;
  }
};

export default {
  fetchGoogleAuthUrl,
  handleGoogleAuthCallback,
  fetchGoogleFileInfo,
  getFileContent
};
