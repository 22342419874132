import React, { useEffect, useState } from "react";
import {
  SportSelectBox,
  StartDatePicker,
  SearchPlayerTeam,
  EndDatePicker,
} from "../../reusableComponent/selectFacts";
import Button from "@mui/material/Button";
import "./aiwriter.scss";
import loadOptions from "../../../server/dbpedia";
import { getLabels } from "../../../data/apiFields";
import * as utils from "../../../utils.js";
import { getAvailableGames } from "../../../server/sportsfeed";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import writerApi from "../../../server/writerApi.js";

import {
  Servicetype,
  Languages,
  ArticleTone,
  ArticleTemperature,
  MaxArticleLength,
  ArticleType,
  TimeFrame,
  StatInclude,
  Game,
  StatIncludeGame,
} from "./aiWriterFilterOption";

const AiWriterSelection = (props) => {
  const { handleCreateTemplate, articleData, id } = props;
  const [sport, setSport] = useState("");
  const [articleType, setArticleType] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [player1, setPlayer1] = useState("");
  const [player2, setPlayer2] = useState("");
  const [stats, setStats] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [games, setGames] = useState([]);
  const [game, setGame] = useState("");
  const [serviceType, setServiceType] = useState("US Sports Article (API)");
  const [language, setLanguage] = useState("English (British English)");
  const [articleTone, setArticleTone] = useState("Informative");
  const [temperature, setTemperature] = useState("5");
  const [maxLength, setMaxLength] = useState("5000");
  const [aiWriterPrompt, setAiWriterPrompt] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openWarningCreativity, setOpenWarningCreativity] = useState(false);
  const [showCreativityWarning, SetCreativityWarning] = useState(false);
  const [showArticleWarning, SetArticleWarning] = useState(false);

  const handleClickOpenWarning = () => {
    setOpenWarning(true);
    localStorage.setItem("article-warning-shown", true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };
  const handleOpenWarningCreativity = () => {
    setOpenWarningCreativity(true);
    localStorage.setItem("creativity-warning-shown", true);
  };

  const handleCloseWarningCreativity = () => {
    setOpenWarningCreativity(false);
  };
  const handleOpenArticleWaring = () => {
    if (showArticleWarning) {
      setOpenWarning(false);
      createReqBody();
    } else {
      handleClickOpenWarning();
    }
  };
  const resetForm = () => {
    setArticleType("");
    setTimeframe("");
    setPlayer1("");
    setPlayer2("");
    setStats([]);
    setStartDate("");
    setEndDate("");
    setGames([]);
    setGame("");
    setAiWriterPrompt("");
  };

  const handleInputChangeSport = (e) => {
    if (e) {
      setSport(e.target.textContent.toLowerCase());
    } else {
      setSport("");
    }
  };
  const handleChangeUsecase = (event, newValue) => {
    resetForm();
    setServiceType(newValue.value);
  };
  const handleChangeLanguage = (event, newValue) => {
    setLanguage(newValue.value);
  };
  const handleChangeArticleTone = (event, newValue) => {
    setArticleTone(newValue.value);
  };
  const handleChangeTemperature = (event, newValue) => {
    if (showCreativityWarning) {
      handleCloseWarningCreativity();
    } else {
      handleOpenWarningCreativity();
      setTemperature(newValue.value);
    }
  };
  const handleChangeMaxLength = (event, newValue) => {
    setMaxLength(newValue.value);
  };
  const handleInputStartDate = (value) => {
    setStartDate(value);
  };
  const handleAiWriterPromptChange = (event) => {
    setAiWriterPrompt(event.target.value);
  };
  const handleInputEndDate = (value) => {
    setEndDate(value);
  };
  const handleChangeArticleType = (event, newValue) => {
    setArticleType(newValue.value);
  };
  const handleChangeTimeframe = (event, newValue) => {
    setTimeframe(newValue.value);
  };
  const handleChangePlayer1 = (val) => {
    if (val) {
      setPlayer1(val.label);
    } else {
      setPlayer1("");
    }
  };

  const handleChangePlayer2 = (val) => {
    if (val) {
      setPlayer2(val.label);
    } else {
      setPlayer2("");
    }
  };
  const handleChangeStat = (val, { action, prevInput }) => {
    setStats(val);
  };

  const handleChangeGame = (game) => {
    setGame(game.value);
  };
  const handleLoadOptions = (inputValue) => {
    return articleType === "Player Comparison"
      ? loadOptions(inputValue, "players", sport)
      : loadOptions(inputValue, "teams", sport);
  };

  const createReqBody = () => {
    let config = {
      options: {
        model: "gpt-3.5-turbo",
        messages: [{ role: 'user', content: aiWriterPrompt }],
      },
      settings: {
        serviceType: serviceType,
        language,
        articleTone,
        temperature,
        maxLength,
        writingStyle: "",
      }
    };

    if (serviceType === "US Sports Article (API)") {
      config =
        articleType === "Game Report"
          ? {
            ...config,
            sport,
            game,
            settings: {
              ...config.settings,
              serviceType: articleType,
            },
            variables: stats,
            timeframe,
          }
          : {
            ...config,
            player1,
            player2,
            settings: {
              ...config.settings,
              serviceType: articleType,
            },
            variables: stats,
            season: "2023-regular",
            sport: "nfl",
            timeframe,
          };
    }

    handleCloseWarning();
    return handleCreateTemplate(config);
  };

  const handleLoadGamesOptions = async () => {
    const start_date = utils.isoToDate(startDate.toISOString());
    const end_date = utils.isoToDate(endDate.toISOString());
    const matches = await getAvailableGames(
      sport,
      "games",
      start_date,
      end_date
    );
    const mapOptions = matches
      .map((match) => {
        return {
          label: `${match["Final Score"]}, ${match["Game Date"]}`,
          value: match.gameId,
        };
      })
      .slice(0, 20);
    setGames(mapOptions);
  };
  const fetchGameOptions = async () => {
    if (articleType === "Game Report" && startDate && endDate) {
      await handleLoadGamesOptions();
    }
  };
  const fetchArticleInfo = async () => {
    const data = await writerApi.fetchArticleById(id);
    if (data) {
      setAiWriterPrompt(data[0].properties.message);
      setTemperature(data[0].properties.settings.temperature);
      setArticleTone(data[0].properties.settings.articleTone);
      setLanguage(data[0].properties.settings.language);
    } else {
      resetForm();
    }
  };
  useEffect(() => {
    SetCreativityWarning(localStorage.getItem("creativity-warning-shown"));
    SetArticleWarning(localStorage.getItem("article-warning-shown"));
    fetchGameOptions();
    if (id) {
      fetchArticleInfo();
    }
  }, [startDate, endDate, language, sport]);

  return (
    <div className="left-section">
      <h3 className="titlename">AI-Writer</h3>
      <div className="filter-section">
        <h5 className="headingsection">Settings:</h5>
        <div className="select-box">
          <Servicetype
            handleChangeUsecase={handleChangeUsecase}
            serviceType={serviceType}
          />
        </div>
        <div className="select-box">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={5.9}>
              <Languages
                language={language}
                handleChangeLanguage={handleChangeLanguage}
              />
            </Grid>
            <Grid item xs={5.9}>
              <ArticleTone
                articleToneValue={articleTone}
                handleChangeArticleTone={handleChangeArticleTone}
              />
            </Grid>
          </Grid>
        </div>
        <div className="select-box">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={5.9}>
              <ArticleTemperature
                temperature={temperature}
                handleChangeTemperature={handleChangeTemperature}
              />
            </Grid>
            <Grid item xs={5.9}>
              <MaxArticleLength
                maxLength={maxLength}
                handleChangeMaxLength={handleChangeMaxLength}
              />
            </Grid>
          </Grid>
        </div>
        <h5 className="headingsection">Article Info:</h5>
        <div className="select-box">
          <SportSelectBox handleInputChangeSport={handleInputChangeSport} />
        </div>
        <div className="select-box">
          <ArticleType handleChangeArticleType={handleChangeArticleType} />
        </div>
        <div className="select-box">
          <TimeFrame handleChangeTimeframe={handleChangeTimeframe} />
        </div>
        {articleType === "Player Comparison" && (
          <>
            <h5 className="headingsection">Player Comparison</h5>
            <div className="select-box">
              <SearchPlayerTeam
                placeholder="Search Player1"
                type={articleType}
                handleLoadOptions={handleLoadOptions}
                handleInputChangeSearch={handleChangePlayer1}
              />
            </div>
            <div className="select-box">
              <SearchPlayerTeam
                placeholder="Search Player2"
                type={articleType}
                handleLoadOptions={handleLoadOptions}
                handleInputChangeSearch={handleChangePlayer2}
              />
            </div>
            <div className="select-box">
              <StatInclude
                handleChangeStat={handleChangeStat}
                options={getLabels("players")}
              />
            </div>
            <div className="select-box">
              <TextareaAutosize
                aria-label="Prompt"
                placeholder="Type your message..."
                style={{ width: "100%", height: 170 }}
                onChange={handleAiWriterPromptChange}
              />
            </div>
          </>
        )}
        {articleType === "Game Report" && (
          <>
            <h5 className="headingsection">Game Report</h5>
            <div className="select-box">
              <StartDatePicker
                handleInputStartDate={handleInputStartDate}
                startDate={startDate}
              />
            </div>
            <div className="select-box">
              <EndDatePicker
                handleInputEndDate={handleInputEndDate}
                endDate={endDate}
              />
            </div>
            <div className="select-box">
              <Game handleChangeGame={handleChangeGame} games={games} />
            </div>
            <div className="select-box">
              <StatIncludeGame
                handleChangeStat={handleChangeStat}
                sport={sport}
              />
            </div>
            <div className="select-box">
              <TextareaAutosize
                aria-label="Prompt"
                placeholder="Type your message..."
                style={{ width: "100%", height: 100 }}
                onChange={handleAiWriterPromptChange}
              />
            </div>
          </>
        )}
        <div>
          <Dialog
            open={openWarning}
            onClose={handleCloseWarning}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please be aware that Mantis Scribe is a product powered by the
                DaVinci model developed by OpenAI and is not always accurate or
                up-to-date. The model is trained on a diverse range of internet
                text and may contain misinformation, inaccuracies, or offensive
                content. It is important to verify the information generated by
                Mantis Scribe before using it for publication or making
                important decisions. As with any AI model, it is important to
                use a critical and cautious approach when interpreting the
                results.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseWarning}>Disagree</Button>
              <Button onClick={createReqBody} autoFocus>
                Acknowledge and Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={openWarningCreativity}
            onClose={handleCloseWarningCreativity}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">ATTENTION!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Adjusting the temperature of this model can impact its
                creativity and lead to output that may not always be accurate or
                factual. As a language model, it does not have a personal
                understanding or consciousness and its responses are generated
                based on patterns in the data it was trained on. Always verify
                information and use your judgement before relying on the output
                generated by this model.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseWarningCreativity}>Cancle</Button>
              <Button onClick={handleCloseWarningCreativity} autoFocus>
                Acknowledge and Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Button
          size="small"
          variant="contained"
          onClick={handleOpenArticleWaring}
          style={{ backgroundColor: "#24ADE4" }}
        >
          Write Article
        </Button>
      </div>
    </div>
  );
};
export default AiWriterSelection;
