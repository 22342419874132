import { getSubjectFacts } from "./sportsfeed";
import api from '../api';

const fetchTemplates = async (user) => {
  const { data } = await api.get(`/templates/${user}`);
  return data;
};

const fetchTemplateGroups = async (template_id) => {
  const { data } = await api.get(
    `/templates/template/${template_id}`
  );
  return formatTemplateGroups(
    data[template_id].template_groups,
    data[template_id].template_type,
    data[template_id].text,
    data[template_id].template_name
  );
};

const formatTemplateGroups = (data, type, text, name) => {
  const obj = data.reduce((result, row) => {
    result[row.group_id] = result[row.group_id] || {
      group_name: row.group_name,
      type: type,
      text: text,
      name: name,
      facts: [],
    };
    result[row.group_id].facts.push({
      fact_id: row.fact_id,
      fact: row.fact,
      label_key: row.label_key,
      label_value: row.label_value,
    });
    return result;
  }, {});
  const arr = Object.keys(obj).map((k) => obj[k]);
  return arr;
};

const loadVariables = async (variables, subject, type, sport, date) => {
  const playersData = await getSubjectFacts(subject, type, sport, date);
  const loadedData = variables.map((variable) => {
    const objKey = getObjKey(
      playersData[variable.label_key],
      variable.label_value
    );

    return {
      fact: variable.name,
      newFact: objKey,
    };
  });
  return loadedData;
};

const getObjKey = (obj, val) => {
  const objKey = Object.keys(obj).filter((key) => key.includes(val))[0];
  return obj[objKey];
};
export { fetchTemplateGroups, loadVariables, fetchTemplates };
