import api from '../api';

const postMatchReport = async (body) => {
  try {
    const url = `/v1/web/feeds/match-reports`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const postPlayerComparison = async (body) => {
  try {
    const url = `/v1/web/feeds/player-comparisons`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default
  {
    postMatchReport,
    postPlayerComparison
  };
