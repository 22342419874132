import completionsApi from '../../server/completionsApi';
import webCrawlingApi from '../../server/webCrawlingApi';

// Mapping of notice categories to URL segments
const categoryMap = {
  planning: "planning",
  traffic: "traffic-and-roads",
  hgv: "goods-vehicle-licensing",
  licensing: "alcohol-and-licensing",
  probate: "probate-and-trustee",
  legal: "contract-and-tender",
  statutory: "statutory",
  other: "other"
};

/**
 * Constructs URLs for each notice based on its category and IID.
 * @param {Array} data - The array of notice items from the API response.
 * @returns {Array} An array of objects, each containing a URL and title for a notice.
 */
export const constructNoticeUrls = (data) => {
  return data.map(item => {

    //Get the notice's category
    const category = item.category || 'other';

    // Get the URL segment for the notice's category from the categoryMap
    // Default to 'other' if the category is not found in the map
    const noticeTypeUrl = categoryMap[category];

    // Construct the full URL for the notice
    const url = `https://publicnoticeportal.uk/notice/${noticeTypeUrl}/${item.iid}`;

    // Format the publish start date in 'en-GB' format
    const publishStartDate = new Date(item.publishStartDate).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'long', year: 'numeric'
    });

    // Include the formatted publish start date in the title, separated by a " - "
    const titleWithDate = `${item.title} - ${publishStartDate}`;

    return {
      url: url, // The constructed URL
      title: titleWithDate, // The title of the notice with the publish start date
      category,
      postcode: item?.closestLocation?.postcode || ''
    };
  });
}

export const checkTaskStatusWithInterval = async (taskId, timeoutInSeconds, intervalMilliseconds) => {
  return new Promise((resolve, reject) => {
    let intervalId;
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      reject(new Error("Timeout while waiting for task completion"));
    }, timeoutInSeconds * 1000);

    intervalId = setInterval(async () => {
      const task = await completionsApi.checkTaskStatusInCompletionsQueue(taskId);
      if (task) {
        const taskStatus = task.message;

        if (taskStatus === 'Completed' || taskStatus === 'Failed') {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          resolve(task);
        }
      }
    }, intervalMilliseconds);
  });
};

export const enqueueMessage = async (requestBody, callback) => {
  // Enqueue message to completions API
  const response = await completionsApi.enqueueMessageToCompletionsAPI(requestBody);
  if (response?.status === 202 && response?.id) {
    // Check task status with interval
    const taskId = response.id; // Assuming the response object has an id property
    const taskStatusResponse = await checkTaskStatusWithInterval(
      taskId,
      240, // Timeout after 240 seconds (4 minutes)
      8000 // Check every 8 seconds
    );

    // Handle task status response
    if (taskStatusResponse && taskStatusResponse.output) {
      const { output } = taskStatusResponse;
      const resultObject = JSON.parse(output);
      const { message, messages } = resultObject;
      if (message && message.length > 0 && messages && messages.length > 0) {
        await callback(message, messages);
      }
    } else {
      throw new Error(`API Error: ${taskStatusResponse?.response?.data?.message ? taskStatusResponse.response.data.message : taskStatusResponse.message}`);
    }

  } else {
    throw new Error(`${response?.response?.data?.message ? response.response.data.message : response.message}`);
  }
};

// Helper function to check if a string is a valid URL
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export const crawlNotices = async (notices, additionalData) => {
  const { publication, dateRange } = additionalData;
  const crawlResults = [];

  for (const notice of notices) {
    const { url, title, postcode, category } = notice;
    if (!isValidUrl(url)) {
      throw new Error(`Title: ${title}, Error: Invalid URL`);
    }

    try {
      const crawlData = await webCrawlingApi.initiateWebCrawling({ url });
      if (crawlData.status !== 200) {
        throw new Error(`Label: ${item.label}, Error: Status ${crawlData.status}`);
      }

      const crawContent = crawlData.data;

      let result = {
        url,
        title,
        content: crawContent,
        publication: publication.label !== "All" ? publication.label : undefined,
        dateRange: dateRange !== "All" ? dateRange : undefined,
        postcode,
        category
      };

      crawlResults.push(result);
    } catch (error) {
      console.error(`Error processing URL ${url}:`, error.message);
      throw error; // Re-throw the error to handle it outside
    }
  }

  return crawlResults;
};

export const formatNoticeData = (notices) => {
  // Ensure notices is an array
  const noticesArray = Array.isArray(notices) ? notices : [notices];

  return noticesArray.map(notice => {
    const {
      url,
      title,
      content,
      publication,
      dateRange,
      postcode
    } = notice;

    let result = `URL:\n${url}\nTitle:\n${title}\nNotice Contents:\n${content}\n`;

    if (publication) {
      result += `Publication: ${publication}\n`;
    }

    if (dateRange) {
      result += `Date Range: ${dateRange}\n`;
    }

    return result;
  }).join('\n');
};

export const filterByPublication = (pubList, publications) => {
  return publications.includes("all")
    ? pubList
    : pubList.filter(({ printTitles }) =>
      printTitles.some(title => publications.includes(title.name))
    );
};

export const filterByDate = (data, range) => {
  const today = new Date();
  let startDate, endDate;

  // Reset time to now to fix issues when calculating endDate for yesterday and this_week
  today.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());

  // Check if the range is 'all', then return all data without filtering
  if (range === 'all') {
    return data; // No filtering applied, return all data
  }

  switch (range) {
    case 'today':
      startDate = new Date(today.setHours(0, 0, 0, 0));
      endDate = new Date(today.setHours(23, 59, 59, 999));
      break;
    case 'yesterday':
      startDate = new Date(today.setDate(today.getDate() - 1)).setHours(0, 0, 0, 0);
      endDate = new Date(today.setHours(23, 59, 59, 999));
      break;
    case 'this_week':
      // Assuming the week starts on Sunday
      startDate = new Date(today.setDate(today.getDate() - today.getDay())).setHours(0, 0, 0, 0);
      endDate = new Date().setHours(23, 59, 59, 999); // Use current time to reset today's date and time
      break;
    case 'last_week':
      const startOfThisWeek = new Date(today.setDate(today.getDate() - today.getDay()));
      startDate = new Date(startOfThisWeek.setDate(startOfThisWeek.getDate() - 7)).setHours(0, 0, 0, 0);
      endDate = new Date(startOfThisWeek.setDate(startOfThisWeek.getDate() + 6)).setHours(23, 59, 59, 999);
      break;
    case 'this_month':
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
      break;
    case 'last_month':
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999);
      break;
    default:
      throw new Error('Invalid range');
  }

  return data.filter(item => {
    const itemDate = new Date(item.publishStartDate);
    return itemDate >= startDate && itemDate <= endDate;
  });
};

export const validatePostcode = (postcode) => {
  // Regular expression pattern to match postcode
  const postcodePattern = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;
  return postcodePattern.test(postcode);
}