import React, { useState } from 'react';

const ModelInteraction = ({
  selectedCheckpoint,
  onEvaluate,
  onContinueTraining,
}) => {
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isTraining, setIsTraining] = useState(false);

  const handleEvaluate = async () => {
    setIsEvaluating(true);
    try {
      await onEvaluate(selectedCheckpoint);
    } catch (error) {
      console.error('Evaluation failed: ', error);
    }
    setIsEvaluating(false);
  };

  const handleContinueTraining = async () => {
    setIsTraining(true);
    try {
      await onContinueTraining(selectedCheckpoint);
    } catch (error) {
      console.error('Continuing training failed: ', error);
    }
    setIsTraining(false);
  };

  if (!selectedCheckpoint) {
    return <p>Please select a checkpoint to interact with the model.</p>;
  }

  return (
    <div>
      <h2>Interacting with {selectedCheckpoint.name}</h2>
      <button onClick={handleEvaluate} disabled={isEvaluating}>
        {isEvaluating ? 'Evaluating...' : 'Evaluate Model'}
      </button>
      <button onClick={handleContinueTraining}>
        {isTraining ? 'Training...' : 'Continue Training'}
      </button>
    </div>
  );
};

export default ModelInteraction;
