import React from 'react';
import scribeLogo from '../../assets/mantis_scribe_logo.png';
import reachLogo from '../../assets/reach-logo.png';
import './footer.scss';

const { version } = require('../../../package.json');

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        <p>
          &copy; {new Date().getFullYear()} <a href="https://www.mantis-intelligence.com/" target="_blank" rel="noreferrer" className="underline">Mantis Scribe</a>. All rights reserved. Mantis Scribe is Your Ultimate Writing Assistant.
        </p>
      </div>
      <div className="logos">
        <img src={scribeLogo} alt="Scribe" className="scribelogo" /> <span>part of</span> <img src={reachLogo} alt="Reach Logo" className="reachlogo" />
      </div>
      <div className="version">
        Version {version}
      </div>
    </div>
  );
}
