import React, { useState } from 'react';
import ModelSelector from './ModelSelector';
import DataUploader from './DataUploader';
import ModelManager from './ModelManager';

const FineTuning = () => {
  const [modelName, setModelName] = useState('');
  const [batchSize, setBatchSize] = useState('');
  const [learningRateMultiplier, setLearningRateMultiplier] = useState('');
  const [nEpochs, setNEpochs] = useState('');

  return (
    <div>
      <h1>Fine-Tuning Configuration</h1>
      <ModelSelector onSelectModel={setModelName} />
      <h2>Hyperparameters</h2>
      <div>
        <label>Batch Size (optional): </label>
        <input
          type="number"
          value={batchSize}
          onChange={(e) => setBatchSize(e.target.value)}
        />
      </div>
      <div>
        <label>Learning Rate Multiplier (optional): </label>
        <input
          type="number"
          step="0.01"
          value={learningRateMultiplier}
          onChange={(e) => setLearningRateMultiplier(e.target.value)}
        />
      </div>
      <div>
        <label>Number of Epochs (optional): </label>
        <input
          type="number"
          value={nEpochs}
          onChange={(e) => setNEpochs(e.target.value)}
        />
      </div>
      <DataUploader
        modelName={modelName}
        batchSize={batchSize}
        learningRateMultiplier={learningRateMultiplier}
        nEpochs={nEpochs}
      />
      <ModelManager />
    </div>
  );
};

export default FineTuning;
