// Import your API configuration module
import api from '../api';

// Function to fetch notices based on a postcode
const fetchNoticesByPostcode = async (postcode, orderBy = 'distance', types = '') => {
  // Set default order based on orderBy
  let order = 'asc'; // Default to ascending
  if (orderBy === 'publishedTime') {
    order = 'desc'; // If sorting by publishedTime, default to descending
  }
  
  try {
    const url = `/proxy/notices?postcode=${postcode}&count=100&order=${order}&orderBy=${orderBy}&types=${types}&radius=15`;
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    console.error("Error fetching notices by postcode:", error);
    throw error;
  }
};

export default {
  fetchNoticesByPostcode,
};
