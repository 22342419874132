// Libraries
import React from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// Local Files
import TypingAnimation from './typingAnimation.js';

// Material UI Icons
import AndroidIcon from '@mui/icons-material/Android';
import PersonIcon from '@mui/icons-material/Person';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import { MemoizedReactMarkdown } from './markdown/memoizedReactMarkdown.js';
import CodeBlock from './markdown/codeBlock.js';
import ImageBlock from './markdown/imageBlock.js';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h2>Something went wrong with the markdown rendering.</h2>;
    }
    return this.props.children;
  }
}

// Styles constants
const styles = {
  userBgColor: '#343542',
  assistantBgColor: 'rgb(82 83 96)',
  systemBgColor: 'rgb(60, 60, 60)',
  textColor: '#fff',
  linkColor: '#24ADE4',
  borderColor: '#e3e3e3',
  headerBgColor: '#e3e3e3',
  headerColor: 'rgb(82 83 96)',
  actionColor: '#24ADE4',
};

// Utility function to get icon based on role
const getIcon = (role) => {
  switch (role) {
    case 'user':
      return <PersonIcon sx={{ color: styles.textColor, mt: 2 }} />;
    case 'assistant':
      return <AndroidIcon sx={{ color: styles.textColor, mt: 2 }} />;
    case 'system':
      return <InfoIcon sx={{ color: styles.textColor, mt: 2 }} />;
    default:
      return null;
  }
};

// ChatMessage Component
const ChatMessage = ({ chat, chatConfig, index, handleAction }) => {
  const { role, content } = chat;
  const isUser = role === 'user';
  const isAssistant = role === 'assistant';
  const isSystem = role === 'system';
  const isTyping = content === 'Mantis Scribe is Typing...';

  return (
    <Box
      key={index}
      display="flex"
      alignItems="flex-start"
      mb={2}
      className={`message ${isUser ? 'user-message' : isAssistant ? 'assistant-message' : 'system-message'}`}
      sx={{
        backgroundColor: isUser ? styles.userBgColor : isAssistant ? styles.assistantBgColor : styles.systemBgColor,
        padding: 2,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 1,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      }}
    >
      {getIcon(role)}
      <Box sx={{ ml: 1, color: styles.textColor, maxWidth: 'calc(100% - 50px)', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {isTyping ? (
          <TypingAnimation />
        ) : (
          <ErrorBoundary>
            <MemoizedReactMarkdown
              className="prose dark:prose-invert"
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeMathjax]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <CodeBlock
                      language={match[1]}
                      value={String(children).replace(/\n$/, '')}
                      {...props}
                    />
                  ) : (
                    <code
                      className={className}
                      style={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        color: styles.textColor,
                      }}
                      {...props}
                    >
                      {children}
                    </code>
                  );
                },
                a({ href, children, ...props }) {
                  return (
                    <a
                      href={href}
                      style={{
                        color: styles.linkColor,
                        textDecoration: 'underline',
                      }}
                      {...props}
                    >
                      {children}
                    </a>
                  );
                },
                table({ children }) {
                  return (
                    <Box
                      component="div"
                      sx={{
                        overflowX: 'auto',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        borderCollapse: 'collapse',
                        border: `1px solid ${styles.borderColor}`,
                      }}
                    >
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                        }}
                        className="table-auto"
                      >
                        {children}
                      </table>
                    </Box>
                  );
                },
                thead({ children }) {
                  return (
                    <thead
                      style={{
                        backgroundColor: styles.headerBgColor,
                      }}
                    >
                      {children}
                    </thead>
                  );
                },
                tbody({ children }) {
                  return (
                    <tbody
                      style={{
                        backgroundColor: 'inherit',
                      }}
                    >
                      {children}
                    </tbody>
                  );
                },
                tr({ children, ...props }) {
                  return (
                    <tr
                      style={{
                        borderBottom: `1px solid ${styles.borderColor}`,
                      }}
                      {...props}
                    >
                      {children}
                    </tr>
                  );
                },
                th({ children }) {
                  return (
                    <th
                      style={{
                        padding: '8px 12px',
                        textAlign: 'left',
                        fontSize: '14px',
                        fontWeight: '600',
                        color: styles.headerColor,
                        borderBottom: `1px solid ${styles.borderColor}`,
                      }}
                    >
                      {children}
                    </th>
                  );
                },
                td({ children }) {
                  return (
                    <td
                      style={{
                        padding: '8px 12px',
                        fontSize: '14px',
                        color: styles.textColor,
                        borderBottom: `1px solid ${styles.borderColor}`,
                      }}
                    >
                      {children}
                    </td>
                  );
                },
                img({ src, alt }) {
                  return <ImageBlock src={src} alt={alt} />;
                },
              }}
            >
              {content}
            </MemoizedReactMarkdown>
          </ErrorBoundary>
        )}
        {isAssistant && (
          <>
            <IconButton
              aria-label="Copy"
              onClick={() => handleAction('copy', chat)}
              sx={{
                color: styles.textColor,
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <FileCopyIcon sx={{ fontSize: 18 }} />
            </IconButton>
            {(chatConfig?.settings?.team === 'Editorial' || chatConfig?.settings?.team === 'Commercial Editorial') && (
              <IconButton
                aria-label="Navigate"
                onClick={() => handleAction('sendToCue', chat)}
                sx={{
                  color: styles.actionColor,
                  position: 'absolute',
                  top: 0,
                  right: '25px',
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessage;
