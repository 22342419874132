import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Texteditor from "./textEditor";
import CircularProgress from "@mui/material/CircularProgress";

const EditorSection = (props) => {
  const { editorState, setEditorState, title, handleTitleChange } = props;
  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        {title && (
          <TextField
            label="Title"
            id="outlined-size-small"
            value={title}
            onChange={handleTitleChange}
            className="outlined-size-small"
            size="small"
            style={{ width: "100%", paddingBottom: "10px" }}
          />
        )}
      </Grid>
      <div>
        <Texteditor editorState={editorState} setEditorState={setEditorState} />
      </div>
    </div>
  );
};

export default EditorSection;
