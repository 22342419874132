import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DefineVariables from "./defineVariables";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import EditorSection from "../textEditorSection";
import "../styles/template.scss";
import { fetchTemplateGroups } from "../../../server/fetchTemplates";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import insertText from "../../reusableComponent/sendTextToEditor";

const Usetemplate = () => {
  const { id } = useParams();
  const [templateData, setTemplateData] = useState([]);
  const [title, setTitle] = useState("");

  const initialState = () => EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialState);

  const fetchTemplateInfo = async () => {
    const data = await fetchTemplateGroups(id);
    setTemplateData(data);
    setTitle(data[0].name);
    sendTextToEditor(data[0].text);
  };

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  useEffect(() => {
    fetchTemplateInfo();
  }, []);
  return (
    <div>
      <div className="templatepage">
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <DefineVariables
              sendTextToEditor={sendTextToEditor}
              templateData={templateData}
            />
          </Grid>
          <Grid item sm={8}>
            <div className="text-editor">
              <EditorSection
                editorState={editorState}
                setEditorState={setEditorState}
                template_name={title}
              />
            </div>
            <div className="template-preview">
              <Typography>Template Preview: </Typography>
              <textarea
                className="textarea"
                disabled
                value={
                  convertToRaw(editorState.getCurrentContent()).blocks[0].text
                }
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Usetemplate;
