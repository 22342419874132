import AsyncSelect from "react-select/async";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { languages, lengths, temperatures, tones } from "../../../definitions";

const typeAiwriter = [
  { value: "Player Comparison", label: "Player Comparison" },
  { value: "Game Report", label: "Game Report" },
];
const timeFrame = [{ value: "Current Season", label: "Current Season" }];
const statOptions = (sport) => {
  return sport === "nfl"
    ? [
      { value: "Total Yards", label: "Total Yards" },
      { value: "Rushing Yards", label: "Rushing Yards" },
      { value: "Receiving Yards", label: "Receiving Yards" },
      { value: "Total Tackles", label: "Total Tackles" },
      { value: "Season Win Percentage", label: "Season Win Percentage" },
    ]
    : [
      { value: "FG Percentage:", label: "FG Percentage:" },
      { value: "FG Percentage from 3:", label: "FG Percentage from 3:" },
      { value: "Rebounds", label: "Rebounds" },
      { value: "Turnovers", label: "Turnovers" },
      { value: "Blocks", label: "Blocks" },
    ];
};

const serviceTypes = [
  { value: "US Sports Article (API)", label: "US Sports Article (API)" },
];

const Servicetype = (props) => {
  const { handleChangeUsecase, serviceType } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        defaultValue={serviceType}
        options={serviceTypes}
        onChange={handleChangeUsecase}
        renderInput={(params) => <TextField {...params} label="Use Case" />}
      />
    </div>
  );
};
const Languages = (props) => {
  const { handleChangeLanguage, language } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={languages}
        defaultValue={"English (British English)"}
        onChange={handleChangeLanguage}
        renderInput={(params) => <TextField {...params} label="Languages" />}
      />
    </div>
  );
};
const ArticleTone = (props) => {
  const { handleChangeArticleTone, articleToneValue } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={tones}
        defaultValue={articleToneValue}
        onChange={handleChangeArticleTone}
        renderInput={(params) => <TextField {...params} label="Article Tone" />}
      />
    </div>
  );
};
const ArticleTemperature = (props) => {
  const { handleChangeTemperature, temperature } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={temperatures}
        defaultValue={temperature}
        onChange={handleChangeTemperature}
        renderInput={(params) => (
          <TextField {...params} label="Article Creativity" />
        )}
      />
    </div>
  );
};
const MaxArticleLength = (props) => {
  const { handleChangeMaxLength, maxLength } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={lengths}
        value={lengths.find((opt) => opt.value === maxLength) || null}
        onChange={handleChangeMaxLength}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Article Length" />
        )}
      />
    </div>
  );
};
const ArticleType = (props) => {
  const { handleChangeArticleType } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={typeAiwriter}
        onChange={handleChangeArticleType}
        renderInput={(params) => <TextField {...params} label="Article Type" />}
      />
    </div>
  );
};
const TimeFrame = (props) => {
  const { handleChangeTimeframe } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        freeSolo
        options={timeFrame}
        onChange={handleChangeTimeframe}
        renderInput={(params) => <TextField {...params} label="Time Frame" />}
      />
    </div>
  );
};
const StatInclude = (props) => {
  const { handleChangeStat, options } = props;
  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        options={options}
        placeholder="Stat Include"
        isClearable={true}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChangeStat}
      />
    </div>
  );
};
const Game = (props) => {
  const { handleChangeGame, games } = props;
  return (
    <div>
      <AsyncSelect
        defaultOptions={games}
        placeholder="Game"
        isClearable={true}
        onChange={handleChangeGame}
      />
    </div>
  );
};
const StatIncludeGame = (props) => {
  const { handleChangeStat, sport } = props;
  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        options={statOptions(sport)}
        placeholder="Stat Include"
        isClearable={true}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChangeStat}
      />
    </div>
  );
};
export {
  typeAiwriter,
  timeFrame,
  statOptions,
  serviceTypes,
  Servicetype,
  Languages,
  ArticleTone,
  ArticleTemperature,
  MaxArticleLength,
  ArticleType,
  TimeFrame,
  StatInclude,
  Game,
  StatIncludeGame,
};
