// React Libraries and Hooks
import React, { useState, useEffect } from 'react';

// Material UI Components
import Typography from '@mui/material/Typography';

// Local Components
import CustomOptions from '../reusableComponent/CustomOptions';

// Local Definitions from 'definitions' file
import {
  publications,
  articleTypes,
  publicationStatusOptions
} from "../../definitions";

const PushToCMSOptions = ({
  serviceMode = "cms",
  input,
  setInput,
}) => {
  const localStorageKey = `${serviceMode}-settings`;

  // Initialize settings from localStorage
  useEffect(() => {
    const savedSettings = localStorage.getItem(localStorageKey);
    if (savedSettings) {
      const cmsSettings = JSON.parse(savedSettings);
      setInput(prevState => ({ ...prevState, cmsData: { ...prevState.cmsData, ...cmsSettings } }));
    }
  }, [localStorageKey]);

  // Function to handle changes and update localStorage
  const handleChange = (key, value) => {
    setInput(prevState => {
      const updatedCmsData = { ...prevState.cmsData, [key]: value };

      // If key is 'cms', update cms and override cmsEntries
      if (key === 'cms') {
        updatedCmsData.cmsEntries = value ? [value] : [];
      }

      const updatedState = { ...prevState, cmsData: updatedCmsData };

      // Save cmsData part of the state to localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(updatedCmsData));
      return updatedState;
    });
  };


  const options = [
    {
      type: 'autocomplete',
      label: 'CMS',
      value: publications.find(pub => pub.value === input.cmsData.cms) || null,
      onChange: (newValue) => handleChange('cms', newValue ? newValue.value : ''),
      options: publications,
      tooltipTitle: 'Select the CMS publication.',
    },
    {
      type: 'autocomplete',
      label: 'Article Type',
      value: articleTypes.find(type => type.value === input.cmsData.cmsContentType) || null,
      onChange: (newValue) => handleChange('cmsContentType', newValue ? newValue.value : ''),
      options: articleTypes,
      tooltipTitle: 'Select the article type.',
    },
    {
      type: 'autocomplete',
      label: 'Publication Status',
      value: publicationStatusOptions.find(status => status.value === input.cmsData.cmsPublicationStatus) || null,
      onChange: (newValue) => handleChange('cmsPublicationStatus', newValue ? newValue.value : ''),
      options: publicationStatusOptions,
      tooltipTitle: 'Select the publication status.',
      sx: { mb: 4 }
    },
  ];
  
  return (
    <>
      <Typography sx={{ mb: 2 }}>Publications Settings</Typography>
      <CustomOptions options={options} />
    </>
  );
};


export default PushToCMSOptions;