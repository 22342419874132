import { useDrag } from "react-dnd";
import { ItemFacts } from "./styledComponents";

const Fact = (props) => {
  const { item, index } = props;
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "fact",
    item: {
      id: index,
      fact: item,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <ItemFacts>{item}</ItemFacts>
    </div>
  );
};

export default Fact;
