import api from '../api';

const sendMessageToCompletionsAPI = async (body) => {
  try {
    const url = `/v1/chat/completions`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const sendToBatchChatCompletionsAPI = async (body) => {
  try {
    const url = `/v1/chat/completions/batch`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const enqueueMessageToCompletionsAPI = async (body) => {
  try {
    const url = `/v1/chat/completions/queue`;
    const { data } = await api.post(url, body);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const checkTaskStatusInCompletionsQueue = async (taskId) => {
  try {
    const url = `/v1/chat/completions/queue/${taskId}`;
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default {
  sendMessageToCompletionsAPI,
  sendToBatchChatCompletionsAPI,
  enqueueMessageToCompletionsAPI,
  checkTaskStatusInCompletionsQueue
};
