// Libraries
import React, { useState } from 'react';

// Material UI Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

const AiPromptBar = ({ onSend }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      handleSend();
    }
  };

  const handleSend = () => {
    if (inputValue.trim() !== '') {
      onSend(inputValue);
      setInputValue('');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Paper elevation={3} style={{ padding: '16px', boxShadow: 'none', backgroundColor: 'transparent', width: '70%', padding: '2px' }}>
        <TextField
          placeholder="Type your message"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{ backgroundColor: '#4a4b5d' }}
          InputProps={{
            style: { color: 'white' },
            endAdornment: (
              <Button variant="contained" style={{ backgroundColor: '#24ADE4' }} onClick={handleSend}>
                <SendIcon />
              </Button>
            ),
          }}
        />
      </Paper>
    </div>
  );
};

export default AiPromptBar;
