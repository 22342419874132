import React, { useEffect, useState } from 'react';
import CheckpointsList from './CheckpointsList';
import ModelInteraction from './ModelInteraction';

const ModelManager = () => {
  const [checkpoints, setCheckpoints] = useState([]);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);

  useEffect(() => {
    const fetchCheckpoints = async () => {
      try {
        const response = await fetch('/v1/chat/fine-tuning');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCheckpoints(data);
      } catch (error) {
        console.error(
          'There has been a problem with your fetch operation: ',
          error,
        );
      }
    };

    fetchCheckpoints();
  }, []);

  const handleEvaluate = async (checkpoint) => {
    console.log('Evaluating model: ', checkpoint);
  };

  const handleContinueTraining = async (checkpoint) => {
    console.log('Continuing training for model: ', checkpoint);
  };

  return (
    <div>
      <h1>Model Management</h1>
      <CheckpointsList
        checkpoints={checkpoints}
        onSelectCheckpoint={setSelectedCheckpoint}
      />
      <ModelInteraction
        selectedCheckpoint={selectedCheckpoint}
        onEvaluate={handleEvaluate}
        onContinueTraining={handleContinueTraining}
      />
    </div>
  );
};

export default ModelManager;
