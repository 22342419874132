import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddGroupPopup from "./addGroupTemplate";
import { getLabels } from "../../../data/apiFields";
import FactFinderButtonSection from "../factFinderButtonSection";
import { SportSelectBox } from "../../reusableComponent/selectFacts";

import "../styles/template.scss";
const AddVariableTemplate = (props) => {
  const { sendTextToEditor, fetchText, templateTitle } = props;

  const [openAddGroupPopup, setOpenAddGroupPopup] = useState(false);
  const [openAddVariablePopup, setOpenAddVariablePopup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [type, setType] = useState("");
  const [sport, setSport] = useState("");

  const [groupCount, setGroupCount] = useState({
    season: 0,
    games: 0,
    players: 0,
    teams: 0,
  });
  const [newVariable, createVariable] = useState({});
  const [currentGroup, setCurrentGroup] = useState("");

  const [variables, addVariables] = useState([]);
  const [options, setOptions] = useState([]);

  // functionality handleclick save group
  const handleClickSaveGroup = () => {
    if (type !== "") {
      updateGroupCounts();

      addGroups({
        selectedType: type,
        selectedSport: sport,
      });
      handleCloseAddGroup();
    } else {
      alert("Please fill all options");
    }
  };
  //  functionality for add group object in array
  const addGroups = (obj) => {
    obj = { ...obj, groupName: `${type}${groupCount[type]}` };
    setGroups((groups) => [...groups, obj]);
  };

  const updateGroupCounts = () => {
    setGroupCount((groupCount) => ({
      ...groupCount,
      [type]: (groupCount[type] += 1),
    }));
  };

  const handleClickOpenAddGroup = () => {
    setOpenAddGroupPopup(true);
  };

  const handleCloseAddGroup = () => {
    setOpenAddGroupPopup(false);
  };

  const handleClickOpenAddVariable = (groupname) => {
    setCurrentGroup(groupname);
    setOptions(getLabels(type));
    setOpenAddVariablePopup(true);
  };

  const handleInputChangeVariable = (event, value) => {
    const obj = {
      group: currentGroup,
      name: `${value.label}`,
      value: value.value,
    };
    createVariable(obj);
  };
  const handleCloseAddVariable = () => {
    addVariables((variables) => [...variables, newVariable]);
    setOpenAddVariablePopup(false);
  };
  const handleInputChangeSport = (val) => {
    if (val) {
      setSport(val.value);
    } else {
      setSport("");
      setType("");
    }
  };
  const handleInputChangeType = (val) => {
    if (val) {
      setType(val.value);
    } else {
      setType("");
      setSport("");
    }
  };
  const handleResetTemplate = () => {
    setGroups([]);
    setType("");
    setSport("");
  };

  useEffect(() => {}, [groups]);

  return (
    <div>
      <div className="left-section">
        <FactFinderButtonSection
          groups={groups}
          type={type}
          sport={sport}
          variables={variables}
          text={fetchText}
          template_name={templateTitle}
          handleResetTemplate={handleResetTemplate}
        />
        <div className="selectbox">
          <SportSelectBox />
        </div>
        <Grid container alignItems="center" justifyContent="space-between">
          <h3 className="titlename">Fact Finder</h3>
          <Button
            data-testid="add-group-button"
            size="small"
            variant="contained"
            onClick={handleClickOpenAddGroup}
          >
            Add Group
          </Button>
        </Grid>
        <div>
          {type && (
            <div>
              {groups.map((group, index) => {
                return (
                  <div className="group-section">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      key={index}
                      sx={{ margin: "10px 0" }}
                    >
                      <p
                        className="insertelement"
                        data-testid={group.groupName}
                      >
                        &lt;{group.groupName}&gt;
                      </p>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          handleClickOpenAddVariable(group.groupName)
                        }
                      >
                        Create Variable
                      </Button>
                    </Grid>
                    <div className="addvariable-section">
                      {variables.length > 0 &&
                        variables
                          .filter((v) => v.group === group.groupName)
                          .map((variable) => {
                            return (
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                key={index}
                                sx={{
                                  margin: "5px 0",
                                }}
                              >
                                <p
                                  className="insertelement"
                                  data-testid={variable.name}
                                >
                                  &lt;Insert{variable.name}&gt;
                                </p>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    sendTextToEditor(`<${variable.name}>`)
                                  }
                                >
                                  Add Variable
                                </Button>
                              </Grid>
                            );
                          })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <AddGroupPopup
          handleCloseAddVariable={handleCloseAddVariable}
          handleClickOpenAddVariable={handleClickOpenAddVariable}
          handleClickOpenAddGroup={handleClickOpenAddGroup}
          handleCloseAddGroup={handleCloseAddGroup}
          handleInputChangeSport={handleInputChangeSport}
          handleInputChangeType={handleInputChangeType}
          handleClickSaveGroup={handleClickSaveGroup}
          openAddGroupPopup={openAddGroupPopup}
          openAddVariablePopup={openAddVariablePopup}
          handleInputChangeVariable={handleInputChangeVariable}
          options={options}
        />
      </div>
    </div>
  );
};
export default AddVariableTemplate;
