import React from "react";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "../aiWriter/createArticles/aiWriter";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const sportData = [
  { value: "nfl", label: "NFL" },
  { value: "nba", label: "NBA" },
];

const SportSelectBox = (props) => {
  const { handleInputChangeSport } = props;
  return (
    <div>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        options={sportData}
        onChange={handleInputChangeSport}
        renderInput={(params) => <TextField {...params} label="Sport" />}
      />
    </div>
  );
};
const StartDatePicker = (props) => {
  const { handleInputStartDate, startDate } = props;
  return (
    <>
      <DatePicker
        isClearable
        placeholderText="Select Date"
        onChange={(date) => handleInputStartDate(date)}
        selected={startDate}
      />
    </>
  );
};
const EndDatePicker = (props) => {
  const { handleInputEndDate, endDate } = props;
  return (
    <>
      <DatePicker
        selected={endDate}
        onChange={(date) => handleInputEndDate(date)}
        isClearable
        placeholderText="Select End Date"
      />
    </>
  );
};
const SearchPlayerTeam = (props) => {
  const { handleLoadOptions, handleInputChangeSearch, placeholder } = props;
  return (
    <>
      <AsyncSelect
        data-testid="autocomplete"
        loadOptions={handleLoadOptions}
        placeholder={placeholder}
        isClearable={true}
        onChange={handleInputChangeSearch}
      />
    </>
  );
};

const SelectPlayerTeam = (props) => {
  const {
    handleLoadOptions,
    handleInputChangeSearch,
    handleClosePopup,
    handleClickSavePlayer,
    selectPlayerPopupOpen,
  } = props;
  return (
    <>
      <Dialog
        fullWidth
        open={selectPlayerPopupOpen}
        sx={{ height: "500px" }}
        onClose={handleClosePopup}
      >
        <DialogTitle>Search Player</DialogTitle>
        <DialogContent sx={{ height: "200px" }}>
          <div>
            <SearchPlayerTeam
              handleInputChangeSearch={handleInputChangeSearch}
              handleLoadOptions={handleLoadOptions}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup}>Cancel</Button>
          <Button
            data-testid="save-variable-button"
            onClick={handleClickSavePlayer}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export {
  SportSelectBox,
  StartDatePicker,
  EndDatePicker,
  SearchPlayerTeam,
  SelectPlayerTeam,
};
