import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";


const Texteditor = (props) => {
  const location = useLocation();
  const { editorState, setEditorState } = props;

  const onChange = async (value) => {
    setEditorState(value);
  };

  return (
    <div>    
      <Editor
        spellCheck
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorState={editorState}
        editorStyle={{ overflowY: "scroll", height: `calc(100vh - 259px)` }}
        onEditorStateChange={(value) => {
          onChange(value);
        }}
        hashtag={{}}        
        readOnly={location.pathname === "/use-template" ? true: false }
      />
      
    </div>
  );
};

export default Texteditor;